import ProjectWorksheetHeader from '../../components/ProjectWorksheetHeader';
import Worksheet from '../../components/Worksheet';
import ProjectWorksheetProvider from './providers/ProjectWorksheetProvider';

export default function ProjectWorksheet() {
  return (
    <ProjectWorksheetProvider>
      <div>
        <ProjectWorksheetHeader />
        <Worksheet />
      </div>
    </ProjectWorksheetProvider>
  );
}