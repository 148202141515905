
export type ProjectDashboardFilterType = '' | 'FILTER_BY_STATUS' | 'OPEN_ITEMS';
export type ProjectFilterType = 'MARKET_QUOTE' | 'DRAFT' | 'IN_REVIEW' | 'BIDDING' | 'BID_REVIEW' | 'IN_DILIGENCE' | 'EXECUTED' | 'COMPLETED' | 'CLOSED';
export type ProjectFilterSourceType = 'TAB' | 'DROPDOWN';

export const ProjectDashboardFilters = {
  ALL: '',
  FILTER_BY_STATUS: 'FILTER_BY_STATUS',
  OPEN_ITEMS: 'OPEN_ITEMS',
};

export const ProjectDashboardFiltersList = [
  { value: ProjectDashboardFilters.ALL, label: 'All projects'},
  { value: ProjectDashboardFilters.FILTER_BY_STATUS, label: 'Filter by status'},
  { value: ProjectDashboardFilters.OPEN_ITEMS, label: 'Open items' },
];

export const ProjectFilterSource = {
  TAB: 'TAB' as ProjectFilterSourceType,
  DROPDOWN: 'DROPDOWN' as ProjectFilterSourceType,
};

export const ProjectFilters = {
  MARKET_QUOTE: 'MARKET_QUOTE',
  DRAFT: 'DRAFT',
  IN_REVIEW: 'IN_REVIEW',
  BID_REVIEW: 'BID REVIEW',
  BIDDING: 'BIDDING',
  IN_DILIGENCE: 'IN_DILIGENCE',
  EXECUTED: 'EXECUTED',
  COMPLETED: 'COMPLETED',
  CLOSED: 'CLOSED',
  CUSTOMER_PROPOSALS: 'CUSTOMER_PROPOSALS',
};

export const ProjectFiltersList = [
  { value: ProjectFilters.MARKET_QUOTE, label: 'Estimates', hideForInvestors: true },
  { value: ProjectFilters.DRAFT, label: 'Draft', hideForInvestors: true },
  { value: ProjectFilters.IN_REVIEW, label: 'In review', hideForInvestors: true },
  { value: ProjectFilters.BIDDING, label: 'Bidding' },
  { value: ProjectFilters.BID_REVIEW, label: 'Bid review' },
  { value: ProjectFilters.IN_DILIGENCE, label: 'In diligence' },
  { value: ProjectFilters.EXECUTED, label: 'Executed' },
  { value: ProjectFilters.COMPLETED, label: 'Completed'},
  { value: ProjectFilters.CLOSED, label: 'Archived' },
];