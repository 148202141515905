import { LongTermOwner } from '~/constants';
import { nonEmptyString, validFloat } from './validators';
import { FormConditionEffects, FormConditionFunction, FormConditionKeys, FormConditionObject, FormFieldValue } from './types';
import { Project } from '~/types/project';


export const when = (fieldIdOrFunction: string | FormConditionFunction, condition?: FormConditionKeys, value?: FormFieldValue) => {
  if (typeof fieldIdOrFunction === 'string' && (!condition || (((condition === '=' || condition === '!=')) && value === undefined))) {
      throw new Error('Bad when function for form condition');
  }
  return {
    then(effects: FormConditionEffects): FormConditionObject {
      if (typeof fieldIdOrFunction === 'string') {
        let conditionValue = condition === '=' || condition === '!=' ? value : true;
        const conditionKey = condition as FormConditionKeys;
        return {
          fieldId: fieldIdOrFunction,
          [conditionKey]: conditionValue,
          effects
        };
      } else {
        return {
          conditionFn: fieldIdOrFunction,
          effects
        };
      }
    }
  };
};

export const additionalLineItemSchema = {
  name: (value: any, project: Project) => project.longTermOwner !== LongTermOwner.END_USER && nonEmptyString(value),
  amount: (value: any, project: Project) => project.longTermOwner !== LongTermOwner.END_USER && validFloat(value),
  escalator: (value: any, project: Project) => project.longTermOwner !== LongTermOwner.END_USER && validFloat(value),
  expectedTerm: (value: any, project: Project) => {
    if (project.longTermOwner === LongTermOwner.END_USER) {
      return false;
    }

    const isValidFloat = validFloat(value);
    if (isValidFloat) {
      return true;
    } else if (value < 0 || value > 30) {
      return 'Must be between 0 and 30';
    }
    return false;
  }
};