import * as Collapsible from '@radix-ui/react-collapsible';
import { ProductionUnit, ProjectArrayDropdownOptions } from '~/constants';
import { ProjectArray } from '~/types/project';
import { WorksheetField } from '../../../../worksheets/common/components/Field';
import styled from 'styled-components';
import { InverterTypeDropdownOptions, ModuleOrientationDropdownOptions, ModuleTypeDropdownOptions } from '~/constants/production-inputs';
import { useFadeInStyles, useProjectContext } from '~/hooks';
import Link from '~/components/Link';
import toast from 'react-hot-toast';
import ProjectArraySectionHeader from './ProjectArraySectionHeader';
import { useEffect, useState } from 'react';
import WorksheetForm from '~/features/projectWizard/worksheets/common/components/WorksheetForm';

const ProjectArraySectionContainer = styled.section`
  & + & {
    margin-top: var(--medium);
  }

  &&& .isBifacial {
    > .radio-container {
      padding: 0.1875rem 0px;
    }
    .field.label > label {
      padding: 0;
      font-size: 0.875rem;
      margin: 0em 0em 0.28571429rem 0em;
    }
    .inline.fields > .field {
      height: 2.5rem;
      display: flex;
      align-items: center;
    }
  }

  .actions {
    margin-top: calc(var(--medium) - 1rem);
    display: flex;
    align-items: center;
    > * {
      display: inline-block;
    }
    > * + * {
      margin-left: var(--medium);
    }
  }
`;

export default function ProjectArraySection({ projectArray }: { projectArray: ProjectArray }) {
  const { project, updateProjectValue } = useProjectContext();
  
  const projectArrays = project.projectArrays ?? [];
  const [open, setOpen] = useState(projectArrays.length > 1 ? false : true);
  
  useEffect(() => {
    // New arrays should always start expanded
    if (projectArray.startOpen) {  
      setOpen(true);
      delete projectArray.startOpen;
    }
  }, []);

  const isLastProjectArray = projectArrays.length - 1 === projectArray?.order;

  const addNewArray = () => {
    updateProjectValue('projectArrays', [...projectArrays, { projectId: project.id, order: projectArrays.length, startOpen: true }]);
  };

  const removeArray = () => {
    // Prevent from removing the last array
    if (projectArrays.length === 1) {
      return toast.error('You must have at least one array in your project.', { duration: 4000 });
    }

    const newArrays = projectArrays
      .filter((array) => array.order !== projectArray.order)
      .map((array, index) => ({ ...array, order: index }));

    // Remove this array from the projectArrays array
    updateProjectValue('projectArrays', newArrays);
  };

  // Returns a schemaKey like projectArrays.0.type for saving the value in the project object
  const schemaKey = (term: string) => `projectArrays.${projectArray.order}.${term}`;

  return (
    <ProjectArraySectionContainer style={useFadeInStyles()}>
      <Collapsible.Root open={open} onOpenChange={() => setOpen(!open)}>
        <ProjectArraySectionHeader 
          projectArray={projectArray} 
          isCollapsed={!open} 
        />
        <Collapsible.Content className="CollapsibleContent">
          <WorksheetForm isInner>
            <WorksheetField.Row>
              <WorksheetField
                type='dropdown'
                schemaKey={schemaKey('type')}
                placeholder="Select an array type"
                options={ProjectArrayDropdownOptions}
                fieldLabel='Array type'
              />
            </WorksheetField.Row>
            {/* Only show roofCondition field for ROOFTOP type arrays */}
            {projectArray.type === 'ROOFTOP' && (
              <WorksheetField.Row>
                <WorksheetField
                  schemaKey={schemaKey('roofCondition')}
                  placeholder="Enter roof condition"
                  fieldLabel='Roof condition'
                />
              </WorksheetField.Row>  
            )}
            <WorksheetField.Row>
              <WorksheetField
                type="number"
                schemaKey={schemaKey('sizeKwdc')}
                fieldLabel="Size in kWdc"
                placeholder="Enter size"
                label="kWdc"
              />
              <div className="radio-selections">
                <WorksheetField
                  type="number"
                  fieldLabel="Year one production"
                  schemaKey={schemaKey('yearOneProduction')}
                  decimals={0}
                  unitField="yearOneProductionUnit"
                  placeholder="Enter production"
                  units={[
                    { value: ProductionUnit.KWH, label: 'kWh' },
                    { value: ProductionUnit.KWHKW, label: 'kWh/kW' },
                  ]}
                  defaultUnit={ProductionUnit.KWHKW}
                />
              </div>
            </WorksheetField.Row>
            <WorksheetField.Row>
              <WorksheetField
                type="dropdown"
                schemaKey={schemaKey('moduleType')}
                placeholder="Select module type"
                options={ModuleTypeDropdownOptions}
                fieldLabel="Module type"
                optional
              />
              <div className="isBifacial">
                <WorksheetField
                  type="radio"
                  schemaKey={schemaKey('isBifacial')}
                  fieldLabel="Bifacial modules?"
                  values={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                  inline
                  optional
                />
              </div>
            </WorksheetField.Row>
            <WorksheetField.Row>
              <WorksheetField
                type="number"
                schemaKey={schemaKey('sizeKwac')}
                fieldLabel="Size in kWac"
                placeholder="Enter size"
                label="kWac"
                optional={project.addressState !== 'Illinois'}
              />
              <WorksheetField
                type="dropdown"
                schemaKey={schemaKey('inverterType')}
                placeholder="Select inverter type"
                options={InverterTypeDropdownOptions}
                fieldLabel='Inverter type'
                optional
              />
            </WorksheetField.Row>
            <WorksheetField.Row>
              <WorksheetField
                type="number"
                schemaKey={schemaKey('derateFactor')}
                fieldLabel="Shading loss / derate factor"
                placeholder="Enter shading loss"
                label="%"
                optional
                helpText="Enter horizon and near shading; not inter-row shading."
              />
              <WorksheetField
                type="dropdown"
                schemaKey={schemaKey('moduleOrientation')}
                placeholder="Select orientation"
                options={ModuleOrientationDropdownOptions}
                fieldLabel='Module orientation'
                optional
              />
            </WorksheetField.Row>
            <WorksheetField.Row style={{ marginBottom: '1rem' }}>
              <WorksheetField
                type="number"
                schemaKey={schemaKey('moduleTiltDegrees')}
                fieldLabel="Module tilt"
                placeholder="Enter tilt"
                label="degrees"
                optional
                helpText="0 - 90° (for trackers, max rotation angle)"
              />
              <WorksheetField
                type="number"
                schemaKey={schemaKey('azimuthDegrees')}
                fieldLabel="Azimuth"
                placeholder="Enter azimuth"
                label="degrees"
                optional
                helpText="North = 0°; East = 90°; South = 180°; West = 270° "
              />
            </WorksheetField.Row>    
          </WorksheetForm> 
        </Collapsible.Content>
        <div className="actions">
          {isLastProjectArray && (
            <Link 
              as="button" 
              underline 
              style={{ marginRight: 'var(--large)' }}
              onClick={addNewArray}
            >
              Add another array
            </Link>
          )}
          <Link 
            as="button" 
            underline 
            onClick={removeArray}
          >
            Remove this array
          </Link>
        </div>
      </Collapsible.Root>
    </ProjectArraySectionContainer>
  );
}