import { Grid, Step } from 'semantic-ui-react';
import * as S from './styles';
import { knownErrors } from '~/schema/project';
import { useProjectWorksheetContext } from '~/features/projectWizard/worksheets/project/providers/ProjectWorksheetProvider';
import FieldErrorCount from './FieldErrorCount';
import { usePageHeaderContext } from '~/components/PageHeader/PageHeaderProvider';
import { useProjectContext } from '~/hooks';

export default function ProjectWorksheetHeader() {
  const pageHeaderContext = usePageHeaderContext();
  const { config, selectScreen, currentScreen } = useProjectWorksheetContext();
  const {
    conditions: {
      isSectionDisplaying,
      sectionLabel,
      sectionDescription
    }
  } = useProjectContext();

  const handleStepSelect = (sectionId: string) => {
    if (sectionId !== currentScreen.sectionId) {
      window.scrollTo({ top: 0 });
      selectScreen(sectionId);
    }
  };

  return (
    <S.StepsGridRow style={{ position: 'sticky', top: pageHeaderContext.height && `${pageHeaderContext.height/16}rem` }}>
      <Grid.Column>
        <Step.Group widths={5} style={{ width: '100%' }}>
          {config.filter(section => isSectionDisplaying(section.id)).map(section => {
            const label = sectionLabel(section.id, section.label);
            const description = sectionDescription(section.id, section.description);
           
            const categories = knownErrors[section.id] as {};
            const categoryData = Object.values(categories).flatMap(subsection =>
              Array.isArray(subsection) ? subsection : []
            );

            return (
              <S.WizardStep key={section.id} link onClick={() => handleStepSelect(section.id)} className={currentScreen.sectionId === section.id ? 'selected' : ''}>
                <FieldErrorCount categoryData={categoryData} showSuccessState /> 
                <Step.Content>
                  <Step.Title>{label}</Step.Title>
                  <Step.Description title={description}>{description}</Step.Description>
                </Step.Content>
              </S.WizardStep>
            );
          })}
        </Step.Group>
      </Grid.Column>
    </S.StepsGridRow>
  );
}