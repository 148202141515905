import { InputType } from '~/schema';
import { WorksheetField } from '../../../../worksheets/common/components/Field';
import { Unit, UnitDecimals, ProjectStatus } from '~/constants';
import { useProjectContext } from '~/hooks';

export default function EpcCosts() {
  const { project, projectState } = useProjectContext();

  const fieldLabel = project.status === ProjectStatus.MARKET_QUOTE ? 'Total project budget' : 'EPC cost + dev fee';

  return (
    projectState.isQuote ? (
      <div className='radio-selections'>
        <WorksheetField
          type="number"
          fieldLabel={fieldLabel}
          schemaKey="epcCost"
          numberType={InputType.DOLLARS}
          unitField="epcCostUnit"
          units={[
            {value: Unit.WDC, label: 'per Wdc', decimals: UnitDecimals.WDC},
            {value: Unit.TOTAL, label: 'total', decimals: UnitDecimals.TOTAL},
          ]}
        />
      </div>
    ) : (
    <>
      <WorksheetField
        type="number"
        fieldLabel={fieldLabel}
        schemaKey="epcCost"
        numberType={InputType.DOLLARS}
        unitField="epcCostUnit"
        units={[
          {value: Unit.WDC, label: 'per Wdc', decimals: UnitDecimals.WDC},
          {value: Unit.TOTAL, label: 'total', decimals: UnitDecimals.TOTAL},
        ]}
      />
    </>
    )
  );
}