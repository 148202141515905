import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { getProjectResponseRequiredCounts } from '~/api/projects';
import { getPortfolioResponseRequiredCounts } from '~/api/portfolios';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePageType } from '~/hooks';

export const requiredResponseQueryKey = () => 'requiredResponseCounts';

export const useRequiredResponseCountsQuery = () => {
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string }>();
  const pageType = usePageType();

  const useQueryResult = useQuery({
    queryKey: requiredResponseQueryKey(),
    queryFn: async () => {
      if ( pageType === 'project' && id ) {
        return getProjectResponseRequiredCounts( id );
      } else if ( pageType === 'portfolio' && id ) {
        return getPortfolioResponseRequiredCounts( id );
      }
    },
    staleTime: Infinity,
    retry: false,
    refetchOnWindowFocus: false
  });

  useEffect( () => {
    useQueryResult.refetch();
  }, [ pathname ] );

  return useQueryResult;
};