import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Form } from 'semantic-ui-react';
import { Button, Modal } from '~/components';
import { FormSchemaAwareWidget } from '~/components/form';
import Link from '~/components/Link';
import { useForm } from '~/hooks';
import projectDefaultValues from '~/schema/project/projectDefaultValues';
import { STATES } from '~/utils';
import { ModalFormProps } from './types';
import { useProjectList } from '~/requests/projects/useProjectList';
import { useCreateProject } from '~/mutations/project/useCreateProject';

interface CreateProjectFormState {
  name: string;
  addressState: string;
}

export default function CreateProjectForm({ 
  toggleFormType, 
  portfolioId,
  closeModal,
  projectData = {}
}: ModalFormProps) {
  const { data: projects } = useProjectList();
  const history = useHistory();
  const { formState } = useForm<CreateProjectFormState>();

  // Determine if form has an error
  const hasError = !formState.name || !formState.addressState;

  const { mutateAsync: createProject, status: createProjectStatus } = useCreateProject();

  const handleSubmit = async () => {
    try {
      const project = await createProject({
        ...projectDefaultValues,
        ...projectData, 
        name: formState?.name,
        addressState: formState?.addressState,
        portfolioId,
      });
      toast.success(`Project created successfully ${portfolioId ? ' and added to the portfolio.' : '.'}`, { duration: 5000 });
      if (project?.id) {
        history.push(`/project/${project?.id}/worksheet`);
        closeModal();
      }
    } catch (err) {
      console.error('Error occurred creating project:', err);
      toast.error('An error occurred while creating the project. Please try again.', { duration: 5000 });
    }
  };

  return (
    <div>
      <Modal.Title>
        Create a new project
      </Modal.Title>
      <Modal.Content>
        <p>
          Start with the basics so we can customize your project information based on location.
        </p>
        <Form onSubmit={() => handleSubmit()}>
          <FormSchemaAwareWidget
            fieldLabel="Project name"
            placeholder="Enter value"
            schemaKey="name"
          />
          <FormSchemaAwareWidget
            fieldLabel="State"
            placeholder="Enter value"
            schemaKey="addressState"
            search
            options={STATES}
          />
        </Form>
      </Modal.Content>
      <Modal.Footer>
        {!!projects?.length && (
          <Link 
            as={Button} 
            style={{ marginRight: 'auto' }}
            onClick={toggleFormType}
          >
            Duplicate a project
          </Link>
        )}
        <Button primary outlined onClick={closeModal}>Cancel</Button>
        <Button
          primary
          disabled={hasError || createProjectStatus === 'loading'}
          loading={createProjectStatus === 'loading'}
          onClick={() => handleSubmit()}
        >
          Create project
        </Button>
      </Modal.Footer>
    </div>
  );
}