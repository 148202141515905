import { Route, Switch, useParams } from 'react-router-dom';
import { ProjectContextProvider } from '~/hooks/providers/ProjectContextProvider';
import PricingEstimatePage from '~/components/PricingEstimatePage';
import PricingEstimateReview from '~/features/pricingEstimateReview';
import { DataroomProvider } from '~/features/dataroom/providers/DataroomProvider';
import Dataroom from '~/features/dataroom';
import RedirectRoute from './RedirectRoute';
import { ConversationProvider } from '~/features/messaging/providers/ConversationProvider';
import { MessagingProvider } from '~/features/messaging/providers/MessagingProvider';
import Messaging from '~/features/messaging';
import { validation } from '~/schema/pricingEstimate';

function PricingEstimate() {
  const { id } = useParams() as any;

  return (
    <ProjectContextProvider id={id} validation={validation}> 
      <PricingEstimatePage>
        <Switch>
          <Route path="/pricing-estimate/:id/worksheet">
            <PricingEstimateReview />
          </Route>
          <Route exact path="/pricing-estimate/:id/dataroom">
            <Route exact path="/pricing-estimate/:id/dataroom">
              <DataroomProvider>
                <Dataroom />
              </DataroomProvider>
            </Route>
          </Route>
          <Route exact path="/pricing-estimate/:id">
            <RedirectRoute to={`/pricing-estimate/${id}/worksheet`} />
          </Route>
          <Route exact path="/pricing-estimate/:id/messages">
            <ConversationProvider>
              <MessagingProvider pageType="project">
                <Messaging />
              </MessagingProvider>
            </ConversationProvider>
          </Route>
        </Switch>
      </PricingEstimatePage>
    </ProjectContextProvider>
  );
}

export default PricingEstimate;