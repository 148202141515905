import React from 'react';
import { FormContextProvider } from '~/hooks/providers/FormContextProvider';

/**
 * This function wraps a component with a form context provider.
 * It lets us do away with the boilerplate of wrapping a component with a FormContextProvider.
 */

interface WithFormProps<FormStateType> {
  defaults: FormStateType | {};
  validation?: any;
}

export const withForm = <FormStateType extends { [key: string]: any}>(
  { defaults, validation }:  WithFormProps<FormStateType> = { defaults: {}}, 
  Component: React.ComponentType<any>
) => {
  return (props: any) => {
    return (
      <FormContextProvider defaults={defaults} validation={validation}>
        <Component {...props} />
      </FormContextProvider>
    );
  };
};