import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { Checkbox } from 'semantic-ui-react';
import { updateUserSettings } from '~/api/user';
import { useUserContext } from '~/hooks';
import { usePermissions } from '~/requests/permissions/usePermissions';
import sendSettingsClickEvent from '~/utils/analytics/sendSettingsClickEvent';

export default function EmailOptOut() {
  const { loggedInUser } = useUserContext();
  const [emailOptOut, setEmailOptOut] = useState(loggedInUser?.emailOptOut);
  const { permissions } = usePermissions();
  const hasBothGroups = permissions.hasDeveloperRole && permissions.hasInvestorRole;

  const { mutate: toggleCheckbox, status } = useMutation(async () => {
    const newState = !emailOptOut;
    
    // Analytics
    newState ? sendSettingsClickEvent('Emails Disabled') : sendSettingsClickEvent('Emails Enabled');

    setEmailOptOut(newState);
    return updateUserSettings({ emailOptOut: newState })
      .catch(() => {
        toast.error('Failed to update email notification settings. Please try again.');
        setEmailOptOut(!newState);
      });
  });

  return (
    <Checkbox 
      checked={!emailOptOut}
      label={hasBothGroups ? 'Receive e-mail notifications?' : 'I want to receive notifications via e-mail'}
      style={{ fontWeight: 'bold', fontSize: '0.875rem' }}
      onChange={() => toggleCheckbox()}
      disabled={status === 'loading'}
    />
  );
}