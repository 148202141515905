import { useMutation } from 'react-query';
import { updateCompanyOwnership } from '~/api/admin';

interface UpdateCompanyOwnershipParams {
  id: string;
  key: string;
  value: string;
}

export const useUpdateCompanyOwnership = (params = {}) => {
  return useMutation({
    mutationFn: async ({ id, key, value }: UpdateCompanyOwnershipParams) => {
     return updateCompanyOwnership(id, { [key]: value });
    },
    ...params
  });
};