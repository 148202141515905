import { STATES } from '~/utils';
import { WorksheetField } from '../../../worksheets/common/components/Field';
import WorksheetForm from '~/features/projectWizard/worksheets/common/components/WorksheetForm';
import { useDerivedRECFields } from '~/features/projectWizard/derived';


export default function Location() {
  // Updates REC data based on addressState changes
  useDerivedRECFields();

  return (
    <WorksheetForm>
      <WorksheetField.Row>
        <WorksheetField
          schemaKey="addressStreet"
          placeholder="Enter address"
          fieldLabel='Address'
        />
        <WorksheetField
          schemaKey="addressCity"
          fluid
          placeholder="Enter city"
          fieldLabel='City'
        />
      </WorksheetField.Row>
      <WorksheetField.Row>
        <WorksheetField
          schemaKey="addressState"
          search
          options={STATES}
          placeholder="Select state"
          fieldLabel='State'
        />
        <WorksheetField
          schemaKey="addressZip"
          placeholder="Enter zip code"
          fieldLabel='Zip Code'
        />
      </WorksheetField.Row>
      <WorksheetField
        schemaKey="utilityZone"
        placeholder="Enter name of electric utility"
        fieldLabel="Electric utility"
        maxLength={250}
      />
    </WorksheetForm>
  );
}