import { Modal } from '~/components';
import { Button } from '~/components';
import { 
  InvestorFeedbackBidStatus,
  InvestorFeedbackButtonText,
  InvestorFeedbackRating
} from '~/constants/project-investor-feedback';
import { Form } from 'semantic-ui-react';
import BidFeedbackRatingRadio from './BidFeedbackRatingRadio';
import { useForm } from '~/hooks/providers/FormContextProvider';
import { useSaveInvestorFeedback } from '~/mutations/project/useSaveInvestorFeedback';

interface BidFeedbackRatingFormProps {
  onSubmit: () => void,
  closeModal: () => void,
  bidStatus: string | null
}

interface BidFeedbackRatingFormState {
  feedbackRating: typeof InvestorFeedbackRating[keyof typeof InvestorFeedbackRating];
}

const BidFeedbackRatingForm = ({
  onSubmit,
  closeModal,
  bidStatus
}: BidFeedbackRatingFormProps) => {
  const { formState } = useForm<BidFeedbackRatingFormState>();

  const { mutateAsync: saveFeedback, status: saveFeedbackStatus } = useSaveInvestorFeedback();

  return (
    <>
      <Modal.Title>Capturing project feedback</Modal.Title>
        <Modal.Content>
          <Form>
            <p>
              Thank you for your bid! We’re interested in hearing what you think about this project. Please complete the form below, and we’ll review your answers to make sure we’re matching you with opportunities that fit your needs. 
            </p>
            <BidFeedbackRatingRadio />
          </Form>
        </Modal.Content>
        <Modal.Footer>
          {bidStatus !== InvestorFeedbackBidStatus.BID_DECLINED && (
            <Button primary outlined onClick={() => closeModal()}>
              Cancel
            </Button>
          )}
          <Button
            primary
            onClick={() => {
              if (formState.feedbackRating === InvestorFeedbackRating.GREAT_FIT) {
                saveFeedback(formState, { onSuccess: closeModal });
              } else {
                onSubmit();
              }}}
            disabled={!formState.feedbackRating || saveFeedbackStatus === 'loading'}
            >
            {formState.feedbackRating === InvestorFeedbackRating.GREAT_FIT ? InvestorFeedbackButtonText.SUBMIT_FEEDBACK : InvestorFeedbackButtonText.CONTINUE}
          </Button>
      </Modal.Footer>
    </>
  );
};

export default BidFeedbackRatingForm;