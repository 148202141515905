import { formatDollars } from '~/utils/formatNumber';
import { Project } from '~/types/project';
import { UnitDecimals } from '~/constants';
import { PricingEstimateTypeUnitDisplay } from '~/constants';
import { ProjectSavingsAnalysisData } from '~/types/project/ProjectSavingsAnalysis';
  
const calculateNetSavingsValueRange = (
  project: Project,
  currentPricing: number | undefined, 
  savingsType: ProjectSavingsAnalysisData | undefined,
): string | undefined => {
  
  const unit = savingsType?.unit as keyof typeof UnitDecimals;
  const hasResult = Boolean(currentPricing && project?.autopricingResult && Object.keys(project.autopricingResult).length > 0);

  return hasResult
    ? `${formatDollars(savingsType?.high, { dec: UnitDecimals[unit] })} - ${formatDollars(savingsType?.low, { dec: UnitDecimals[unit] })} / ${PricingEstimateTypeUnitDisplay[unit]}` 
    : '—';  
};

export default function netSavingsValueRange(project: Project) {
  const { ppaPricing, leasePricing, ppaSavings, leaseSavings } = project?.savingsAnalysis || {};
  return {
    ppaNetSavings: calculateNetSavingsValueRange(project, ppaPricing, ppaSavings),
    leaseNetSavings: calculateNetSavingsValueRange(project, leasePricing, leaseSavings),
  };
}