import Link from '~/components/Link';
import * as S from './styles';
import { useTeamQuery } from '~/requests/team';
import { Button, CompanyLogo } from '~/components';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { updateTeamCompany } from '~/api/team';
import externalAxiosInstance from 'axios';
import toast from 'react-hot-toast';
import { useUserContext } from '~/hooks';
import Modal from '~/components/Modal';

const CompanyLogoForm = () => {
  const { loggedInUser } = useUserContext();
  const { data, refetch } = useTeamQuery();
  const [logoSrc, setLogoSrc] = useState<string | null>(loggedInUser.isAdmin ? '/conductor_avatar.png' : data?.logoS3Url ?? null);
  const [removing, setRemoving] = useState(false);
  const queryClient = useQueryClient();

  const { mutateAsync: updateFile, status } = useMutation(async (file: File | null) => {
    try {
      const result = await updateTeamCompany({ fileName: file?.name ?? null });
      const successMessage = `Successfully ${file ? 'updated' : 'removed'} company logo.`;
      if (result?.logoUploadPresignedUrl && file) {
        try {
          await externalAxiosInstance.put(result.logoUploadPresignedUrl, file);
          toast.success(successMessage);
        } catch (err) {
          toast.error('Company info was uploaded successfully, but an error occurred uploading your company logo. Please refresh the page and try again.', { duration: 15000 });
          console.error(err);
        }
      } else {
        toast.success(successMessage);
      }
    } catch (err) {
      toast.error('Error occurred while updating your company profile information. Please refresh the page and try again.');
      console.error(err);
    }
  });

  const { open: openFileDialog } = useDropzone({   
    accept: 'image/jpeg,image/png,image/svg+xml',
    maxFiles: 1,
    noKeyboard: true,
    onDrop(acceptedFiles) {
      const uploadedFile = acceptedFiles?.[0];
      updateFile(uploadedFile);
      if (uploadedFile) {
        setLogoSrc(URL.createObjectURL(uploadedFile));
      }
      refetch();

      // Invalidate messages cache, since it uses logos. This forces refetching messages to get the new logo
      queryClient.invalidateQueries(['messages']);
    }
  });

  const hasLogo = logoSrc || loggedInUser.isAdmin;

  return (
    <div>
      <S.LogoHeader>Logo Management</S.LogoHeader>
      <div style={{
        display: 'flex',
        gap: 'var(--large)'
      }}>
        <S.LogoManagement>
          <div>
            <S.LogoMessage>
              Upload a logo so your partners on the app can quickly identify you. Your logo will appear on your counterparty’s messaging area when you’re attached to a project. Logos should be a transparent PNG file.
            </S.LogoMessage>
          </div>
        </S.LogoManagement>
        <div style={{ display: 'flex', alignItems: hasLogo ? 'flex-end' : 'center' }}>
          {hasLogo ? (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ 
                marginTop: '-1.5rem',
                opacity: status === 'loading' ? 0.5 : 1
              }}>
                <CompanyLogo src={logoSrc ?? ''} />
              </div>
              {loggedInUser.isAdmin ? (
                <div style={{ color: 'var(--color-charcoal)', fontSize: '0.8rem', fontStyle: 'italic', textAlign: 'center' }}>
                  To change the Conductor logo, please contact the engineering team.
                </div>
              ) : (
                <div style={{ display: 'flex', gap: 'var(--medium)'}}>
                  <Link as={Button} onClick={openFileDialog}>
                    Upload new logo
                  </Link>
                  <Link as={Button} color='red' onClick={() => setRemoving(true)}>
                    Remove my logo
                  </Link>
                </div>
              )}
            </div>
          ) : (
            <Link as={Button} onClick={openFileDialog}>
              Upload my logo
            </Link>
          )}
        </div>
      </div>

      {removing && (
        <Modal closeModal={() => setRemoving(false)}>
          <Modal.Title>Confirm logo removal</Modal.Title>
          <Modal.Content>
            <p>
              Are you sure you want to remove this logo entirely? 
              Without a logo, any partners you match with on a project will see simple text instead of your company’s preferred branding. 
            </p>
          </Modal.Content>
          <Modal.Footer>
            <Button 
              primary 
              onClick={() => {
                updateFile(null)
                  .then(() => {
                    setLogoSrc(null);
                    setRemoving(false);
                    refetch();
                  })
                  .catch((err) => {
                    console.error(err);
                    toast.error('Error occurred while removing your company logo. Please refresh the page and try again.');
                  });
              }} 
              disabled={status === 'loading'}
              loading={status === 'loading'}
            >
              Confirm removal
            </Button>
            <Button onClick={() => setRemoving(false)} primary outlined>Go back</Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default CompanyLogoForm;