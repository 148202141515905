import { orderBy, isEqual } from 'lodash';
import { ProjectDashboardFilters, ProjectFilters } from '~/constants';

interface TableState {
  column: string;
  data: any[];
  direction: 'asc' | 'desc';
  dashboardFilter?: string | undefined;
  dropdownFilters?: string[]; 
}

interface TableAction {
  type: string;
  data?: any[];
  column?: string;
  dashboardFilter?: string | undefined;
  dropdownFilters?: string[];
}

interface ProjectFilterOptions {
  statusDisplay?: string;
  isArchived: boolean;
  portfolioIsArchived: boolean;
  hasOpenConversation?: boolean;
  requiresCustomerProposal?: boolean;
}

export function filterProjects(projects: any[], dashboardFilter: string | undefined, dropdownFilters: string[]) {
  if (dropdownFilters?.length > 0) {
    return dropdownFilters.map((filter: string) => applyActiveFilters(projects, filter)).flat();
  } else if (dashboardFilter !== undefined) {
    return applyDashboardFilter(projects, dashboardFilter);
  } else {
    return projects;
  }
};

const applyDashboardFilter = (projects: any[], dashboardFilter: string) => {
  switch (dashboardFilter) {
    case ProjectDashboardFilters.ALL:
      return projects.filter(({ statusDisplay, isArchived, portfolioIsArchived }) => !isArchived && !portfolioIsArchived && statusDisplay !== 'BID_NOT_SELECTED' && statusDisplay !== 'DECLINED_TO_BID');
    case ProjectDashboardFilters.OPEN_ITEMS:
      return projects.filter(({ hasOpenConversation, requiresCustomerProposal, isArchived, portfolioIsArchived }) => (hasOpenConversation || requiresCustomerProposal) && !isArchived && !portfolioIsArchived);
    default:
      return projects;
  }
};

const applyActiveFilters =(projects: any[], activeFilter: string) => {
  const filterConditions = {
    [ProjectFilters.MARKET_QUOTE]: ({ statusDisplay, isArchived, portfolioIsArchived }: ProjectFilterOptions) =>
      statusDisplay === 'MARKET_QUOTE' && !isArchived && !portfolioIsArchived,
    [ProjectFilters.DRAFT]: ({ statusDisplay, isArchived, portfolioIsArchived }: ProjectFilterOptions) =>
      statusDisplay === 'NOT_SUBMITTED' && !isArchived && !portfolioIsArchived,
    [ProjectFilters.IN_REVIEW]: ({ statusDisplay, isArchived, portfolioIsArchived }: ProjectFilterOptions) =>
      statusDisplay === 'IN_REVIEW' && !isArchived && !portfolioIsArchived,
    [ProjectFilters.BIDDING]: ({ statusDisplay, isArchived, portfolioIsArchived }: ProjectFilterOptions) =>
      statusDisplay === 'BIDDING' && !isArchived && !portfolioIsArchived,
    [ProjectFilters.BID_REVIEW]: ({ statusDisplay, isArchived, portfolioIsArchived }: ProjectFilterOptions) =>
      statusDisplay === 'BID_REVIEW' && !isArchived && !portfolioIsArchived,
    [ProjectFilters.IN_DILIGENCE]: ({ statusDisplay, isArchived, portfolioIsArchived }: ProjectFilterOptions) =>
      statusDisplay === 'IN_DILIGENCE' && !isArchived && !portfolioIsArchived,
    [ProjectFilters.EXECUTED]: ({ statusDisplay, isArchived, portfolioIsArchived }: ProjectFilterOptions) =>
      statusDisplay === 'EXECUTED' && !isArchived && !portfolioIsArchived,
    [ProjectFilters.COMPLETED]: ({ statusDisplay, isArchived, portfolioIsArchived }: ProjectFilterOptions) =>
      statusDisplay === 'COMPLETED' && !isArchived && !portfolioIsArchived,
    [ProjectFilters.CLOSED]: ({ statusDisplay, isArchived, portfolioIsArchived }: ProjectFilterOptions) =>
      statusDisplay === 'BID_NOT_SELECTED' ||
      statusDisplay === 'DECLINED_TO_BID' ||
      isArchived ||
      portfolioIsArchived,
  };

  const condition = filterConditions[activeFilter];
  return condition ? projects.filter(condition) : projects;
};

export const DefaultSortOrders: { [key: string]: 'asc' | 'desc' } = {
  name: 'asc',
  portfolioName: 'asc',
  investorName: 'asc',
  installerName: 'asc',
  statusDisplay: 'asc',
  location: 'asc',
  size: 'asc',
  bidDeadline: 'desc',
  updatedAt: 'asc'
};

export function projectListReducer(state: TableState, action: TableAction): TableState {
  const data = (action.data ?? state.data);

  const column = action.column ?? state.column;
  const sortData = (data: any) => {
    if (typeof data?.[column] !== 'string') {
      return data[column];
    } else if (column === 'location') {
      // Flip state and city so we can prioritize sort by state first
      const [state, city] = data[column]?.split(', ')?.reverse() ?? [];
      if (!state && !city) {
        return '';
      } else {
        return `${state}, ${city ?? ''}`.toLowerCase();
      }
    } else {
      return data?.[column]?.toLowerCase();
    }
  };

  const getSortedData = (data: any[], direction: 'asc' | 'desc') => {
    if (column === 'bidDeadline') {
      // No matter the direction, we want to prioritize projects with bid deadlines at the top.
      // Using a custom .sort instead of lodash orderBy to have more control over sorting
      return data.sort((a, b) => {
        if (!a.bidDeadline && !b.bidDeadline) {
          return 0;
        } else if (!a.bidDeadline) {
          return 1;
        } else if (!b.bidDeadline) {
          return -1;
        } else {
          const aTime = new Date(a.bidDeadline).getTime();
          const bTime = new Date(b.bidDeadline).getTime();
          return direction === 'asc' ? aTime - bTime : bTime - aTime;
        }
      });
    } else if (column === 'statusDisplay') {
     // Sort by statusDisplay first, and if the status is BIDDING sort further by bid deadline
     // For bidding statuses, we want to prioritize projects with bid deadlines at the top.
     return data.sort((a, b) => {
      if (a.statusDisplay === 'BIDDING' && b.statusDisplay === 'BIDDING') {
        if (!a.bidDeadline && !b.bidDeadline) {
          return 0;
        } else if (!a.bidDeadline) {
          return 1;
        } else if (!b.bidDeadline) {
          return -1;
        } else {
          const aTime = new Date(a.bidDeadline).getTime();
          const bTime = new Date(b.bidDeadline).getTime();
          return direction === 'asc' ? aTime - bTime : bTime - aTime;
        }
      } 
      return direction === 'asc' ? a.statusDisplay.localeCompare(b.statusDisplay) : b.statusDisplay.localeCompare(a.statusDisplay);
     }); 
    }

    return orderBy(data, [sortData], [direction]);
  };

  switch (action.type) {
    case 'INIT': {
      if (state.dashboardFilter === undefined && state?.dropdownFilters?.length === 0) {
        return {
          ...state,
          data: getSortedData(filterProjects(data ?? [], ProjectDashboardFilters.ALL, []), state.direction),
        };
      }
      
      return {
        ...state,
        data: getSortedData(filterProjects(data ?? [], state.dashboardFilter, state.dropdownFilters ?? []), state.direction),
      };
    }

    case 'CHANGE_SORT': {
      // If the user clicks the same column, reverse the sort direction
      // Otherwise use the default sort order for the column
      const direction = action.column === state.column 
        ? (state.direction === 'asc' ? 'desc' : 'asc')
        : (DefaultSortOrders[action.column as string] ?? 'asc');
      
      return {
        ...state,
        column,
        data: getSortedData(data, direction),
        direction,
      };
    }

    case 'CHANGE_FILTER': {
      const { dropdownFilters, dashboardFilter } = action;

      if (dropdownFilters && dropdownFilters?.length > 0) {
        if (isEqual(state.dropdownFilters, dropdownFilters)) {
          return state;
        } 
        return {
          ...state,
          dashboardFilter: undefined,
          dropdownFilters: dropdownFilters,
          data: getSortedData(filterProjects(data, dashboardFilter, dropdownFilters), state.direction),
        };
      }
      
      if (isEqual(state.dashboardFilter, dashboardFilter)) {
        return state;
      } 
      return {
        ...state,
        dashboardFilter: dashboardFilter,
        dropdownFilters: [],
        data: getSortedData(filterProjects(data, dashboardFilter, []), state.direction),
      };
    }
    default:
      throw new Error();
  }
}