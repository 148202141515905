import { Modal } from '~/components';
import { Form } from 'semantic-ui-react';
import { FormRadioSelectGroup } from '~/components/form';
import { CreateProjectTypesRadioOptions } from '~/constants/create-project';
import { Button } from '~/components';
import { useForm } from '~/hooks';

interface CreateNewProjectSelectionFormProps {
  closeModal: () => void;
  onSubmit: () => void;
}

export default function CreateNewProjectSelectionForm({
  closeModal,
  onSubmit
}: CreateNewProjectSelectionFormProps) {
  const { formState } = useForm() as any;

  return (
    <>
      <Modal.Title>
        Create new project
      </Modal.Title>
      <Modal.Content>
        <Form>
          <FormRadioSelectGroup
            schemaKey="createProjectSelection"
            fieldLabel="What are you looking to do today?"
            values={CreateProjectTypesRadioOptions}
            inline
            stretched
          />  
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Button 
          primary 
          outlined 
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button 
          primary
          disabled={!formState.createProjectSelection} 
          onClick={() => onSubmit()}
        >
          Continue
        </Button>
      </Modal.Footer>
    </>
  );
}