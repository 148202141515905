import { WorksheetField } from '../common/components/Field';
import { InputType } from '~/schema';
import { ExpensesUnit, UnitDecimals } from '~/constants';
import { useProjectContext } from '~/hooks';
import { Button } from '~/components';

const defaultAdditionalExpense = {
  name: '',
  amount: null,
  escalator: null,
  expectedTerm: null,
  unit: ExpensesUnit.KWDC,
  notes: '',
};

export default function PricingEstimateOperatingExpenses () {
  const { project, updateProjectValue } = useProjectContext();

  const add = () => {
    updateProjectValue('additionalOperatingExpenses', [defaultAdditionalExpense]);  
  };

  const remove = () => {
    updateProjectValue('additionalOperatingExpenses', []);
  };

  return (
    !project?.additionalOperatingExpenses?.length ? (
      <p style={{ margin: 'var(--x-small) 0 var(--medium) 0' }}>
        If there is an additional operating expense you would like to add,{' '}
        <Button variant='link' onClick={add}>
          click here
        </Button>
        {' '}
        to add it.
      </p> 
    ) : (
      <>
        <WorksheetField 
          schemaKey={'additionalOperatingExpenses.0.name'}
          fieldLabel={'Additional operating expense - Name'}
          placeholder="Enter name"
        />
        <WorksheetField
          type="number"
          schemaKey={'additionalOperatingExpenses.0.amount'}
          fieldLabel="Expected annual amount"
            numberType={InputType.DOLLARS}
          unitField="unit"
          units={[
            {value: ExpensesUnit.KWDC, label: 'per kWdc', decimals: UnitDecimals.KWDC},
            {value: ExpensesUnit.YEAR, label: 'per year', decimals: UnitDecimals.YEAR},
          ]}
        />
        <WorksheetField.Row isQuote>
          <div className="labelled-input">
            <WorksheetField
              type="number"
              schemaKey={'additionalOperatingExpenses.0.escalator'}
              fieldLabel="Expected annual escalator"
              numberType={InputType.PERCENT}
              decimals={2}
            />
          </div>
          <div className="labelled-input">
            <WorksheetField
              type="number"
              schemaKey={'additionalOperatingExpenses.0.expectedTerm'}
              fieldLabel="Expected term"
              decimals={0}
              label="years"
            />
          </div>
        </WorksheetField.Row>
        <Button 
          variant="link"  
          onClick={() => remove()} 
          style={{ textAlign: 'left', marginBottom: 'var(--medium)' }}>
          Remove this additional operating expense
        </Button>
      </>
    )
  );
}