import { useDropzone } from 'react-dropzone';
import { useUpdateProposalRequest } from '~/mutations/project/useUpdateProposalRequest';
import { useUploadFileMessageMutation } from '~/mutations/conversations/useUploadFileMessageMutation';
import toast from 'react-hot-toast';
import { useMessagingContext } from '~/features/messaging/providers/MessagingProvider';
import { useProjectContext } from './providers/ProjectContextProvider';

export default function useUploadCustomerProposal(
  id: string,
  supportConversationId: string
) {
  const { updateProjectValue } = useProjectContext();
  const { addNewMessage } = useMessagingContext();

  const uploadFileMessage = useUploadFileMessageMutation();
  const { mutateAsync: updateProposalRequest, status: updateProposalRequestStatus } = useUpdateProposalRequest();

  const { getInputProps, open } = useDropzone({   
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    async onDrop(acceptedFiles) {
      try {
        if (acceptedFiles?.length) {
          const message = await uploadFileMessage.mutateAsync({
            file: acceptedFiles?.[0],
            conversationId: supportConversationId
          });
          addNewMessage(message);
          const messageId = message?.id;
          const result = await updateProposalRequest({ id, messageId });
          updateProjectValue('customerProposal', result, { doSave: false, patch: true }); 
          toast.success('Proposal uploaded successfully.', { duration: 5000 });
        } else {
          toast.error('Only one file can be uploaded at a time.');
        }
      } catch (error) {
        console.error(error);
        toast.error('Error uploading file.', { duration: 5000 });
      }
    }
  });

  return { getInputProps, open, updateProposalRequestStatus };
}