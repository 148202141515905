import styled from 'styled-components';
import PricingEstimateWorksheet from '../projectWizard/worksheets/pricingEstimate/PricingEstimateWorksheet';
import PricingEstimateQuoteTable from './Quote/PricingEstimateQuoteTable';

const ReviewContainer = styled.div`
  padding: var(--x-large) 0 var(--2x-large);
  display: flex;
  justify-content: space-between;
  gap: 4rem; 
`;

export default function PricingEstimateReview() {
  return (
    <ReviewContainer>
      <PricingEstimateWorksheet />    
      <div style={{ width: '23rem'}}>
        <PricingEstimateQuoteTable />
      </div>
    </ReviewContainer>
  );
}