import { SyntheticEvent, useState } from 'react';
import { useQueryParamState } from '~/hooks/useQueryParamState';
import { DASHBOARD_QUERY_PARAM_STATE } from '../../constants';
import { ProjectDashboardFilters } from '~/constants';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { Dropdown, Checkbox } from 'semantic-ui-react';
import { Button } from '~/components';
import { ProjectDashboardFiltersList, ProjectFiltersList } from '~/constants';
import sendFilterClickEvent from '~/utils/analytics/sendFilterClickEvent';

interface ProjectFiltersDropdownProps {
  currentDashboardFilter: string;
  dropdownFilters: string[];
  setDropdownFilters: (filters: string[]) => void;
  activeTabIndex: number;
  setActiveTabIndex: (index: number) => void;
}

export default function ProjectFiltersDropdown({ 
  currentDashboardFilter,
  dropdownFilters,
  setDropdownFilters,
  setActiveTabIndex,
}: ProjectFiltersDropdownProps) {
  const { permissions } = usePermissions();

  const projectFiltersList = ProjectFiltersList.filter(({ hideForInvestors }) => {
    return !(permissions.hasInvestorRole && !permissions.hasDeveloperRole && hideForInvestors);
  });

  const [dashboardState, setDashboardState] = useQueryParamState(DASHBOARD_QUERY_PARAM_STATE);
  const [showFiltersDropdown, setShowFiltersDropdown] = useState(false);

  // The checkbox just prevents default behavior and doesn't actually change the state.
  // It just propagates click events to the Dropdown.Item component which handles the click.
  const checkboxHandler = (e: SyntheticEvent) => e.preventDefault();

  // The dropdown handler is what handles selecting/unselecting single and multiple project filters.
  const dropdownHandler = (e: SyntheticEvent, filter: string) => {
    e.preventDefault();
    e.stopPropagation();

    //Google Analytics event
    sendFilterClickEvent(filter);

    let updatedFilters: string[] = [];
    if (dropdownFilters.includes(filter as string)) {
      updatedFilters = dropdownFilters.filter((active) => active !== filter);
    } else {
      updatedFilters = [...dropdownFilters, filter as string];
    }
  
    setDropdownFilters(updatedFilters); 
  };

  const handleApplyFilters = (e: SyntheticEvent) => {
    //This stops dropdown clicks from reaching the button and allows us to continue to manually set the active tab index
    e.stopPropagation();
    
    setDashboardState({
      ...dashboardState,
      dashboardFilter: dropdownFilters.length > 0 ? undefined : ProjectDashboardFiltersList[0].value,
      dropdownFilters: dropdownFilters.length > 0 ? dropdownFilters : undefined,
    });

    //If a user has no filter selections and clicks the button
    //dashboardFilter will be set to 'ALL'
    //so we need to manually set the tab index back to 0, and manually close the dropdown due to e.stopPropagation()
    if (currentDashboardFilter === ProjectDashboardFilters.ALL) {
      setActiveTabIndex(0);
    }
  
    setShowFiltersDropdown(false);
  };

  return (
    <Dropdown
      open={showFiltersDropdown}
      trigger={<span>{ProjectDashboardFiltersList[1].label}</span>}
      onClick={() => setActiveTabIndex(1)}
      closeOnBlur={true} 
      closeOnChange={false}
      style={{ maxWidth: '15rem' }}
      onOpen={() => setShowFiltersDropdown(true)}
      onClose={() => setShowFiltersDropdown(false)}
    >
      <Dropdown.Menu style={{ paddingBottom: 'var(--small)' , marginTop: '1.25rem' }}>
        {projectFiltersList?.map((filter) => {
          return (
            <Dropdown.Item
              key={filter.value}
              onClick={(e: SyntheticEvent) => dropdownHandler(e, filter.value)}
              style={{
                maxWidth: '11rem',
                fontSize: '11px',
                margin: '0',
                display: 'flex',
                gap: 'var(--x-small)',
              }}
            >
              <Checkbox 
                checked={dropdownFilters.includes(filter.value)}
                label={filter.label}
                value={filter.value}
                onChange={checkboxHandler}
                onClick={checkboxHandler}
                onMouseDown={checkboxHandler}
                onMouseUp={checkboxHandler}
              />
            </Dropdown.Item>
          );
        })}
        <div style={{ textAlign: 'center', margin: 'var(--x-small) 0' }}>
          <Button 
            primary
            onClick={(e: SyntheticEvent) => handleApplyFilters(e)}
          >
            Apply filter
          </Button>
        </div>
      </Dropdown.Menu> 
    </Dropdown>
  );
}