import useUploadCustomerProposal from '~/hooks/useUploadCustomerProposal';
import { useProjectContext } from '~/hooks';
import { useConversationListQuery } from '~/requests/conversations/useConversationListQuery';
import { useDownloadMessageFileQuery } from '~/requests/conversations/useDownloadMessageFileQuery';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { Button } from '~/components';
import { ButtonProps } from '~/components/Button';
import { PricingEstimateProposalTypeDisplay } from '~/constants';
import { Conversation } from '~/types/conversations/Conversation';
import useArchiveToastCheck from '~/hooks/archive/useArchiveToastCheck';

interface PricingEstimateProposalButtonProps {
  proposalType: string;
  onClick: () => void;
}

export default function PricingEstimateProposalButton({
  proposalType,
  onClick,
}: PricingEstimateProposalButtonProps) {
  const { project } = useProjectContext();
  const { permissions } = usePermissions();
  const { data: conversations } = useConversationListQuery();
  const supportConversationId = conversations?.find((conversation: Conversation) => conversation.type === 'SUPPORT' && !!conversation.installerId)?.id as string;
  
  const { getInputProps, open, updateProposalRequestStatus } = useUploadCustomerProposal(project?.id, supportConversationId);
  const { refetch: downloadProposalFile, status: downloadProposalFileStatus } = useDownloadMessageFileQuery({
    conversationId: supportConversationId,
    messageId: project?.customerProposal?.messageId as string
  });

  const proposalText = PricingEstimateProposalTypeDisplay[proposalType];
  const handleClick = useArchiveToastCheck(onClick);
  const handleUpload = useArchiveToastCheck(open);
  const handleDownload = useArchiveToastCheck(downloadProposalFile);

  const buttonProps: ButtonProps = (() => {
    if (!project?.customerProposal) {
      return {
        content: `Request customer ${proposalText} proposal`,
        disabled: !project?.autopricingResult,
        onClick: () => handleClick()
      };
    } 
    
    if (project?.customerProposal?.requestedAt && !project?.customerProposal?.messageId) {
      return {
        content: `Customer ${proposalText} proposal requested`,
        loading: updateProposalRequestStatus === 'loading',
        disabled: updateProposalRequestStatus === 'loading' || (permissions.hasProjectDeveloperAccess && !permissions.isAdmin),
        onClick: () => handleUpload()
      };
    }
    
    if (project?.customerProposal?.uploadedAt && project?.customerProposal?.messageId) {
      return {
        content: `View customer ${proposalText} proposal`,
        loading: downloadProposalFileStatus === 'loading',
        disabled: downloadProposalFileStatus === 'loading',
        onClick: () => handleDownload()
      };
    }

    return {};
  })();

  return (
    <>
      {project?.customerProposal?.proposalType && !project?.customerProposal?.uploadedAt && (
        <input {...getInputProps()} />
      )}
      <Button 
        primary 
        outlined
        {...buttonProps}
      />
    </>
  );
}