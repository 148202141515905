import { useCallback } from 'react';
import useArchiveToast from '~/hooks/archive/useArchiveToast';

export default function useArchiveToastCheck(fn: () => void): () => void {
  const { showArchiveToast } = useArchiveToast();

   return useCallback(() => {
    if (showArchiveToast()) {
      return; 
    }
    return fn(); 
  }, [showArchiveToast, fn]);
}

