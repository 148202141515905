import { ProductionUnit, RevenueUnit, UnitDecimals } from '~/constants';
import WorksheetForm from '~/features/projectWizard/worksheets/common/components/WorksheetForm';
import { useProjectContext } from '~/hooks';
import { WorksheetField } from '../../../worksheets/common/components/Field';
import { InputType } from '~/schema';
import { Button } from '~/components';

const defaultAdditionalRevenue = {
  name: '',
  amount: null,
  unit: ProductionUnit.KWH,
  escalator: null,
  expectedTerm: null,
  notes: '',
};

export default function OtherRevenueStreams () {
  const { project, updateProjectValue } = useProjectContext();

  const add = () => {
    updateProjectValue('additionalRevenues', [...project.additionalRevenues, { ...defaultAdditionalRevenue }]);
  };

  const remove = (i: number) => {
    updateProjectValue('additionalRevenues', [
      ...project.additionalRevenues.slice(0, i),
      ...project.additionalRevenues.slice(i + 1, project.additionalRevenues.length)
    ]);
  };

  return (
    <WorksheetForm>
      <p>
        This section is optional - if you have additional revenues or
        incentives related to this project, you can add them here.
      </p>
      {project.additionalRevenues?.length === 0 && (
        <p>
          If there are additional revenues you would like to add,{' '}
          <Button variant="link" onClick={add}>
            click here
          </Button>
          {' '}
          to add the first one.
        </p> 
      )}
      {project.additionalRevenues?.map((revenue: any, i: number) => (
        <>
          <WorksheetField 
            fieldLabel={`Revenue Stream ${i + 1} – Name`}
            placeholder="Enter name"
            schemaKey={`additionalRevenues.${i}.name`}
          />
          <WorksheetField
            fieldLabel="Expected annual amount"
            type="number"
            schemaKey={`additionalRevenues.${i}.amount`}
            numberType={InputType.DOLLARS}
            unitField="unit"
            placeholder={revenue.unit === RevenueUnit.KWH ? '0.0000' : '0'}
            units={[
              {value: RevenueUnit.KWH, label: 'per kWh', decimals: UnitDecimals.KWH},
              {value: RevenueUnit.YEAR, label: 'per year', decimals: UnitDecimals.YEAR},
            ]}
          />
          <WorksheetField.Row>
            <WorksheetField
              fieldLabel="Expected annual escalator"
              schemaKey={`additionalRevenues.${i}.escalator`}
              type="number"
              numberType={InputType.PERCENT}
              decimals={2}
              placeholder="0.00"
            />
            <WorksheetField
              fieldLabel="Expected term"
              schemaKey={`additionalRevenues.${i}.expectedTerm`}
              type="number"
              placeholder="0"
              decimals={0}
              label="years"
            />
          </WorksheetField.Row>
          <WorksheetField
            fieldLabel="Notes"
            placeholder="Please describe the revenues provided, particularly the contracted nature of those revenues."
            schemaKey={`additionalRevenues.${i}.notes`}
            type="textarea"
          />
          <Button variant="link" onClick={() => remove(i)} style={{ marginBottom: i !== project?.additionalRevenues?.length - 1 ? 'var(--medium)' : 0 }}>
            Remove this revenue stream
          </Button>
        </>
      ))}
      {project.additionalRevenues?.length > 0 && (
        <div>
          <Button variant="link" onClick={add}>
            Add an additional revenue stream
          </Button>
        </div> 
      )}
    </WorksheetForm>
  );
}