export const CustomerTypes = {
  PUBLIC_SCHOOL: 'Public School',
  PRIVATE_SCHOOL: 'Private School',
  MUNICIPALITY: 'Municipality',
  OTHER_GOVERNMENTAL_ENTITY: 'Other Governmental Entity',
  OTHER_NON_PROFIT_NATIONWIDE_LARGE: 'Other Non-profit - Nationwide / Large',
  OTHER_NON_PROFIT_LOCAL_SMALL: 'Other Non-profit - Local / Small',
  FOR_PROFIT_INTERNATIONAL_EXTRA_LARGE: 'For-profit - International / Extra Large',
  FOR_PROFIT_NATIONAL_LARGE: 'For-profit - National / Large',
  FOR_PROFIT_REGIONAL_MEDIUM: 'For-profit - Regional / Medium',
  FOR_PROFIT_LOCAL_SMALL: 'For-profit - Local / Small',
  UTILITY: 'Utility',
  FEDERALLY_RECOGNIZED_TRIBAL_LAND: 'Federally-Recognized Tribal Land',
  HOAS: 'HOAs',
  COMMUNITY_SOLAR: 'Community Solar',
};

export const CustomerTypeOptions = [
  CustomerTypes.PUBLIC_SCHOOL,
  CustomerTypes.PRIVATE_SCHOOL,
  CustomerTypes.MUNICIPALITY,
  CustomerTypes.OTHER_GOVERNMENTAL_ENTITY,
  CustomerTypes.OTHER_NON_PROFIT_NATIONWIDE_LARGE,
  CustomerTypes.OTHER_NON_PROFIT_LOCAL_SMALL,
  CustomerTypes.FOR_PROFIT_INTERNATIONAL_EXTRA_LARGE,
  CustomerTypes.FOR_PROFIT_NATIONAL_LARGE,
  CustomerTypes.FOR_PROFIT_REGIONAL_MEDIUM,
  CustomerTypes.FOR_PROFIT_LOCAL_SMALL,
  CustomerTypes.UTILITY,
  CustomerTypes.FEDERALLY_RECOGNIZED_TRIBAL_LAND,
  CustomerTypes.HOAS,
].map(type => ({ key: type, text: type, value: type }));