import { ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useProjectContext, useFadeInStyles } from '~/hooks';
import { useRequiredResponseCountsQuery } from '~/requests/projects/useRequiredResponseCounts';
import { PageHeaderProvider } from '../PageHeader/PageHeaderProvider';
import PageHeader from '../PageHeader';
import Container from '../Container';
import { Label } from 'semantic-ui-react';
import Button from '../Button';
import ArchiveButton from '../ArchiveButton';
import MainContainer from '../ProjectPage/MainContainer';
import PageLoader from '../PageLoader';
import PrepareProjectForInvestorsModal from './PrepareProjectForInvestorsModal';
import { usePermissions } from '~/requests/permissions/usePermissions';

const PricingEstimatePageContent = ({ children }: { children: ReactNode }) => {
  const { project, projectState } = useProjectContext();
  const { pathname } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: requiredResponseCountsData, isFetching } = useRequiredResponseCountsQuery();
  const requiredResponseCounts = requiredResponseCountsData?.reduce((acc: number, curr: any) => acc + curr.count, 0) ?? 0;

  return (
    <PageHeaderProvider>
      <Container style={useFadeInStyles()}>
        <PageHeader 
          project={project}
          navTabs={[{
            path: `/pricing-estimate/${project.id}/worksheet`,
            content: 'Data + Pricing',
          }, 
          {
            path: `/pricing-estimate/${project.id}/dataroom`,
            content: 'Documents',
          }, 
          {
            path: `/pricing-estimate/${project.id}/messages`,
            content: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>Messages</div>
                {isFetching ? null : (
                  pathname !== `/project/${project.id}/messages` && requiredResponseCounts > 0 && (
                    <Label circular color='red' style={{ marginLeft: 'var(--x-small)' }}>
                      {requiredResponseCounts}  
                    </Label>
                  )
                )}
              </div>
            ),
          }]}
          navContent={
            <div 
              style={{
                display: 'flex',
                gap: 'var(--x-small)',
                position: 'absolute', 
                right: 0, 
                top: 0 
              }}>
                <ArchiveButton />
                <Button 
                  primary
                  disabled={projectState.hasErrors}
                  onClick={() => setIsModalOpen(true)}
                >
                  Prepare project for investors
                </Button>
            </div>
          }
        />
        <MainContainer>{children}</MainContainer>
      </Container>
      {isModalOpen && <PrepareProjectForInvestorsModal closeModal={() => setIsModalOpen(false)} />}
    </PageHeaderProvider>   
  );
};

export default function PricingEstimatePage({ children }: { children: ReactNode}) {
  const { project, fetchStatus } = useProjectContext();
  const { status: permissionStatus } = usePermissions();

  if (fetchStatus === 'loading' || permissionStatus === 'loading') {
    return <div style={{ width: '100%', position: 'relative', height: '100vh' }}><PageLoader /></div>;
  } else if (project?.id) {
    return <PricingEstimatePageContent>{children}</PricingEstimatePageContent>;
  } else {
    return null;
  }
}