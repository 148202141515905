import { useProjectContext } from '~/hooks';
import { System } from '~/utils/calculators';
import { useDerivedSavingsAutocalculateFields } from '~/features/projectWizard/derived/useDerivedSavingsAutocalculateFields';
import { WorksheetField } from '../common/components/Field';
import { InputType } from '~/schema';
import { UnitDecimals } from '~/constants';

export default function PricingEstimateYearOneSavings() {
  const { project } = useProjectContext();
  const yearOneProduction = System.yearOneProduction(project?.projectArrays);

  // Determine if field should be disabled.
  // This happens when year one production is not set and the user types a value into one of the fields
  const yearOneSavingsDisabled = !yearOneProduction && project?.avoidedCostOfSolar !== null && project?.avoidedCostOfSolar !== undefined;

  // Update avoided cost of solar / year one savings when either of the fields change
  useDerivedSavingsAutocalculateFields();

  return (
    <WorksheetField
      type="number"
      fieldLabel="Year one utility bill savings"
      schemaKey="yearOneSavings"
      numberType={!yearOneSavingsDisabled ? InputType.DOLLARS : undefined}
      decimals={UnitDecimals.YEAR_ONE_SAVINGS}
      disabled={yearOneSavingsDisabled}
      placeholder={yearOneSavingsDisabled ? 'N/A' : undefined}
    />
  );
};