import styled from 'styled-components';
import { Header as SemanticHeader } from 'semantic-ui-react';

export const Container = styled.div`
  position: relative;
  padding: var(--x-large) 0 var(--2x-large);
  .ui.message {
    margin-bottom: var(--medium);
  }
`;

export const MetadataHeader = styled.div`
  position: absolute;
  top: var(--2x-small);
  left: 0;
  right: 0;
  line-height: 1.25rem;
  font-size: 0.875rem;
  color: var(--color-charcoal);
  display: flex;
  justify-content: space-between;
  > * + * {
    margin-left: var(--small);
  }
`;

export const TableContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 7.5rem;
  margin-bottom: var(--2x-large);
`;  

export const Header = styled(SemanticHeader)`
  font-size: var(--medium);
  font-weight: 700;
  padding-bottom: var(--medium);
  margin: 0;
`;

export const ProjectDetailsContainer = styled.div`
  max-width: 40.625rem;
`;

export const BudgetContainer = styled.div`
`;

export const EstimatesContainer = styled.div`
  //column gap align estimates tables grid with key details and budget tables
  //could be refactored as a sub-grid when available
  grid-column: 1 / -1;

  display: grid;
  column-gap: 7.25rem;
  align-items: end; //aligns estimate buttons to the bottom of the container / page
`;


export const ProductionEstimateContainer = styled.div<{ autopricingResult: any[] }>`
  grid-row: 2 / 3;
  > *:last-child {
    padding-top: ${props =>
      `calc(1.5rem * ${props.autopricingResult?.length ? props.autopricingResult.length - 1 : 1})`}; // use this to align the lines in estimate tables
  }
  width: 40rem; // aligns with key details width
  grid-column: 1;
`;


export const PricingEstimateContainer = styled.div`
  grid-row: 2 / 3;
  width: 23rem; //aligns with budget table width
  grid-column: 2;
  justify-self: end;
`;

export const SavingsAnalysisContainer = styled.div`
  grid-row: 3 / 4;
  grid-column: 2;
  margin-top: var(--x-large);
`;
