import { InvestorBidStatus, PricingType, Unit } from '~/constants';
import { sizeKwdc } from '~/utils/calculators/system';
import { nrsFee as calcNrsFee, nrsFeeWithUnit } from '~/utils/calculators/nrsFee';
import epcCost from './epcCost';
import getBudgetCostAmount from './getBudgetCostAmount';


export const normalizeToOverall = (sizeKwdc: any, budgetItem: any) => {
  if (!budgetItem?.amount) {
    return 0;
  }
  return (budgetItem?.unit === Unit.WDC
      ? budgetItem?.amount * sizeKwdc * 1000
      : budgetItem?.amount
  ) ?? 0;
};

export const normalizeToPerWdc = (sizeKwdc: any, budgetItem: any) => {
  if (!budgetItem?.amount) {
    return 0;
  }
  return (budgetItem?.unit === Unit.WDC
      ? budgetItem?.amount
      : budgetItem?.amount / (sizeKwdc * 1000)
  ) ?? 0;
};

const getExpectedItc = (project: any) => {
  // return a sum of itc adders + 30%
  return project?.itcAdders?.reduce((acc: any, adder: any) => acc + adder?.value ?? 0, 0) + 30;
};

const getExpectedItcCost = (project: any) => {
  const expectedItc = getExpectedItc(project);
  return (
    (epcCost(project) ?? 0) + 
    (getBudgetCostAmount(project, 'INTERCONNECTION') ?? 0) +
    (getBudgetCostAmount(project, 'OTHER_ITC') ?? 0)
  ) 
  * (expectedItc / 100);
};

const calculateProjectCosts = (project: any, { perWdc, overall, nrsFee, bid }: any = {}) => {
  const result: any = {};
  result.bid = bid || project?.projectBids?.find((bid: any) => bid.bidStatus === InvestorBidStatus.ACCEPTED || bid.bidStatus === InvestorBidStatus.PENDING);
  if (result.bid && (project.pricingType === PricingType.ACQUISITION)) {
    result.epcCost = result.bid.amount;
    result.epcCostUnit = result.bid.unit;
  } else {
    result.epcCost = project?.epcCost;
    result.epcCostUnit = project?.epcCostUnit;
  }
  result.sizeKwdc = sizeKwdc(project);
  result.expectedItc = getExpectedItc(project);
  result.expectedItcCost = getExpectedItcCost(project);

  //TODO Project typing
  const {INTERCONNECTION: interconnection, OTHER_ITC: otherItc, OTHER_NON_ITC: otherNonItc} = project?.budgetCosts ?? {};

  if (overall) {
    result.overall = {};
    result.overall.interconnection = normalizeToOverall(result.sizeKwdc, interconnection);
    result.overall.otherItc = normalizeToOverall(result.sizeKwdc, otherItc);
    result.overall.otherNonItc = normalizeToOverall(result.sizeKwdc, otherNonItc);
    result.overall.epcCost = normalizeToOverall(result.sizeKwdc, {amount: result.epcCost, unit: result.epcCostUnit});
    result.overall.total = result.overall.interconnection + result.overall.otherItc + result.overall.otherNonItc + result.overall.epcCost;
    result.overall.totalMinusFee = result.overall.total;
    if (nrsFee) {
      if (project.pricingType === PricingType.ITC && result.bid?.amount) {
        const bidAmountWithFee = getProjectBidOverallAmount(project, result.bid, true);
        const bidAmountWithoutFee = getProjectBidOverallAmount(project, result.bid, false);
        result.overall.nrsFee = bidAmountWithFee - bidAmountWithoutFee;
      } else if (project.pricingType !== PricingType.ITC) {
        result.overall.nrsFee = calcNrsFee(project, result.overall.epcCost) ?? 0;
        result.overall.total += result.overall.nrsFee;
      }
    }
  }

  if (perWdc) {
    result.perWdc = {};
    result.perWdc.interconnection = normalizeToPerWdc(result.sizeKwdc, interconnection);
    result.perWdc.otherItc = normalizeToPerWdc(result.sizeKwdc, otherItc);
    result.perWdc.otherNonItc = normalizeToPerWdc(result.sizeKwdc, otherNonItc);
    result.perWdc.epcCost = normalizeToPerWdc(result.sizeKwdc, {amount: result.epcCost, unit: result.epcCostUnit});
    result.perWdc.total = result.perWdc.interconnection + result.perWdc.otherItc + result.perWdc.otherNonItc + result.perWdc.epcCost;
    result.perWdc.totalMinusFee = result.perWdc.total;
    if (nrsFee) {
      if (overall) {
        result.perWdc.nrsFee = (result.overall.nrsFee / (result.sizeKwdc * 1000)) ?? 0;
      } else {
        const epcCostOverall = normalizeToOverall(result.sizeKwdc, {amount: result.epcCost, unit: result.epcCostUnit});
        const nrsFeeOverall = calcNrsFee(project, epcCostOverall) ?? 0;
        result.perWdc.nrsFee = (nrsFeeOverall / (result.sizeKwdc * 1000)) ?? 0;
      }
      result.perWdc.total += result.perWdc.nrsFee;
    }
  }

  return result;
};

// Given a project and a bid, get the bid amount converted to total $
export const getProjectBidOverallAmount = (project: any, bid: any, withNrsFee = true) => {
  if (!project || !bid?.amount) return;

  let amount;
  if (project.pricingType === PricingType.ITC && bid.unit === Unit.PERCENT) {
    const itcCost = getExpectedItcCost(project);
    amount = itcCost * bid.amount / 100;
    if (withNrsFee) {
      const fee = nrsFeeWithUnit(project)?.fee ?? 0;
      // For ITC, nrs fee is the percent of the whole ITC value
      amount += (fee * itcCost / 100);
    }
  } else {
    amount = normalizeToOverall(sizeKwdc(project), bid);
    if (withNrsFee) {
      amount += (calcNrsFee(project, amount) ?? 0);
    }
  }

  return amount;
};

export default calculateProjectCosts;
