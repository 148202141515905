import { WorksheetField } from '../../../../worksheets/common/components/Field';
import { LandTypeDropdownOptions, PanelWashingDropdownOptions } from '~/constants/production-inputs';

export default function ProductionValidationOptions() {
  return (
    <WorksheetField.Row>
      <WorksheetField
        schemaKey="dnvInputLandType"
        fieldLabel="Land type"
        options={LandTypeDropdownOptions}
        placeholder={'Urban - lightly used'}
        optional
        fluid
      />
      <WorksheetField
        schemaKey="dnvInputPanelWashing"
        fieldLabel="Panel washing"
        options={PanelWashingDropdownOptions}
        placeholder={'None'}
        optional
        fluid
      />
    </WorksheetField.Row>
  );
}