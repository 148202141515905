import WorksheetForm from '~/features/projectWizard/worksheets/common/components/WorksheetForm';
import { WorksheetField } from '~/features/projectWizard/worksheets/common/components/Field';
import { InputType } from '~/schema';
import { ExpensesUnit, PropertyTaxTypeRadioOptions, SiteLeaseExtensionOptions, SiteLeaseTermDropdownOptions, UnitDecimals } from '~/constants';

export default function SiteExpenses () {
  return (
    <WorksheetForm>
      <WorksheetField
        fieldLabel="Site lease cost"
        schemaKey="siteLeaseCost"
        type="number"
        numberType={InputType.DOLLARS}
        unitField="siteLeaseCostUnit"
        units={[
          {value: ExpensesUnit.KWDC, label: 'per kWdc', decimals: UnitDecimals.KWDC},
          {value: ExpensesUnit.YEAR, label: 'per year', decimals: UnitDecimals.YEAR},
        ]}
      />
      <WorksheetField.Row>
        <WorksheetField
          fieldLabel="Annual escalator"
          schemaKey="siteLeaseEscalator"
          type="number"
          numberType={InputType.PERCENT}
          decimals={2}
        />
        <WorksheetField
          type="dropdown"
          fieldLabel='Site lease term'
          placeholder="Select term length"
          schemaKey="siteLeaseTerm"
          options={SiteLeaseTermDropdownOptions}
        />
      </WorksheetField.Row>
      <WorksheetField 
        type="radio"
        schemaKey="siteLeaseExtensions"
        fieldLabel="Site lease extensions"
        helpText="For how many additional years will the Project have a unilateral extension option?"
        values={SiteLeaseExtensionOptions}
        inline
        stretched
      />
      <WorksheetField
        fieldLabel="Site lease acreage"
        schemaKey="siteLeaseAcreage"
        type="number"
        label="Acres"
        decimals={0}
      />
      <WorksheetField
        fieldLabel="Unusual site conditions"
        schemaKey="unusualSiteConditions"
        type="textarea"
        placeholder="Please use this field to describe any unusual site conditions associated with the project, if necessary."
        optional
      />
      <WorksheetField
        fieldLabel="Property tax type"
        schemaKey="propertyTaxType"
        helpText="Please note that some states have blanket exemptions; others have exemptions at certain size thresholds."
        type="radio"
        values={PropertyTaxTypeRadioOptions}
        inline
        stretched
      />
      <WorksheetField
        schemaKey="propertyTaxAmount"
        type="number"
        numberType={InputType.DOLLARS}
      />
      <WorksheetField
        schemaKey="propertyTaxEscalator"
        type="number"
        numberType={InputType.PERCENT}
        decimals={2}
        width="half"
      />
      <WorksheetField
        type="textarea"
        schemaKey="propertyTaxNotes"
        fieldLabel="Tax notes"
        placeholder="Please list out any nuances important for pricing."
        optional
      />
    </WorksheetForm>
  );
}