import { useMutation } from 'react-query';
import { duplicateProject } from '~/api/projects';

export const useDuplicateProject = (params = {}) => {
  return useMutation({
    mutationFn: async({ id, data }: { id: string; data: any }) => {
      return duplicateProject(id, data);
    },
    ...params,
  });
};

   