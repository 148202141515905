import React from 'react';
import * as S from './styles';
import { Link } from 'react-router-dom';
import { Sidebar as SemanticSidebar, Menu, Icon } from 'semantic-ui-react';
import Avatar from './avatar';
import { useUserContext } from '~/hooks';
import PopupMenuTrigger from './popups/PopupMenuTrigger';
import { SidebarProvider } from './context';
import { useNotificationCount } from '~/requests/notifications';
import { isQaEnvironment, storage } from '~/utils';
import { colors } from 'theme';
import sendMenuClickEvent from '~/utils/analytics/sendMenuClickEvent';

export const Sidebar = () => {
  const { loggedInUser } = useUserContext();
  const { data: notificationCount } = useNotificationCount();

  return (
    <SidebarProvider>
      <SemanticSidebar
        as={Menu}
        animation='overlay'
        icon='labeled'
        inverted
        vertical
        visible
        style={{
          width: 'var(--sidebar-width)',
          background: 'var(--bg-primary-dark)',
          overflow: 'visible!important',
          backgroundColor: storage.getSpoofedEmail() ? '#5b4491' : colors.midnightBlue
        }}
      >
        <S.MenuHeader>
          <a href="/" onClick={ () => sendMenuClickEvent('Conductor Logo')}>
            <img src="/logo-longform.png" alt="Conductor Solar Home" />
          </a>
        </S.MenuHeader>
        <S.NavContainer>
          <S.NavContainerTop>
            <S.MenuItem as={Link} onClick={() => sendMenuClickEvent('Dashboard')} to="/">
              <Icon name='chart bar' size="large" />
              <span>Dashboard</span>
            </S.MenuItem>
            <PopupMenuTrigger name="projects">
              <Icon name="clipboard" size="large" />
              <span>Projects</span>
            </PopupMenuTrigger>
            <PopupMenuTrigger name="portfolios">
              <Icon name="briefcase" size="large" />
              <span>Portfolios</span>
            </PopupMenuTrigger>
            {loggedInUser?.role?.code === 'ADMIN' && (
              <>
                <S.MenuItem as={Link} onClick={() => sendMenuClickEvent('Users')} to="/users">
                  <Icon name="users" size="large" />
                  <span>Users</span>
                </S.MenuItem>
                <S.MenuItem as={Link} onClick={() => sendMenuClickEvent('Companies')} to="/companies">
                  <Icon name="building" size="large" />
                  <span>Companies</span>
                </S.MenuItem>
              </>
            )}
          </S.NavContainerTop>
          <S.NavContainerBottom>
            <PopupMenuTrigger name="notifications">
              Notifications
              {notificationCount > 0 && (
                <S.MenuItemLabel>
                  {notificationCount}
                </S.MenuItemLabel>
              )}
            </PopupMenuTrigger>
            <S.MenuItem as={Link} onClick={() => sendMenuClickEvent('Settings')} to="/settings">
              Settings
            </S.MenuItem>
           <S.MenuItem as="a" href="https://conductor.solar/company-blog" target="_blank" rel="noopener noreferrer" onClick={() => sendMenuClickEvent('Blog')}>
              Blog
            </S.MenuItem>
            <S.MenuItem as="a" href="mailto:support@conductor.solar" onClick={() => sendMenuClickEvent('Contact Us')}>
              Contact Conductor
            </S.MenuItem>
            {isQaEnvironment() && (
              <S.MenuItem as={Link} onClick={() => sendMenuClickEvent('QA')} to="/qa">
                QA
              </S.MenuItem> 
            )}
            <S.MenuItem as={Link} to="/logout">
              Log out
            </S.MenuItem>
            <S.NavBottomUserInfo>
              <Avatar />
              <div>
                <S.NavBottomUserName style={{color: storage.getSpoofedEmail() ? colors.red : colors.offWhite}}>
                  {loggedInUser?.name}
                </S.NavBottomUserName>
                <S.NavBottomUserEmail style={{color: storage.getSpoofedEmail() ? colors.red : colors.charcoal}}>
                  {loggedInUser?.email}
                </S.NavBottomUserEmail>
              </div>
            </S.NavBottomUserInfo>
          </S.NavContainerBottom>
        </S.NavContainer>
      </SemanticSidebar>
    </SidebarProvider>
  );
};