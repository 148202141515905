import * as S from './styles';
import Link from '~/components/Link';
import { FormNumberInput, FormSchemaAwareWidget } from '~/components/form';
import { 
  ContractType, 
  PricingType, 
  Unit, 
  UnitDecimals,
  InvestorFeedbackBidStatus 
} from '~/constants';
import { useProjectContext } from '~/hooks';
import { FormContextProvider, useForm } from '~/hooks/providers/FormContextProvider';
import { InputType } from '~/schema';
import { Button } from '~/components';
import { postProjectBid } from '~/api/projectBids';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import BidConfirmationModal from './modals/BidConfirmationModal';
import budget from '~/utils/displayValues/project/budget';
import { calculateProjectCosts } from '~/utils/calculators';
import { usePermissions } from '~/requests/permissions/usePermissions';
import useArchiveToast from '~/hooks/archive/useArchiveToast';
import InvestorFeedbackModal from './modals/BidFeedback';
import toast from 'react-hot-toast';
import { getProjectBidOverallAmount } from '~/utils/calculators/costs';
import { formatDollars } from '~/utils';
import ITCBidConfirmationModal from './modals/ITCBidConfirmationModal';
import DeclineBidConfirmationModal from './modals/DeclineBidConfirmationModal';

interface BidFormState {
  amount: number | null;
  unit: keyof typeof Unit;
  investorAcknowledgmentsFlag: boolean;
}


const BidForm = () => {
  const { formState, updateFormValue } = useForm<BidFormState>();
  const { permissions } = usePermissions();
  const { project, id, fetchProject } = useProjectContext();
  const { showArchiveToast } = useArchiveToast();
  const [modalType, setModalType] = useState<null | 'BID_CONFIRMATION' | 'BID_CONFIRMATION_ITC' | 'FEEDBACK_PENDING_BID' | 'FEEDBACK_DECLINED_BID' | 'DECLINE_BID_CONFIRMATION'>(null);
  const closeModal = () => setModalType(null);
  const history = useHistory();

  const handleBidSubmit = () => {
    fetchProject();
    // if there are no bids and the project is a standalone project, prompt investor for feedback
    if (!project?.projectBids?.length && !project?.portfolioId) {
      setModalType('FEEDBACK_PENDING_BID');
    } else if (project?.portfolioId) {
      history.push(`/portfolio/${project.portfolioId}`);
    }

    updateFormValue('amount', '');
    updateFormValue('investorAcknowledgmentsFlag', false);
    if (project?.pricingType !== PricingType.ITC) {
      updateFormValue('unit', project?.pricingType === PricingType.OFFTAKE ? (project?.contractType === ContractType.PPA ? Unit.KWH : Unit.YEAR) : Unit.WDC);
    }
  };

  const { mutateAsync: submitBid, status: submitBidStatus } = useMutation(
    async () => {
      await postProjectBid(formState, id);
      handleBidSubmit();
      toast.success('Thank you for submitting your bid.', { duration: 5000 });
    }
  );

  const projectCosts =  calculateProjectCosts(project, {
    perWdc: true,
    overall: true,
    nrsFee: true,
    bid: formState
  });

  const budgetDisplayValues = budget(project, projectCosts, permissions);

  const decimals = project?.pricingType === PricingType.ITC ? 4 : UnitDecimals[formState.unit]; // Make special exception for ITC to be 4 decimal spaces

  return (
    <>
      <S.BidForm>
        <div style={{ display: 'flex', width: '100%' }}>
          <FormNumberInput
            schemaKey="amount"
            fieldLabel={`My bid${project?.pricingType === PricingType.ITC ? ', as a percentage of total ITC value' : ''}`}
            decimals={decimals}
            label={project?.pricingType === PricingType.OFFTAKE ? (project?.contractType === ContractType.PPA ? 'per kWh' : 'per year') : undefined}
            numberType={formState.unit === Unit.PERCENT ? InputType.PERCENT : InputType.DOLLARS}
            unitField="unit"
            units={project?.pricingType === PricingType.ACQUISITION ? [
              { value: Unit.WDC, label: 'per Wdc', decimals: UnitDecimals.WDC },
              { value: Unit.TOTAL, label: 'total', decimals: UnitDecimals.TOTAL },
            ] : undefined}
            fieldStyle={{ width: '100%' }}
          />

          {project?.pricingType === PricingType.ITC && (
            <div style={{ width: '100%', marginLeft: 'var(--small)' }}>
              <strong style={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
                Calculated bid value
              </strong>
              <div style={{ lineHeight: '2.625rem' }}>
                {formState.amount ? formatDollars(getProjectBidOverallAmount(project, formState, false), { dec: 0 }) : '-'}
              </div>
            </div>
          )}
        </div>

        <S.BidFormCheckbox>
          <FormSchemaAwareWidget schemaKey="investorAcknowledgmentsFlag" />
          <label htmlFor="investorAcknowledgmentsFlag" >
            I’ve reviewed the <Link to="/acknowledgements" primary underline bold>Investor’s Acknowledgment</Link>
          </label>
        </S.BidFormCheckbox>

        <S.BidFormActions>
          <Button 
            primary 
            onClick={() => {
              if (showArchiveToast()) {
                return;
              } else if (project?.pricingType === PricingType.ITC) {
                setModalType('BID_CONFIRMATION_ITC');
              } else {
                setModalType('BID_CONFIRMATION');
              }
            }}
            disabled={!formState.investorAcknowledgmentsFlag}
          >
            Submit bid
          </Button>
          <Button 
            primary 
            outlined
            onClick={() => {
              if (showArchiveToast()) {
                return;
              } else if (project?.pricingType === PricingType.ITC) {
                setModalType('DECLINE_BID_CONFIRMATION');
              } else { 
                setModalType('FEEDBACK_DECLINED_BID');
              }}
            }
          >
            Decline to bid
          </Button>
        </S.BidFormActions>
      </S.BidForm>
      
      {modalType === 'BID_CONFIRMATION' && 
        <BidConfirmationModal 
          submitBid={submitBid}
          submitBidStatus={submitBidStatus}
          budgetDisplayValues={budgetDisplayValues}
          modalPricingType={project?.pricingType as keyof typeof PricingType}
          bidAmount={formState?.amount}
          closeModal={closeModal} 
        />
      }
      {modalType === 'BID_CONFIRMATION_ITC' &&
        <ITCBidConfirmationModal
          submitBid={submitBid}
          submitBidStatus={submitBidStatus}
          projectCosts={projectCosts}
          closeModal={closeModal}
        />
      }
      {modalType === 'FEEDBACK_PENDING_BID' && 
        <InvestorFeedbackModal 
          bidStatus={InvestorFeedbackBidStatus.BID_PENDING} 
          closeModal={closeModal}
        />
      }
      {modalType === 'FEEDBACK_DECLINED_BID' && 
        <InvestorFeedbackModal 
          bidStatus={InvestorFeedbackBidStatus.BID_DECLINED} 
          closeModal={closeModal}
        />
      }
      {modalType === 'DECLINE_BID_CONFIRMATION' &&
        <DeclineBidConfirmationModal closeModal={closeModal} />
      }
    </>
  );
};

export default function() {
  const { project } = useProjectContext();

  let unit: keyof typeof Unit;

  if (project?.pricingType === PricingType.OFFTAKE && project?.contractType === ContractType.PPA) {
    unit = Unit.KWH;
  } else if (project?.pricingType === PricingType.OFFTAKE ) {  
    unit = Unit.YEAR;
  } else if (project?.pricingType === PricingType.ITC) {
    unit = Unit.PERCENT;
  } else {
    unit = Unit.WDC;
  }

  const defaults: BidFormState = {
    amount: null,
    unit,
    investorAcknowledgmentsFlag: false
  };

  return (
    <FormContextProvider defaults={defaults}>
      <BidForm />
    </FormContextProvider>
  );
}