export const Unit: { [key: string]: 'WDC' | 'TOTAL' | 'YEAR' | 'KWH' | 'PERCENT' } = {
  WDC: 'WDC',
  TOTAL: 'TOTAL',
  YEAR: 'YEAR',
  KWH: 'KWH',
  PERCENT: 'PERCENT',
};

export const UnitDecimals: { [key: string]: number } = {
  WDC: 3,
  KWDC: 4,
  KWAC: 4,
  DOLLARS_PER_MWH: 2,
  TOTAL: 0,
  YEAR: 0,
  YEAR_ONE_SAVINGS: 0,
  YEAR_ONE_PAYMENT: 0,
  KWH: 4,
  PERCENT: 2,
  NET_SAVINGS_PERCENT: 0,
  DOLLARS: 2
};

export const UnitDisplay = {
  WDC: 'Wdc',
  TOTAL: 'Total',
  YEAR: 'Year',
  KWH: 'kWh',
};



export const ProductionUnit = {
  KWH: 'KWH',
  KWHKW: 'KWH_KW',
};

export const ProductionUnitDisplay = {
  KWH: 'kWh',
  KWHKW: 'kWh per kW',
};

export const ExpensesUnit = {
  KWDC: 'KWDC',
  YEAR: 'YEAR',
};

export const OMPriceDisplay = {
  KWDC: '($/kW-year)',
  YEAR: '($/year)',
} as any;

export const RevenueUnit = {
  KWH: 'KWH',
  YEAR: 'YEAR',
};

export const TaxUnit = {
  KWDC: 'KWDC',
  KWAC: 'KWAC',
  YEAR: 'YEAR',
};

export const TaxUnitDisplay = {
  KWDC: 'kWdc',
  KWAC: 'kWac',
  YEAR: 'year',
};