import styled from 'styled-components';
import { Dropdown, Icon } from 'semantic-ui-react';
import { usePortfolioContext } from '~/hooks';
import Button from '~/components/Button';
import { downloadPortfolioExcel, downloadProjectExcel } from '~/utils';
import { usePermissions } from '~/requests/permissions/usePermissions';
import sendProjectClickEvent from '~/utils/analytics/sendProjectClickEvent';
import sendPortfolioClickEvent from '~/utils/analytics/sendPortfolioClickEvent';

const DropdownWrapper = styled.div`
  --color-primary: var(--color-secondary);
`;

export default function DownloadExcelButton() {
  const { portfolio, displayBlobs, locations, portfolioTotals } = usePortfolioContext();
  const { permissions } = usePermissions();

  return (
    <DropdownWrapper>
      <Dropdown
        icon={null}
        direction='left'
        selectOnBlur={false}
        options={[
          {
            key: 'portfolio',
            onClick: () => {
              // Analytics
              sendPortfolioClickEvent('Excel Download', portfolio.name, portfolio.id);
              downloadPortfolioExcel(portfolio, locations, portfolioTotals, displayBlobs, permissions);
            },
            text: 'for all projects in portfolio',
            active: false
          },
          ...displayBlobs.map((projectBlob: any) => ({
            key: projectBlob.project.id,
            onClick: () => {
              // Analytics
              sendProjectClickEvent('Excel Download', projectBlob?.project?.name, projectBlob?.project?.id, {status: projectBlob?.project?.status});
              downloadProjectExcel(projectBlob.project, projectBlob.projectCosts, projectBlob.projectDisplayValues, permissions);
            },
            text: `for ${projectBlob.project.name}`,
            active: false
          }))
        ]}
        trigger={
          <Button outlined>
            <Icon name='arrow circle down' />
            Project data
          </Button>
        }
      />
    </DropdownWrapper>
    
  );
}