import { Form } from 'semantic-ui-react';
import { FormContextProvider, useForm } from '~/hooks/providers/FormContextProvider';
import { FormSchemaAwareWidget } from '~/components/form';
import { usePortfolioContext, useProjectContext } from '~/hooks';
import { useAddProjectDeadline } from '~/mutations/project/useAddProjectDeadline';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { isEqual } from 'lodash';

interface BidDeadlineFormState {
  bidDeadline: string;
}

const BidDeadlineContainer = styled.div`
  &&& {
    input {
      color: var(--color-charcoal);
    }
  }
`;

const BidDeadlineFormImpl: React.FC = () => {
  const { formState, updateFormValue } = useForm<BidDeadlineFormState>();
  const { portfolio, updatePortfolioValue } = usePortfolioContext();
  const { project, updateProjectValue } = useProjectContext();
  const isPortfolio = portfolio?.projects;

  const { mutateAsync: updateProjectBidDeadline } = useAddProjectDeadline() as any;
  const handleSubmit = async () => {
    if (updatePortfolioValue && isPortfolio) {
      updateProjectBidDeadline({ portfolioId: portfolio.id, date: formState.bidDeadline });
      updatePortfolioValue('bidDeadline', formState.bidDeadline, { doSave: false });
    } else if (project) {
      updateProjectBidDeadline({ projectId: project.id, date: formState.bidDeadline });
      updateProjectValue('bidDeadline', formState.bidDeadline, { doSave: false });
    }
  };

  const findInvitationStatus = () => {
    const invitationStatus = project?.investorQueue?.map?.((investor: any) => investor.status);
    return invitationStatus;
  };  

  const formStateRef = useRef(formState);
  const autoSaveUserInfoTimeoutRef = useRef(handleSubmit) as any;
  const deadlineEmptyErrorRef = useRef() as any;
  useEffect(() => {
    const matchStatus = findInvitationStatus();
    const isInvited = matchStatus?.includes('INVITED');
 
    if (formState.bidDeadline === null && isInvited) {
      toast.error('Bid deadline must have a selected date.', { duration: 3000 });
      deadlineEmptyErrorRef.current = true;
    } else {
      deadlineEmptyErrorRef.current = false;
    }

    if (!deadlineEmptyErrorRef.current && !isEqual(formState, formStateRef.current)) {
      if (autoSaveUserInfoTimeoutRef.current) {
        clearTimeout(autoSaveUserInfoTimeoutRef.current);
      }
      autoSaveUserInfoTimeoutRef.current = setTimeout(handleSubmit, 3000);
      formStateRef.current = formState;
    }
  }, [formState]);

  return (
    <BidDeadlineContainer>
      <Form>
        <FormSchemaAwareWidget
          datepicker
          fieldLabel="Bid Deadline"
          schemaKey="bidDeadline"
          onBlur={() => {
            if (deadlineEmptyErrorRef.current) {
              const currentBidDeadline = project?.bidDeadline ?? portfolio?.bidDeadline;
              updateFormValue('bidDeadline', currentBidDeadline);
            }
          }}
        />
      </Form>
    </BidDeadlineContainer>
  );
};

const BidDeadlineForm: React.FC = () => {
  const { portfolio } = usePortfolioContext();
  const { project } = useProjectContext();
  const defaults: BidDeadlineFormState = {
    bidDeadline: portfolio?.bidDeadline ?? project?.bidDeadline,
  };

  return (
    <FormContextProvider defaults={defaults}>
      <BidDeadlineFormImpl />
    </FormContextProvider>
  );
};

export default BidDeadlineForm;
