import { useEffect, useMemo, useState } from 'react';
import * as S from './styles';
import { Dropdown } from 'semantic-ui-react';
import Link from '~/components/Link';
import { usePortfolioContext } from '~/hooks';
import { useProjectList } from '~/requests/projects/useProjectList';
import { getAvailableProjectOptions } from '../../AddProjectsDropdown';
import AddOrRemoveProjectCheckbox from './AddOrRemoveProjectCheckbox';
import CreateNewProjectModal from '~/modals/CreateNewProject';
import { Project } from '~/types/project';
import { usePermissions } from '~/requests/permissions/usePermissions';
import RemovePortfolioProjectModal from '~/modals/RemovePortfolioProjectModal';
import { CreateProjectTypes } from '~/constants';

export default function ManageProjectsDropdown() {
  const { portfolio } = usePortfolioContext();
  const [showProjectDropdown, setShowProjectDropdown] = useState(false);
  const { permissions } = usePermissions();

  // Get the list of projects that can be added to the portfolio
  const { data: projectListQueryResult } = useProjectList();
  const isInvestor = !permissions.isAdmin && permissions.hasPortfolioInvestorAccess;
  const parentPortfolioProjects = portfolio?.projects?.filter((project: any) => !project.parentId);
  const projects = isInvestor ? portfolio?.projects : parentPortfolioProjects;
  const [portfolioProjects, setPortfolioProjects] = useState(projects);
  const [openCreateProjectModal, setOpenCreateProjectModal] = useState(false);

  const [projectToRemove, setProjectToRemove] = useState<Project | null>(null);

  const projectOptions = useMemo(() => {
    return getAvailableProjectOptions(projectListQueryResult, portfolio?.investorId, false)
      .filter((project: Project) => !portfolioProjects?.find((p: Project) => p.id === project.id));
  }, [projectListQueryResult, portfolioProjects, portfolio?.investorId, showProjectDropdown]);

  useEffect(() => {
    if (!showProjectDropdown) {
      setPortfolioProjects(projects);
    }
  }, [portfolio, showProjectDropdown]);

  if (portfolio?.isArchived) {
    return null;
  }

  return (
    <S.DropdownContainer>
      <Dropdown 
        trigger={<S.DropdownTrigger>Manage projects</S.DropdownTrigger>}
        pointing="top right"
        closeOnBlur={true}
        closeOnChange={false}
        scrolling
        onOpen={() => setShowProjectDropdown(true)}
        onClose={() => setShowProjectDropdown(false)}
      >
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setOpenCreateProjectModal(true)}>
            <Link as="button" 
              primary 
              underline 
              bold 
            >
              Create new project
            </Link>
          </Dropdown.Item>
          {portfolioProjects?.map((project: Project) => (
            <AddOrRemoveProjectCheckbox 
              key={`currentProject-${project.id}`} 
              project={project} 
              selected
              setProjectToRemove={setProjectToRemove}
            />
          ))}
          {projectOptions.map((project: Project) => (
            <AddOrRemoveProjectCheckbox 
              key={`projectOption-${project.id}`} 
              project={project} 
              setProjectToRemove={setProjectToRemove} 
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {projectToRemove && 
        <RemovePortfolioProjectModal
          project={projectToRemove}
          onRemove={() => {
            setPortfolioProjects(portfolioProjects?.filter((p: Project) => p.id !== projectToRemove.id));
            setProjectToRemove(null);
          }}
          closeModal={() => {
            setProjectToRemove(null);
          }}
        />
      }
      {openCreateProjectModal && 
        <CreateNewProjectModal 
          closeModal={() => setOpenCreateProjectModal(false)} 
          portfolioId={portfolio?.id}
          projectType={CreateProjectTypes.PROJECT}
        />
      }
    </S.DropdownContainer>
  );
}