import { useEffect, useReducer, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import { Icon } from 'semantic-ui-react';
import { getCompanies } from '~/api/admin';
import { useUpdateCompanyOwnership } from '~/mutations/admin/useUpdateCompanyOwnership';
import { CompanyOwnershipDropdownOptions } from '~/constants';
import { Container, Table } from '~/components';
import AdminNotesModal from './AdminNotesModal';
import { orderBy } from 'lodash';
import { useAdminUsersQuery } from '~/requests/admin/useAdminUsersQuery';
import useAnalyticsPageView from '~/hooks/analytics/useAnalyticsPageView';
import { CompanyOwnership } from '~/types/company';

function sortReducer(state: any, action: any) {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        data: orderBy(action.data, [state.column], [state.direction])
      };
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        const direction = state.direction === 'asc' ? 'desc' : 'asc';
        return {
          ...state,
          data: orderBy(state.data, [state.column], [direction]),
          direction,
        };
      } 

      return {
        column: action.column,
        data: orderBy(state.data, [action.column], ['asc']),
        direction: 'asc',
      };
    default:
      throw new Error();
  }
}

export default function AdminCompanies() {
  const [adminNotesCompanyId, setAdminNotesCompanyId] = useState<string>();

  const { data: companies, status: fetchCompaniesStatus } = useQuery('companies', getCompanies, {
    refetchOnWindowFocus: false
  });
  const { data: users, status: fetchUsersStatus } = useAdminUsersQuery();
  const { mutateAsync: updateCompanyOwnership, status: updateCompanyOwnershipStatus } = useUpdateCompanyOwnership();

  const queryClient = useQueryClient();

  const [state, dispatch] = useReducer(sortReducer, {
    column: 'companyName',
    data: companies,
    direction: 'asc',
  });

  useAnalyticsPageView('/companies', 'Companies');

  useEffect(() => {
    dispatch({ 
      type: 'INIT', 
      data: companies
    });
  }, [companies]);
  
  const getSort = (column: any) => {
    const sort = state.column === column ? state.direction : undefined;
    if (sort === 'asc') return 'ascending';
    if (sort === 'desc') return 'descending';
    return sort;
  };

  const updateSort = (column: any) => {
    return dispatch({ type: 'CHANGE_SORT', column });
  };

  const [updateMap, setUpdateMap] = useState<{ [key: string]: boolean }>({});

  const handleUpdate = async (
    id: string, 
    key: string, 
    value: string, 
    row: { [key: string]: string }, 
    currentValue: string
  ) => {
    try {
      await updateCompanyOwnership({ id, key, value });
      toast.success('Successfully updated company data.', { duration: 5000 });
    } catch (error) {
      console.error('Error updating company ownership:', error);
      toast.error('Failed to update company data. Please try again', { duration: 5000 });
      row[key] = currentValue; // Revert the row value
      queryClient.setQueryData('companies', companies); // Update the cache
    }
  };

  const updateCompanyValue = (
    id: string, 
    key: string, 
    value: string
  ) => {
    const row = companies.find((company: CompanyOwnership) => company.companyId === id);
    if (!row) {
      return;
    }

    const currentValue = row[key]; 
    row[key] = value;
    queryClient.setQueryData('companies', [...companies]); 

    setUpdateMap({ 
      ...updateMap,
      [`${id}_${key}`]: true,
    });

    handleUpdate(id, key, value, row, currentValue);

    setUpdateMap({
      ...updateMap,
      [`${id}_${key}`]: false,
    });
  };

  const handleSaveNotes = (company: CompanyOwnership, notes: string) => {
    updateCompanyValue(company.companyId, 'notes', notes);
    setAdminNotesCompanyId(undefined);
  };

  const isLoading = fetchCompaniesStatus === 'loading' || fetchUsersStatus === 'loading' || updateCompanyOwnershipStatus === 'loading';

  return (
    <Container style={{ paddingTop: 'var(--x-large)', position: 'relative' }}>
      <h1>Companies</h1>
      <p>Use the table below to determine Conductor Admin ownership of companies on the platform, as well as to document their solar and 3rd party finance experience and any other relevant notes. </p>
      {isLoading && <Table.Loader />}
      <div
        style={isLoading ? {
          marginTop: 0,
          minHeight: 'calc(100vh - 16rem',
          background: 'var(--color-pure-white)'
        }: {}}
      >
        <Table textAlign="center" sortable>
          <Table.Header  style={{ position: 'sticky', top: 0 }}>
            <Table.Row>
              <Table.HeaderCell 
                textAlign="left"
                sorted={getSort('companyName')}
                onClick={() => updateSort('companyName')}
              >
                Company Name
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={getSort('companyType')}
                onClick={() => updateSort('companyType')}
              >
                Company type
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={getSort('ownerName')}
                onClick={() => updateSort('ownerName')}
              >
                Conductor Lead
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={getSort('solarExperience')}
                onClick={() => updateSort('solarExperience')}
              >
                Solar experience
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={getSort('thirdPartyExperience')}
                onClick={() => updateSort('thirdPartyExperience')}
              >
                3rd Party<br/>Finance experience
              </Table.HeaderCell>
              <Table.HeaderCell>Admin notes</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.data?.map((company: CompanyOwnership) => (
              <Table.Row key={company.companyId}>
                <Table.Cell textAlign="left">{company.companyName}</Table.Cell>
                <Table.Cell>{company.companyType ?? '-'}</Table.Cell>
                <Table.Cell
                  isEditable
                  value={company.ownerId}
                  defaultValue="-"
                  options={
                    users?.map((user) => ({
                      key: user.id,
                      text: user.name,
                      value: user.id,
                    })) ?? []
                  }
                  onChange={(value) => {
                    updateCompanyValue(company.companyId, 'ownerId', value);
                    queryClient.invalidateQueries('ownership');
                  }}
                />
                <Table.Cell
                  isEditable
                  value={company.solarExperience}
                  defaultValue="-"
                  options={CompanyOwnershipDropdownOptions}
                  onChange={(value) => updateCompanyValue(company.companyId, 'solarExperience', value)}
                />
                <Table.Cell 
                  isEditable
                  value={company.thirdPartyExperience}
                  defaultValue="-"
                  options={CompanyOwnershipDropdownOptions}
                  onChange={(value) => updateCompanyValue(company.companyId, 'thirdPartyExperience', value)}
                />
                <Table.CellButton onClick={() => setAdminNotesCompanyId(company.companyId)}>
                  <Icon name="edit" />
                </Table.CellButton>
                {adminNotesCompanyId === company.companyId && (
                  <AdminNotesModal
                    company={company}
                    closeModal={() => setAdminNotesCompanyId(undefined)}
                    onSave={(notes) => handleSaveNotes(company, notes)}
                  /> 
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </Container>
  );
}