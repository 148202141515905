type ProjectInterconnectionType = 'BEHIND_METER' | 'FRONT_OF_METER';

export const ProjectInterconnectionTypeCodes = {
  BEHIND_METER: 'BEHIND_METER' as ProjectInterconnectionType,
  FRONT_OF_METER: 'FRONT_OF_METER' as ProjectInterconnectionType
};

export const ProjectionInterconnectionTypes = {
  BEHIND_METER: 'Behind the meter',
  FRONT_OF_METER: 'In front of the meter'
} as any;

export const ProjectInterconnectionTypeOpts = Object.keys(ProjectionInterconnectionTypes).map((key: any) => ({
  label: ProjectionInterconnectionTypes[key as 'BEHIND_METER' | 'FRONT_OF_METER'] as any,
  value: key
}));

export const ProjectInterconnectionTypesShort = {
  BEHIND_METER: 'Behind the meter',
  FRONT_OF_METER: 'Front of meter'
};
