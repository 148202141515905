export const ProjectStatus = {
  DRAFT: 'DRAFT', // Project is not yet submitted, or has been rejected by the admin. Not in review -- only the installer is acting on it.
  MARKET_QUOTE: 'MARKET_QUOTE', // Pricing estimate that can be converted to a project
  IN_REVIEW: 'IN_REVIEW', // Project submitted to Conductor Solar, but never assigned to an investor.
  MATCHING: 'MATCHING', // A project has been assigned to an investor, but no bid has been accepted.
  BID_REVIEW: 'BID REVIEW', //Date has passed for project bid deadline
  IN_DILIGENCE: 'IN_DILIGENCE', //  A bid has been accepted. The data room may not be set up yet, but that’s the next thing to do in the app.
  EXECUTED:'EXECUTED', // The project has finished diligence phase and definitive documents have been signed. 
  COMPLETED:'COMPLETED', // Definitive documents have been signed, construction has completed and the project is now in the “completed” phase.
  DECLINED_TO_BID: 'DECLINED_TO_BID', // Investor declined to bid.
  BID_REJECTED: 'BID_REJECTED' // A different bid was accepted.
};

export const ProjectStatusDisplay = {
  [ProjectStatus.DRAFT]: 'Not Submitted',
  [ProjectStatus.MARKET_QUOTE]: 'Market Quote',
  [ProjectStatus.IN_REVIEW]: 'In Review',
  [ProjectStatus.MATCHING]: 'In Review',
  [ProjectStatus.BID_REVIEW]: 'Bid Review',
  [ProjectStatus.IN_DILIGENCE]: 'In Diligence',
  [ProjectStatus.EXECUTED]: 'Executed',
  [ProjectStatus.COMPLETED]: 'Deal Completed',
  [ProjectStatus.DECLINED_TO_BID]: 'Declined to Bid',
  [ProjectStatus.BID_REJECTED]: 'Bid Not Selected'
};

export const ProjectWorkflow= {
  ARCHIVED: 'ARCHIVED',
  MARKET_QUOTE: 'MARKET_QUOTE',
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  IN_REVIEW: 'IN_REVIEW',
  BIDDING: 'BIDDING',
  BID_REVIEW: 'BID_REVIEW',
  IN_DILIGENCE: 'IN_DILIGENCE',
  EXECUTED: 'EXECUTED',
  COMPLETED: 'COMPLETED',
};

export const ProjectWorkflowDisplay = {
  [ProjectWorkflow.ARCHIVED]: 'Archived',
  [ProjectWorkflow.MARKET_QUOTE]: 'Estimate',
  [ProjectWorkflow.NOT_SUBMITTED]: 'Not Submitted',
  [ProjectWorkflow.IN_REVIEW]: 'In Review',
  [ProjectWorkflow.BIDDING]: 'Bidding',
  [ProjectWorkflow.BID_REVIEW]: 'Bid Review',
  [ProjectWorkflow.IN_DILIGENCE]: 'In Diligence',
  [ProjectWorkflow.EXECUTED]: 'Executed',
  [ProjectWorkflow.COMPLETED]: 'Completed',
};

export const DocumentStatus = {
  DRAFT: 'DRAFT',
  FINAL: 'FINAL',
};

