import WorksheetForm from '~/features/projectWizard/worksheets/common/components/WorksheetForm';
import { WorksheetField } from '../../../../worksheets/common/components/Field';
import { CustomerTypeOptions } from '~/constants';
import CustomerSavingsForm from './CustomerSavingsForm';

export default function Information () {
  return (
    <WorksheetForm>
      <WorksheetField
        schemaKey="customerName"
        placeholder="Enter customer name"
        helpText='Entity name of who will be signing the offtake contract. Ideally, this is their legal name, but it’s OK if you don’t know that right now.'
      />
      <WorksheetField
        schemaKey="customerType"
        placeholder="Select customer type2"
        options={CustomerTypeOptions}
      />
      <CustomerSavingsForm  />
      <WorksheetField
        schemaKey="customerCreditRating"
        placeholder="Enter credit details"
        helpText="Use this space to indicate whatever high-level information is known about the customer's credit quality (i.e., their financial strength)."
      />
      <WorksheetField
        fieldLabel="Community solar subscription details"
        schemaKey="communitySolarSubscriptionDetails"
        optional
        placeholder="Add any subscriber nuances or requirements here (e.g., LMI percentage, anchor subscribers, residential percentage, etc.)"
        type="textarea"
      />
    </WorksheetForm>
  );
}