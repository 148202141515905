import { useCallback } from 'react';
import { usePortfolioContext } from '../providers/PortfolioContextProvider';
import { useProjectContext } from '../providers/ProjectContextProvider';
import usePageType from '../usePageType';
import toast from 'react-hot-toast';

export default function useArchiveToast() {
  const pageType = usePageType();

  const { project } = useProjectContext();
  const { portfolio } = usePortfolioContext();

  const showArchiveToast = useCallback(() => {
    const isArchived = project?.isArchived || project?.portfolio?.isArchived || portfolio?.isArchived;
    if (isArchived) {
      toast.error(`This ${pageType} is archived so cannot be acted on.`, { duration: 5000 });
    }
    return isArchived;
  }, [project, portfolio]);

  return { showArchiveToast };
}