import { useHistory } from 'react-router-dom';
import { useProjectContext } from '~/hooks';
import Modal from '../Modal';
import Button from '../Button';
import useArchiveToastCheck from '~/hooks/archive/useArchiveToastCheck';
import { useUpdateProject } from '~/mutations/project/useUpdateProject';
import { ProjectStatus } from '~/constants';

export default function PrepareProjectForInvestorsModal( 
  { closeModal }: { closeModal: () => void }
) {
  const { project } = useProjectContext();
  const history = useHistory();

  const { mutateAsync: updateProject, status: updateProjectStatus} = useUpdateProject();

  const handleSubmit = useArchiveToastCheck(async () => {
    await updateProject({
      id: project.id, 
      data: { status: ProjectStatus.DRAFT }
    });
    history.push(`/project/${project.id}/review`);
  });

  return (
    <Modal closeModal={updateProjectStatus === 'loading' ? undefined : closeModal}>
      <Modal.Title>Prepare project for investors</Modal.Title>
      <Modal.Content>
        <p>This will convert your pricing estimate to a full project, with all of the data fields required by investors. Estimate results will be on the Summary tab and edits to the project data will be on the Data tab. This change is not reversible.</p>      
      </Modal.Content>
      <Modal.Footer>
        <Button 
          primary 
          outlined 
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button 
          primary
          loading={updateProjectStatus === 'loading'}
          disabled={updateProjectStatus === 'loading'} 
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}