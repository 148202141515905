import { formatNumber } from '~/utils/formatNumber';
import { UnitDecimals } from '~/constants';
import { Project } from '~/types/project';
import { ProjectSavingsAnalysisData } from '~/types/project/ProjectSavingsAnalysis';

const calculateNetSavingsPercentageRange = (
  project: Project,
  currentPricing: number, 
  savingsKwh: ProjectSavingsAnalysisData | undefined,
): string => {
  
  const hasResult = Boolean(currentPricing && project?.autopricingResult && Object.keys(project?.autopricingResult).length > 0);
  const formatPercentage = (kwh: number) => {
    const percentage = formatNumber(kwh, UnitDecimals.NET_SAVINGS_PERCENT, {
      default0: '—',
      suffix: '%',
    });
    return percentage;
  };
  
  const lowPercent = savingsKwh && formatPercentage(savingsKwh.high);
  const highPercent = savingsKwh && formatPercentage(savingsKwh.low);

  return hasResult
    ? `${lowPercent} - ${highPercent}` 
    : '—';
};

export default function netSavingsPercentageRange(project: Project) {
  const { ppaPricing, netSavingsRange } = project?.savingsAnalysis || {};
  return {
    netSavingsRange: calculateNetSavingsPercentageRange(project, ppaPricing as number, netSavingsRange)
  };
}