import styled from 'styled-components';
import { useProjectContext } from '~/hooks';
import WorksheetForm from '../../../../worksheets/common/components/WorksheetForm';
import ProductionValidationInfo from './ProductionValidationInfo';
import ProductionValidationOptions from './ProductionValidationOptions';
import ProductionValidationTable from './ProductionValidationTable';
import ProductionValidationEstimateButton from './ProductionValidationEstimateButton';

const ProductionValidationContainer = styled.div`
  > * + * {
    margin-top: var(--medium);
  }
`;

export default function ProductionValidation() {
  const { project, projectDisplayValues } = useProjectContext();

  return ( 
    <ProductionValidationContainer>
      <ProductionValidationInfo />
      <WorksheetForm>
        <ProductionValidationOptions />
        <ProductionValidationTable project={project} />
        <ProductionValidationEstimateButton project={project} values={projectDisplayValues} />
      </WorksheetForm>
    </ProductionValidationContainer>
  );
}