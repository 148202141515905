import { useProjectContext } from '~/hooks';
import { WorksheetField } from '../common/components/Field';
import { 
  ContractTypeDropdownOptions, 
  ContractType, 
  UnitDecimals,
  PricingType
} from '~/constants';
import { InputType } from '~/schema';

export default function PricingEstimateContractType() {
  const { project } = useProjectContext();

  if (project?.pricingType === PricingType.ACQUISITION) {
    return (
      <>
        <WorksheetField
          type="radio"
          schemaKey="contractType"
          fieldLabel="Contract Type"
          values={ContractTypeDropdownOptions}
          inline
          stretched
        />
        <WorksheetField.Row isQuote>
          <div className='labelled-input'>
            <WorksheetField
              type="number"
              fieldLabel="Contract term"
              schemaKey="contractTerm"
              placeholder="Enter contract term"
              label="years"
              decimals={0}
            />
          </div>
          <div className='labelled-input'>
            <WorksheetField
              type="number"
              fieldLabel="Offtake rate"
              placeholder="Enter rate"
              schemaKey="contractRate"
              label={project.contractType === ContractType.PPA ? 'per kWh' : 'per year'}
              numberType={InputType.DOLLARS}
              decimals={project.contractType === ContractType.PPA ? UnitDecimals.KWH : UnitDecimals.YEAR}
            />
          </div>
        </WorksheetField.Row>
        <WorksheetField
          type="number"
          fieldLabel="Annual contract escalator"
          placeholder="Enter contract escalator"
          schemaKey="contractEscalator"
          numberType={InputType.PERCENT}
          decimals={UnitDecimals.PERCENT}
        />
      </>
    );
  }; 

  if (project?.pricingType === PricingType.OFFTAKE) {
    return (
      <>
        <WorksheetField.Row isQuote>
          <div className='labelled-input'>
            <WorksheetField
              type="number"
              fieldLabel="Contract term"
              schemaKey="contractTerm"
              placeholder="Enter contract term"
              label="years"
              decimals={0}
            />
          </div>
          <WorksheetField
            type="number"
            fieldLabel="Annual contract escalator"
            placeholder="Enter contract escalator"
            schemaKey="contractEscalator"
            numberType={InputType.PERCENT}
            decimals={UnitDecimals.PERCENT}
          />
        </WorksheetField.Row>
      </>
    );
  }
  
  return null;
};