import * as S from './styles';
import { useProjectContext } from '~/hooks';
import { WorksheetField } from '../common/components/Field';
import { STATES } from '~/utils';
import { CreatePricingEstimateDropdownOptions, PricingType } from '~/constants';
import EpcCosts from '../../components/Worksheet/Budget/BudgetItems/EpcCosts';
import PricingEstimateArray from './PricingEstimateArray';
import PricingEstimateYearOneSavings from './PricingEstimateYearOneSavings';
import PricingEstimateContractType from './PricingEstimateContractType';
import { useDerivedRECFields } from '../../derived';

export default function PricingEstimateFields() {
  const { project } = useProjectContext();
  useDerivedRECFields();
  
  return (
    <S.FieldsContainer>
      <WorksheetField.Row>
        <WorksheetField 
          type='dropdown'
          schemaKey='addressState' 
          fieldLabel='State'
          placeholder='Enter value'
          search
          options={STATES}
        />
        <WorksheetField 
          type='dropdown'
          schemaKey='pricingType' 
          fieldLabel='Solve for'
          placeholder='Enter value'
          options={CreatePricingEstimateDropdownOptions}
        />
      </WorksheetField.Row>
      <PricingEstimateArray />
      <WorksheetField.Row isQuote>
        <PricingEstimateYearOneSavings />
        {project?.pricingType === PricingType.OFFTAKE && <EpcCosts />}
      </WorksheetField.Row>
      <PricingEstimateContractType />
    </S.FieldsContainer>
  );
}