import styled from 'styled-components';
import { Header } from 'semantic-ui-react';
import { PageHeaderContextValue } from '~/components/PageHeader/PageHeaderProvider';

const PAGE_PADDING_TOP = '1.25rem';
const PAGE_PADDING_BOTTOM = 'var(--x-large)';

export const Container = styled.div<{ pageHeaderContext: PageHeaderContextValue }>`
  padding-top: ${PAGE_PADDING_TOP};
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: ${PAGE_PADDING_TOP};
  .dataroom-sidebar {
    > * {
      transition: 0.2s ease-in-out;
      height: unset;
      min-height: calc(
        100vh 
        - 1.25rem 
        - ${PAGE_PADDING_BOTTOM} 
        - ${props => `${props.pageHeaderContext.height / 16}rem`}
      );
    }
    width: 16.5rem;
    position: sticky;
    top: calc(${PAGE_PADDING_TOP} + ${props => `${props.pageHeaderContext.height / 16}rem`});
    height: 100%;
    transition: top 0.2s ease-in-out;
    max-height: calc(100vh - ${PAGE_PADDING_TOP} - ${props => `${props.pageHeaderContext.height / 16}rem`});
    overflow-y: hidden;
    overflow-x: hidden;
    flex-shrink: 0;
    overflow-y: auto;
  }

  .main-content {
    max-width: 47rem;
    width: 100%;
    padding-bottom: calc(${PAGE_PADDING_BOTTOM} + 1rem);
    margin-left: var(--medium);
    position: relative;
  }

`;

export const SimplifiedHeader = styled(Header)`
  margin-top: calc(var(--x-large) - 1.25rem);
  display: flex;
  justify-content: space-between;
`;