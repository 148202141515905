type RevenueSourceType = 'PPA_LEASE_ESA' | 'N_A' | 'COMMUNITY_SOLAR_SUBS' | 'MERCHANT_POWER_SALES';

export const RevenueSource = {
  PPA_LEASE_ESA: 'PPA_LEASE_ESA' as RevenueSourceType,
  N_A: 'N_A' as RevenueSourceType,
  COMMUNITY_SOLAR_SUBS: 'COMMUNITY_SOLAR_SUBS' as RevenueSourceType,
  MERCHANT_POWER_SALES: 'MERCHANT_POWER_SALES' as RevenueSourceType
};

export const RevenueSourceDisplayValues = {
  [RevenueSource.PPA_LEASE_ESA]: 'PPA / Lease / Energy services agreement',
  [RevenueSource.N_A]: 'N/A — The power user will also own the system',
  [RevenueSource.COMMUNITY_SOLAR_SUBS]: 'Community solar subscriptions',
  // [RevenueSource.MERCHANT_POWER_SALES]: 'Merchant power sales'
};