export const ProjectArrays = {
  ROOFTOP: 'ROOFTOP',
  GROUNDMOUNT_FIXED_TILT: 'GROUNDMOUNT_FIXED_TILT',
  GROUNDMOUNT_SINGLE_AXIS: 'GROUNDMOUNT_SINGLE_AXIS',
  CARPORT_CANOPY: 'CARPORT_CANOPY'
};

export const ProjectArraysDisplay = {
  [ProjectArrays.ROOFTOP]: 'Rooftop',
  [ProjectArrays.GROUNDMOUNT_FIXED_TILT]: 'Ground mount, fixed',
  [ProjectArrays.GROUNDMOUNT_SINGLE_AXIS]: 'Ground mount, single-axis trackers',
  [ProjectArrays.CARPORT_CANOPY]: 'Carport / Canopy'
};

// for any shortened display of the project arrays in the app
export const ProjectArraysShortDisplay = {
  [ProjectArrays.ROOFTOP]: 'Rooftop',
  [ProjectArrays.GROUNDMOUNT_FIXED_TILT]: 'Ground - fixed',
  [ProjectArrays.GROUNDMOUNT_SINGLE_AXIS]: 'Ground - tracking',
  [ProjectArrays.CARPORT_CANOPY]: 'Carport / canopy'
};

export const ProjectArrayDropdownOptions = [
  { key: ProjectArrays.ROOFTOP, text: ProjectArraysDisplay[ProjectArrays.ROOFTOP], value: ProjectArrays.ROOFTOP },
  { key: ProjectArrays.GROUNDMOUNT_FIXED_TILT, text: ProjectArraysDisplay[ProjectArrays.GROUNDMOUNT_FIXED_TILT], value: ProjectArrays.GROUNDMOUNT_FIXED_TILT },
  { key: ProjectArrays.GROUNDMOUNT_SINGLE_AXIS, text: ProjectArraysDisplay[ProjectArrays.GROUNDMOUNT_SINGLE_AXIS], value: ProjectArrays.GROUNDMOUNT_SINGLE_AXIS },
  { key: ProjectArrays.CARPORT_CANOPY, text: ProjectArraysDisplay[ProjectArrays.CARPORT_CANOPY], value: ProjectArrays.CARPORT_CANOPY }
];