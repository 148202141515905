import { WorksheetField } from '../common/components/Field';
import { PropertyTaxTypeRadioOptions } from '~/constants';
import { InputType } from '~/schema';

export default function PricingEstimatePropertyTax () {
  return (
    <>
      <WorksheetField
        type="radio"
        schemaKey="propertyTaxType"
        fieldLabel="Property taxes"
        helpText="Please note that some states have blanket exemptions; others have exemptions at certain size thresholds."
        values={PropertyTaxTypeRadioOptions}
        inline
        stretched
      />
      <WorksheetField
        type="number"
        schemaKey="propertyTaxAmount"
        numberType={InputType.DOLLARS}
      />
      <WorksheetField
        type="number"
        schemaKey="propertyTaxEscalator"
        numberType={InputType.PERCENT}
        decimals={2}
        width="half"
      />
    </>
  );
}