import { useToggleArchiveMutation } from '~/mutations/archive';
import { useProjectContext } from '~/hooks';
import { usePermissions } from '~/requests/permissions/usePermissions';
import Button from './Button';

export default function ArchiveButton() {
  const { permissions } = usePermissions();
  const { project } = useProjectContext();

  const action = project?.isArchived ? 'unarchive' : 'archive' as 'archive' | 'unarchive';

  const { mutate: toggleArchive, status: toggleArchiveStatus } = useToggleArchiveMutation(action);

  return (
    permissions.hasArchiveActions ? (
      <Button 
        primary 
        outlined
        onClick={() => toggleArchive()}
        loading={toggleArchiveStatus === 'loading'}
        disabled={toggleArchiveStatus === 'loading'}
      >
        {action === 'archive' ? 'Archive' : 'Unarchive'}
      </Button>
    ) : null
  );
};  