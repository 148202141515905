import { RecAllocationRadioOptions, UnitDecimals } from '~/constants';
import { WorksheetField } from '~/features/projectWizard/worksheets/common/components/Field';
import { useProjectContext } from '~/hooks';
import { InputType } from '~/schema';

export default function NewJerseyRECForm() {
  const { project, projectState } = useProjectContext();
  const schemaKey = (term: string) => `recsData.${project.addressState}.${term}`;

  return (
    <>
      <WorksheetField
        type="radio"
        fieldLabel="Have you secured a REC allocation?"
        schemaKey={schemaKey('recAllocation')}
        values={RecAllocationRadioOptions}
      />
      <WorksheetField.Row>
        <WorksheetField
          type="number"
          fieldLabel="REC Value ($/MWh)"
          schemaKey={schemaKey('recValue')}
          numberType={InputType.DOLLARS}
          decimals={UnitDecimals.DOLLARS_PER_MWH}
        />
        {projectState?.isQuote ? (
          <div className='labelled-input'>
            <WorksheetField
              type="number"
              fieldLabel="Contract term"
              schemaKey={schemaKey('recContractTerm')}
              placeholder='Enter length of contract term'
              label='years'
            />
          </div>
        ) : (
          <WorksheetField
            type="number"
            fieldLabel="Contract term"
            schemaKey={schemaKey('recContractTerm')}
            placeholder='Enter length of contract term'
            label='years'
          />
        )}
      </WorksheetField.Row>
    </>
  );
}