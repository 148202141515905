import * as S from './styles';
import { Modal } from '~/components';
import { Button } from '~/components';
import { 
  InvestorFeedbackBidStatus,
  InvestorFeedbackRating,
  InvestorFeedbackButtonText,
  InvestorFeedbackSelection
} from '~/constants/project-investor-feedback';
import BidFeedbackCheckbox from './BidFeedbackCheckbox';
import BidFeedbackNotes from './BidFeedbackNotes';
import { useProjectContext } from '~/hooks';
import { useForm } from '~/hooks/providers/FormContextProvider';
import { useHistory } from 'react-router-dom';
import { useSaveInvestorFeedback } from '~/mutations/project/useSaveInvestorFeedback';
import { useMutation } from 'react-query';
import { declineProjectBid } from '~/api/projectBids';
import { toast } from 'react-hot-toast';
import { Form } from 'semantic-ui-react';

interface BidFeedbackSelectionFormProps {
  onGoBack: () => void,
  closeModal: () => void,
  bidStatus: string | null,
}

interface BidFeedbackSelectionFormState {
  feedbackSelection: typeof InvestorFeedbackSelection[keyof typeof InvestorFeedbackSelection][];
  feedbackNotes: string;
  feedbackRating: typeof InvestorFeedbackRating[keyof typeof InvestorFeedbackRating];
}

export default function BidFeedbackSelectionForm({
  onGoBack,
  closeModal,
  bidStatus, 
}: BidFeedbackSelectionFormProps) {
  const { formState } = useForm<BidFeedbackSelectionFormState>();
  const { project, id } = useProjectContext();
  const history = useHistory(); 
  const investorId = project?.investorId;
  const portfolioId = project?.portfolioId;

  const { mutate: saveFeedback, status: saveFeedbackStatus } = useSaveInvestorFeedback();
  const { mutateAsync: executeDecline, status: declineStatus } = useMutation(() => declineProjectBid(id, { ...formState, investorId }));
  
  const handleBidDecline = async () => {
    try {
      const { isFinalProject } = await executeDecline();

      toast.success('Thank you for sharing your feedback.', { duration: 5000 });

      if (portfolioId && !isFinalProject) {
        history.push(`/portfolio/${portfolioId}`);
      } else {
        history.push('/dashboard');
      }

      closeModal();
    } catch (error) {
      console.error(error);
      toast.error(
        <div>
          <p>
            We ran into an error while attempting to decline your bid. Please try again, and if you continue to run into issues, contact{' '}
            <a href="mailto:support@conductor.solar">support@conductor.solar</a>.
          </p>
        </div>,
        { duration: 5000 }
      );
    }
  };
    
  return (
    <>
      <Modal.Title>Capturing project feedback</Modal.Title>
      <Modal.Content>
        {bidStatus === InvestorFeedbackBidStatus.BID_DECLINED && (
          <p style={{ margin: '0 0 var(--medium) 0'}}>
            We’d like to hear what you think about this project and why you weren’t interested in bidding. Please complete the form below, and we’ll review your answers to make sure we’re matching you with opportunities that fit your needs. 
          </p>
        )}
        <Form>
          <S.FeedbackContainer>
            <h3>What makes this a less-than great fit?</h3>
            <p>Select all that apply.</p>
            <BidFeedbackCheckbox  />
            <BidFeedbackNotes />
          </S.FeedbackContainer>
        </Form>
      </Modal.Content>
      <Modal.Footer style={{ justifyContent: bidStatus !== InvestorFeedbackBidStatus.BID_DECLINED ? 'space-between' : 'flex-end'}}>
        {bidStatus === InvestorFeedbackBidStatus.BID_DECLINED 
          ? <Button primary outlined onClick={() => closeModal()}>Cancel</Button>
          : <Button primary outlined onClick={() => onGoBack()}>Go back</Button>
        }
        <Button
          primary
          onClick={() => {
           if (formState.feedbackRating === InvestorFeedbackRating.DECLINED_TO_BID) {
              handleBidDecline();
            } else if (formState.feedbackRating) {
              saveFeedback(formState, { onSuccess: closeModal });
            }
          }}
          loading={saveFeedbackStatus === 'loading' || declineStatus === 'loading'}
          disabled={
            (!formState.feedbackSelection?.length && !formState.feedbackNotes) 
              || (formState.feedbackSelection.includes(InvestorFeedbackSelection.OTHER) && !formState.feedbackNotes)
              || saveFeedbackStatus === 'loading' 
              || declineStatus === 'loading'
            }
          >
            {bidStatus === InvestorFeedbackBidStatus.BID_DECLINED ? InvestorFeedbackButtonText.DECLINE_AND_SUBMIT : InvestorFeedbackButtonText.SUBMIT_FEEDBACK}
        </Button>
      </Modal.Footer>
    </>
  );
}