import { Button } from '~/components';
import { Link } from 'react-router-dom';
import { usePromiseWatcher, useForm } from '~/hooks';
import { sendPasswordResetEmail } from '~/api/auth';
import { FormSchemaAwareWidget } from '~/components/form';
import { FormContextProvider } from '~/hooks/providers/FormContextProvider';
import AuthForm  from '../components/AuthForm';

interface ForgotPasswordFormState {
  email: string;
}

const ForgotPasswordFormImpl = () => {
  const {formState} = useForm<ForgotPasswordFormState>();
  // Send the Password Reset email to the provided email address
  const {execute, status} = usePromiseWatcher(
    () => sendPasswordResetEmail(formState.email),
    'sending the password reset email',
    [formState],
    {toastOpts: { id: 'login', duration: 5000 }} as any
  );

  return (
    <>
      {status !== 'success' ? (
        <AuthForm header = 'Password Reset'>
          <FormSchemaAwareWidget
            schemaKey="email"
            fluid
            fieldLabel="E-mail Address"
            icon="envelope"
            iconPosition="left"
            placeholder="E-mail address"
            autoComplete="username"
          />
          <Button 
            fluid 
            secondary
            size="large" 
            disabled={formState.email.length === 0 || status ==='pending'}
            loading={status === 'pending' || status === 'success'} 
            onClick={execute}
          >
            Send Reset Email
          </Button>
        </AuthForm>
       
      ) : (

      <AuthForm header = {`Your password reset e-mail has been sent to: ${formState.email}`}>
        <Button 
          as={Link} 
          to="/login"
          fluid 
          secondary
          size="large"
        >
          Return to Login Page
        </Button> 
      </AuthForm>
        )}
    </>
  );
};

const ForgotPasswordForm = () => {
  const defaults: ForgotPasswordFormState = {
    email: ''
  };
  
  return (
    <FormContextProvider defaults={defaults}>
      <ForgotPasswordFormImpl/>
    </FormContextProvider>
  );
};

export default ForgotPasswordForm;
