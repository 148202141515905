import { Button } from '~/components';
import { usePromiseWatcher, useForm } from '~/hooks';
import { updateUserPassword } from '~/api/user';
import { FormSchemaAwareWidget } from '~/components/form';
import { FormContextProvider } from '~/hooks/providers/FormContextProvider';
import { Form, Message } from 'semantic-ui-react';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import Modal from '../../../components/Modal/index';

interface ChangePasswordFormState {
  email?: string;
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

const ChangePasswordModalImpl = () => {
  const { closeModal } = useModalContext();
  const { formState } = useForm<ChangePasswordFormState>();
  const {execute: executeUpdatePassword, status: updatePasswordStatus} = usePromiseWatcher(
    () => updateUserPassword(formState).then(() => closeModal()),
    {
      messageStub: 'setting your new password',
      loading: null
    },
    [formState]
  );

  return (
    <Modal>
      <Modal.Title>
        Change my password
      </Modal.Title>
        <Modal.Content>
            <Form
              error={
                (!!formState.newPasswordConfirm && formState.newPassword !== formState.newPasswordConfirm) ||
                (!!formState.newPasswordConfirm && formState.newPasswordConfirm.length < 8)
              }
            >
              <Message
                error
                header="A problem has occurred."
                content={
                  formState.newPassword.length >= 8
                    ? 'The new passwords you entered do not match.'
                    : 'The new password must be more than 8 characters.'
                }
              />
              <Form.Field hidden>
                <Form.Input autoComplete="username" type="text" value={formState.email}/>
              </Form.Field>
              <FormSchemaAwareWidget
                schemaKey="oldPassword"
                autoComplete={false}
                type="password"
                fieldLabel='Old password'
              />
              <FormSchemaAwareWidget
                schemaKey="newPassword"
                autoComplete="new-password"
                type="password"
                fieldLabel='New password'
              />
              <FormSchemaAwareWidget
                schemaKey="newPasswordConfirm"
                autoComplete="new-password"
                type="password"
                fieldLabel='Re-enter new password'
              />
              <Modal.Footer>
                <Button
                  primary
                  onClick={() => {
                    if (formState.newPassword === formState.newPasswordConfirm) {
                      executeUpdatePassword();
                    }
                  }}
                  disabled={
                    (!!formState.newPasswordConfirm && formState.newPassword !== formState.newPasswordConfirm) ||
                    (!!formState.newPasswordConfirm && formState.newPasswordConfirm.length < 8) ||
                    (formState.oldPassword.length === 0 || formState.newPassword.length === 0 || formState.newPasswordConfirm.length === 0) ||
                    (updatePasswordStatus === 'pending')
                  }
                  loading={updatePasswordStatus === 'pending' || updatePasswordStatus === 'success'} 
                >
                  Save changes
                </Button>
                <Button
                  primary
                  outlined
                  onClick={() => closeModal()}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Form>
        </Modal.Content>
    </Modal>
  );
};

const ChangePasswordModal = () => {
  const defaults: ChangePasswordFormState = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: ''
  };
  
  return (
    <FormContextProvider defaults={defaults}>
      <ChangePasswordModalImpl/>
    </FormContextProvider>
  );
};

export default ChangePasswordModal;
