import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { updateProjectCustomerProposal } from '~/api/admin';

interface UpdateProposalRequestParams {
  id: string;
  messageId: string | undefined; 
}

export const useUpdateProposalRequest = (params = {}) => {
  return useMutation({
    mutationFn: async ({ id, messageId }: UpdateProposalRequestParams) => {
      if (id && messageId) {
        return updateProjectCustomerProposal(id, messageId);
      }
    },
    onError: (err) => {
      console.error('An error occurred while updating the proposal.', err);
      toast.error('Error updating proposal.', { duration: 5000 });
    },
    ...params
  });
};