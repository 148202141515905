import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { withForm } from '~/utils/withForm';
import { Button } from '~/components';
import { Form, TextArea } from 'semantic-ui-react';
import { createPortfolio } from '~/api/portfolios';
import { AddProjectsDropdown, getAvailableProjectOptions } from '~/components';
import Modal from '~/components/Modal';
import { FormSchemaAwareWidget } from '~/components/form';
import { useForm } from '~/hooks/providers/FormContextProvider';
import { useProjectList } from '~/requests/projects/useProjectList';
interface CreatePortfolioFormState {
  name: string;
  description: string;
  projectIds?: string[];
}

const defaults: CreatePortfolioFormState = { name: '', description: '' };

const CreatePortfolioModal = withForm({ defaults }, (
  { closeModal }: { closeModal: () => void }
) => {
  // Call hooks and parent contexts
  const { formState } = useForm<CreatePortfolioFormState>();
  const history = useHistory();

  const { mutateAsync: createNewPortfolio, status: createNewPortfolioStatus } = useMutation(async () => await createPortfolio(formState));

  // Form is not valid if the name or description is empty
  const hasError = !formState?.name || !formState?.description || !formState?.projectIds?.length;

  // Handle the form submission
  const handleSubmit = async () => {
    if (!hasError) {
      try {
        const portfolio = await createNewPortfolio();
        toast.success('Portfolio created successfully', { duration: 5000 });
        history.push(`/portfolio/${portfolio.id}`);
        closeModal();
      } catch (err) {
        console.error('Error creating portfolio:', err);
        toast.error('Error creating the portfolio', { duration: 5000 });
      }
    };
  };

  // Get the list of projects that can be added to the portfolio
  const { data: projects } = useProjectList();
  const projectOptions = useMemo(() => getAvailableProjectOptions(projects), [projects]);

  // If there are no projects, show a message explaining that you need at least two projects to createNewPortfolio a portfolio
  if (!projectOptions.length || projectOptions.length < 2) {
    return (
      <Modal closeModal={createNewPortfolioStatus !== 'loading' ? closeModal : undefined}>
        <Modal.Title>
          Create new portfolio
        </Modal.Title>
        <Modal.Content>
          <p>
            Portfolios are a great way to link together related projects so that they can be sent as a group to a single investor.
          </p>
          <p>
            <strong>In order to create a portfolio, you need to have at least two active projects.</strong> Please return to the dashboard to create a new project.
          </p>
        </Modal.Content>
        <Modal.Footer>
          <Button primary onClick={closeModal}>Go back</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  // Otherwise, show the form
  return (
    <Modal>
      <Modal.Title>
        Create new portfolio
      </Modal.Title>
      <Modal.Content>
        <p>
          Portfolios are a great way to link together related projects so that they can be sent as a group to a single investor.
        </p>
        <p>
          To get started, enter a portfolio name, some details on how the constituent projects will be linked together, and at least two active projects.
        </p>
        <Form onSubmit={handleSubmit}>
          <FormSchemaAwareWidget
            fieldLabel="Portfolio name"
            placeholder="Enter value"
            schemaKey="name"
          />
          {/* @ts-ignore */}
          <AddProjectsDropdown projectOptions={projectOptions} />
          <FormSchemaAwareWidget
            as={TextArea}
            fieldLabel="Portfolio description"
            placeholder="Please use this space to describe how the projects relate to each other and provide high-level details about the portfolio."
            schemaKey="description"
          />
        </Form>
      </Modal.Content>
      <Modal.Footer>
        <Button primary outlined onClick={closeModal}>Cancel</Button>
        <Button
          primary
          disabled={hasError || createNewPortfolioStatus === 'loading'}
          loading={createNewPortfolioStatus === 'loading'}
          onClick={handleSubmit}
        >
          Create portfolio
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default CreatePortfolioModal;