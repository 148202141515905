import { Comment, Divider, Placeholder } from 'semantic-ui-react';
import LabelEvent from './LabelEvent';
import CommentEvent from './CommentEvent';
import { useMessagingContext } from '../providers/MessagingProvider';
import { useUserContext } from '~/hooks';
import CommentReplyForm from './CommentReplyForm';
import * as S from '../styles';
import { ProjectEvent } from '~/types/projectEvent';
import { CommentMessage, FileMessage } from '~/types/conversations/Message';

export default function MessagingHistory() {
  const { isLoading, newCommentReplies, messageList } = useMessagingContext();
  const { loggedInUser } = useUserContext();

  return (
    <S.Container>
      <Comment.Group style={{ 
        maxWidth: '46.4375rem', 
        width: '100%'
      }}>
        {isLoading && (
          <>
            <Placeholder>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
            <Placeholder>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
            <Placeholder>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          </>
        )}
        {!isLoading && !!messageList?.length && messageList?.map((row) => (
          <>
            <Comment key={row?.id} className={row?.user?.id === loggedInUser?.id && row?.type === 'COMMENT' ? 'is-own' : ''}>
              {row?.type === 'COMMENT'
                ? <CommentEvent message={row as CommentMessage} />
                : <LabelEvent event={row as ProjectEvent | FileMessage} />
              }
            </Comment>

            {row?.type === 'COMMENT' && (row?.replies?.length || newCommentReplies?.[row?.id]) ? (
              <S.CommentReplyContainer>
                {row?.replies?.map((reply: any) => (
                  <Comment key={reply?.id} className={reply?.user?.id === loggedInUser?.id ? 'is-own' : ''}>
                    <CommentEvent message={reply as CommentMessage} />
                  </Comment>
                ))}
                {newCommentReplies?.[row?.id] && <CommentReplyForm message={row as CommentMessage} />}
              </S.CommentReplyContainer>
            ) : null}

          </>
        ))}
        {!isLoading && !messageList?.length && (
          <Divider 
            horizontal
            style={{
              textTransform: 'none',
              margin: 'var(--medium) 0',
              fontSize: '0.75rem',
              fontWeight: 'bold',
              letterSpacing: 'normal'
            }}
          >
            No activity
          </Divider> 
        )}
      </Comment.Group>
    </S.Container>
  );
}