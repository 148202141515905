import WorksheetForm from '~/features/projectWizard/worksheets/common/components/WorksheetForm';
import BudgetSummary from './BudgetSummary';
import EpcCosts from './EpcCosts';
import BudgetCosts from './BudgetCosts';
import EpcCostsText from './EpcCostsText';


export default function BudgetItems() {
  return (
    <WorksheetForm>
      <BudgetSummary />
      <EpcCosts /> 
      <EpcCostsText />      
      <BudgetCosts />
    </WorksheetForm>
  );
};