import WorksheetForm from '~/features/projectWizard/worksheets/common/components/WorksheetForm';
import ItcAddersForm from './ItcAddersForm';
import ExpectedITCPercentage from './ExpectedITCPercentage';
import UpfrontIncentives from './UpfrontIncentives';

export default function SourceOfFunds () {
  return (
    <WorksheetForm>
      <ItcAddersForm />
      <ExpectedITCPercentage />
      <UpfrontIncentives />
    </WorksheetForm>
  );
}