import * as S from './styles';
import { ReactNode } from 'react';
import { CommentMessage } from '~/types/conversations/Message';
import { useToggleMessageResponseFlag } from '~/mutations/conversations/useToggleMessageResponseFlag';
import { usePageType, usePortfolioContext, useProjectContext, useUserContext } from '~/hooks';
import sendProjectClickEvent from '~/utils/analytics/sendProjectClickEvent';
import sendPortfolioClickEvent from '~/utils/analytics/sendPortfolioClickEvent';

interface CommentResponseButtonProps {
  isDiv?: boolean;
  children?: ReactNode;
  message?: CommentMessage;
}  

export default function CommentResponseButton({
  isDiv,
  children,
  message,
}: CommentResponseButtonProps) {
  const { mutate: toggleFlag, status: toggleFlagStatus } = useToggleMessageResponseFlag();
  const pageType = usePageType();
  const { loggedInUser } = useUserContext();
  const { project } = useProjectContext();
  const { portfolio } = usePortfolioContext();
  
  if (!isDiv && !message) {
    return null;
  }

  if (isDiv) {
    return (
      <S.CommentResponseButton as="div" data-testId='comment-response'>
        {children}
      </S.CommentResponseButton>
    );
  } 
  
  if (message) {
    return (
      <S.CommentResponseButton 
        onClick={() => {
          toggleFlag(message);
          // Analytics 
          if (!loggedInUser?.isAdmin && !message.requiresResponse){
            // Only fires when changing to response required
            if (pageType === 'project'){
              sendProjectClickEvent('Response Requested', project?.name, project?.id);
            } else if (pageType === 'portfolio'){
              sendPortfolioClickEvent('Response Requested', portfolio?.name, portfolio?.id);
            }
          }
        }} 
        disabled={toggleFlagStatus === 'loading'}
      > 
        {children}
      </S.CommentResponseButton>
    );
  }
}