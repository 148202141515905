import { useState } from 'react';
import { Modal } from '~/components';
import { useForm } from '~/hooks';
import { InvestorFeedbackBidStatus, InvestorFeedbackRating, InvestorFeedbackSelection } from '~/constants/project-investor-feedback';
import BidFeedbackRatingForm from './BidFeedbackRatingForm';
import BidFeedbackSelectionForm from './BidFeedbackSelectionForm';
import { FormContextProvider } from '~/hooks/providers/FormContextProvider';

interface InvestorFeedbackProps {
  bidStatus: string | null;
  closeModal: () => void;
}

interface InvestorFeedbackState {
  feedbackRating: typeof InvestorFeedbackRating[keyof typeof InvestorFeedbackRating] | '';
  feedbackSelection: typeof InvestorFeedbackSelection[keyof typeof InvestorFeedbackSelection][];
  feedbackNotes: string;
}

const InvestorFeedbackModal = ({ 
  bidStatus,  
  closeModal 
}: InvestorFeedbackProps) => { 
  const { formState } = useForm<InvestorFeedbackState>();
  const [currentForm, setCurrentForm] = useState(formState.feedbackRating ? 'SELECTION_FORM' : 'RATING_FORM');

  return (
    <Modal closeModal={closeModal}>
      {currentForm === 'RATING_FORM' && (
        <BidFeedbackRatingForm
          onSubmit={() => setCurrentForm('SELECTION_FORM')}
          closeModal={closeModal}
          bidStatus={bidStatus}
        />
      )}
      
      {currentForm === 'SELECTION_FORM' && (
        <BidFeedbackSelectionForm
          onGoBack={() => setCurrentForm('RATING_FORM')}
          closeModal={closeModal}
          bidStatus={bidStatus}
        />
      )}
    </Modal>
  );
};

export default function (props: InvestorFeedbackProps) {
  const defaults: InvestorFeedbackState = {
    feedbackRating: props.bidStatus === InvestorFeedbackBidStatus.BID_DECLINED ? InvestorFeedbackRating.DECLINED_TO_BID : '',
    feedbackSelection: [],
    feedbackNotes: ''
  };

  return (
    <FormContextProvider defaults={defaults}>
      <InvestorFeedbackModal {...props} />
    </FormContextProvider>
  );
}