import { RecAllocationRadioOptions, RecProgramYearRadioOptions, RecProjectCategoryRadioOptions, RecShinesProgramRadioOptions, RecUtilityGroupRadioOptions } from '~/constants';
import { WorksheetField } from '~/features/projectWizard/worksheets/common/components/Field';
import { useProjectContext } from '~/hooks';
import { DisplayRECValue } from './DisplayRECValue';
import { useDerivedRECFields } from '~/features/projectWizard/derived';

export default function IllinoisRECForm() {
  const { project, projectState } = useProjectContext();
  const schemaKey = (term: string) => `recsData.${project.addressState}.${term}`;
  useDerivedRECFields();

  return (
    <>
      <WorksheetField
        type="radio"
        fieldLabel="Is this part of the IL SHINES program?"
        schemaKey={schemaKey('isShinesProgram')}
        inline
        values={RecShinesProgramRadioOptions}
      />
      {projectState?.isQuote && project?.recsData?.Illinois?.isShinesProgram === 'YES' && (
        <div className="labelled-input">
          <WorksheetField
            type="number"
            schemaKey={'projectArrays.0.sizeKwac'}
            fieldLabel="Array size in kWac"
            placeholder="Enter size"
            label="kWac"
            optional={project.addressState !== 'Illinois'}
          />
        </div>
      )}
      {project?.recsData?.Illinois?.isShinesProgram === 'YES' && (
        <>
        <WorksheetField
          type="radio"
          fieldLabel="Have you secured a REC allocation?"
          schemaKey={schemaKey('recAllocation')}
          values={RecAllocationRadioOptions}
        />
        <WorksheetField
          type="radio"
          fieldLabel="Project category"
          schemaKey={schemaKey('recProjectCategory')}
          helpText='How is this project classified within the IL SHINES program?'
          values={RecProjectCategoryRadioOptions}
        />
        <WorksheetField
          type="radio"
          fieldLabel="Utility group"
          schemaKey={schemaKey('recUtilityGroup')}
          inline
          values={RecUtilityGroupRadioOptions}
        />
        <WorksheetField
          type="radio"
          fieldLabel="Program year"
          schemaKey={schemaKey('recProgramYear')}
          inline
          stretched
          values={RecProgramYearRadioOptions}
        />
        <DisplayRECValue />
      </>
      )}
    </>
  );
}