import styled, { css } from 'styled-components';

const textStyles = css`
  color: var(--color-charcoal);
  font-weight: 600; 
  font-size: 0.875rem; 
  font-style: italic;
  letter-spacing: 0.07px;
  line-height: 1.25rem;
`;

export const OwnershipPickerContainer = styled.div`
  display: flex;
  gap: var(--small);
  align-items: center;
`;

export const OwnershipText = styled.span`
  ${textStyles}
  margin-right: var(--2x-small);
`;

export const OwnershipDropdownButton = styled.button`
  all: unset;
  text-decoration: underline;
  cursor: pointer;

 ${textStyles}
`;