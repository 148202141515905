import { autopricing, pollAsyncRequestResult } from '~/api/projects';
import { useProjectContext } from '~/hooks';
import { useAutoSaveContext } from '~/hooks';
import toast from 'react-hot-toast';
import AsyncRequestButton from '~/components/AsyncRequestButton';
import useArchiveToastCheck from '~/hooks/archive/useArchiveToastCheck';

export default function PricingEstimateQuoteButton() {
  const { project, projectErrors: errors, projectAutopricing } = useProjectContext();
  const { fetchFreshData } = useAutoSaveContext() as any;
  const projectArrayKeys = Object.keys(errors).filter((key) => key.includes('projectArrays')); 
  const hasProjectArrayErrors = Boolean(projectArrayKeys.length); 
  const hasErrors = errors.addressState || errors.epcCost || errors.contractRate || hasProjectArrayErrors;

  const handleClick = useArchiveToastCheck(async () => {
    try {
      return await autopricing(project.id);
    } catch (err) {
      toast.error('Error initializing pricing estimate request', { duration: 5000 });
      throw err;
    }
  });

  return (
    <div>
      <AsyncRequestButton
        apiEvent={project?.apiEvents?.autopricing}
        onClick={handleClick}
        disabled={hasErrors}
        onSuccess={() => {
          fetchFreshData().catch((err: any) => {
            console.error('Error fetching fresh data after making pricing estimate request', err);
            toast.error('An unexpected error occurred while fetching pricing estimate data after finishing the pricing estimate request. Please refresh the page.', { duration: 10000 });
          });
        }}
        onError={(errorObj: any) => {
          if (errorObj?.response?.data?.outOfBounds) {
            toast.error((
              <div>
                <p>
                  Conductor Solar was unable to find a workable price range for your pricing estimate. Please review your pricing estimate details,
                  paying specific attention to the pricing estimate size and overall costs.
                </p>
                <p>
                  If you believe this is in error, please contact{' '}
                  <a href="mailto:support@conductor.solar">support@conductor.solar</a> for assistance.
                </p>
              </div>
            ), {
              duration: 20000
            });
          } else if (errorObj?.response?.data?.errorInIRR) {
            toast.error((
              <div>
                <p>
                  Conductor Solar is having difficulty generating a price, and this is likely due to choosing the wrong unit selector for one of your inputs (e.g., year 1 production).
                </p>
                <p>
                  After you&apos;ve confirmed your inputs, please contact{' '}
                  <a href="mailto:support@conductor.solar">support@conductor.solar</a> for assistance.
                </p>
              </div>
            ), { 
              duration: 20000 
            });
          } else {
            toast.error('An error occurred fetching pricing estimate data. Please refresh the page and try again.');
            fetchFreshData();
          }
        }}
        onTimeout={() => toast.error('Pricing estimates are taking longer than expected. Please refresh the page to check for results.')}
        text={projectAutopricing?.conditions?.hasPricingEstimate ? 'Update pricing estimate' : 'Generate my pricing estimate'}
        loadingText={projectAutopricing?.conditions?.hasPricingEstimate ? 'Updating pricing estimate' : 'Generating pricing estimate'}
        pollFn={(apiEventId: string) => pollAsyncRequestResult(project.id, apiEventId)}
      />
    </div>
  );
}