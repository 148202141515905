import * as S from './styles';
import WorksheetForm from '../common/components/WorksheetForm';
import { WorksheetField } from '../common/components/Field';
import { RecOwnershipRadioOptions } from '~/constants';
import { InputType } from '~/schema';
import { useDerivedRECFields } from '../../derived';
import { useDerivedProjectSetupFields } from '../../derived';
import ItcAddersForm from '../../components/Worksheet/Budget/UpfrontIncentives/ItcAddersForm';
import PricingEstimateFields from './PricingEstimateFields';
import PricingEstimatePropertyTax from './PricingEstimatePropertyTax';
import PricingEstimateRevenueStream from './PricingEstimateRevenueStream';
import PricingEstimateOperatingExpenses from './PricingEstimateOperatingExpenses';
import StateSpecificRECForm from '../../components/Worksheet/Revenue/RenewableEnergyCredits/StateSpecificRECForm';
import { useProjectContext } from '~/hooks';

export default function PricingEstimateWorksheet() {
  const { project } = useProjectContext();

  useDerivedProjectSetupFields();
  useDerivedRECFields(); 

  return (
    <WorksheetForm isInner isQuote>
      <S.InputsContainer>
        <h2 style={{ marginBottom: 'var(--medium)' }}>Project inputs</h2>
        <PricingEstimateFields />
        <ItcAddersForm />
        <WorksheetField
          type="number"
          fieldLabel="Other upfront incentives"
          helpText='Please note that ITC value should not be added as an upfront incentive as it is accounted for elsewhere.'
          schemaKey="upfrontIncentives"
          numberType={InputType.DOLLARS}
        />
        <PricingEstimatePropertyTax />
        <h5>Additional revenues and expenses</h5>
        <PricingEstimateRevenueStream />
        <PricingEstimateOperatingExpenses />
        {project?.addressState === 'New Jersey' && (
          <WorksheetField
            type="radio"
            fieldLabel="Renewable energy certificates ownership"
            schemaKey="recOwnership"
            values={RecOwnershipRadioOptions}
          />
        )}
        <StateSpecificRECForm />
      </S.InputsContainer>
    </WorksheetForm>
  );
}