import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { withForm } from '~/utils/withForm';
import { Modal } from '~/components';
import { useForm } from '~/hooks';
import { useCreateProject } from '~/mutations/project/useCreateProject';
import { useDuplicateProject } from '~/mutations/project/useDuplicateProject';
import { CreateProjectTypes } from '~/constants';
import CreateNewProjectSelectionForm from './CreateNewProjectSelectionForm';
import CreatePricingEstimateForm from './CreatePricingEstimateForm';
import CreateProjectForm from './CreateProjectForm';
import DuplicateProjectForm from './DuplicateProjectForm';
import { Project } from '~/types/project';
import { DeploymentTypes } from '~/constants/deployment-type';

const ModalWrapper = styled.div`
  i.icon.close {
    background-color: red !important;
  }
`;

interface CreateNewProjectModalProps {
  closeModal: () => void;
  projectType?: string;
  portfolioId?: string;
  projectData?: Partial<Project>;
}

const defaults = {
  createProjectSelection: '',
  name: '',
  addressState: '',
  pricingType: ''
};

type FormTypes = 'PROJECT_SELECTION' | 'CREATE_PROJECT' | 'CREATE_PRICING_ESTIMATE' | 'DUPLICATE_PROJECT' | null;

const CreateNewProjectModal = withForm({ defaults }, ({
  closeModal,
  projectType,
  portfolioId,
  projectData = {}
}: CreateNewProjectModalProps) => {
  const { formState } = useForm() as any;
  const [currentForm, setCurrentForm] = useState<FormTypes>(projectData?.deploymentType === DeploymentTypes.CI ? 'PROJECT_SELECTION' : 'CREATE_PROJECT');
  const [isDuplicatingProject, setIsDuplicatingProject] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const { status: createProjectOrPricingEstimateStatus } = useCreateProject();
  const { status: duplicateProjectStatus } = useDuplicateProject();
  
  const formProps = {
    modalRef,
    portfolioId,
    closeModal,
    toggleFormType() {
      setIsDuplicatingProject((prev) => !prev);
      setCurrentForm((prev) => prev === 'DUPLICATE_PROJECT' ? 'CREATE_PROJECT' : 'DUPLICATE_PROJECT');
    },
  };

  useEffect(() => {
    if (isDuplicatingProject) {
      setCurrentForm('DUPLICATE_PROJECT');
    } else if (projectType === CreateProjectTypes.PROJECT) {
      setCurrentForm('CREATE_PROJECT');
    }
  }, [formState.createProjectSelection, isDuplicatingProject, projectType, currentForm]);

  const isLoading = createProjectOrPricingEstimateStatus === 'loading' || duplicateProjectStatus === 'loading';

  return (
    <ModalWrapper>
      <Modal 
        ref={currentForm === 'CREATE_PROJECT' ? modalRef : null} 
        closeModal={isLoading ? undefined : closeModal}
      >
        {(() => {
          switch (currentForm) {
            case 'PROJECT_SELECTION':
              return (
                <CreateNewProjectSelectionForm 
                  closeModal={closeModal}
                  onSubmit={() => setCurrentForm(
                    formState.createProjectSelection === CreateProjectTypes.MARKET_QUOTE
                      ? 'CREATE_PRICING_ESTIMATE'
                      : 'CREATE_PROJECT'
                  )}
                />
              );
            case 'CREATE_PRICING_ESTIMATE':
              return <CreatePricingEstimateForm projectData={projectData} closeModal={closeModal} />;
            case 'CREATE_PROJECT':
              return <CreateProjectForm {...formProps} projectData={projectData} />;
            case 'DUPLICATE_PROJECT':
              return <DuplicateProjectForm {...formProps} />;
            default:
              return null;
          }
        })()}
      </Modal>
    </ModalWrapper>
  );
});

export default CreateNewProjectModal;