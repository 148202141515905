import { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useConversationListQuery } from '~/requests/conversations/useConversationListQuery';
import { Conversation } from '~/types/conversations/Conversation';
import { useProjectContext } from '~/hooks';
import CustomerProposalModal from '~/modals/CustomerProposalModal';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { useDownloadMessageFileQuery } from '~/requests/conversations/useDownloadMessageFileQuery';
import useUploadCustomerProposal from '~/hooks/useUploadCustomerProposal';

interface DropdownItemProps {
  text?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export default function ProjectCustomerProposal() {
  const { project, id } = useProjectContext();
  const { data: conversations } = useConversationListQuery();
  const supportConversationId = conversations?.find((conversation: Conversation) => conversation.type === 'SUPPORT' && !!conversation.installerId)?.id as string;
  const { permissions } = usePermissions();
  const [modalOpen, setModalOpen] = useState(false);

  const { getInputProps, open, updateProposalRequestStatus } = useUploadCustomerProposal(id, supportConversationId);
  const { refetch: downloadProposalFile, status: downloadProposalFileStatus } = useDownloadMessageFileQuery({
    conversationId: supportConversationId,
    messageId: project?.customerProposal?.messageId as string
  });

  const dropdownItemProps: DropdownItemProps = (() => {
    if (!project?.customerProposal) {
      return {
        text: 'Request customer proposal',
        onClick: () => setModalOpen(true),
      };
    }

    if (project.customerProposal.requestedAt && !project.customerProposal.messageId) {
      return {
        text: 'Awaiting customer proposal',
        loading: updateProposalRequestStatus === 'loading',
        disabled: updateProposalRequestStatus === 'loading' || (permissions.hasProjectDeveloperAccess && !permissions.isAdmin),
        onClick: () => open(),
      };
    }

    if (project.customerProposal.uploadedAt && project.customerProposal.messageId) {
      return {
        text: 'View customer proposal',
        loading: downloadProposalFileStatus === 'loading',
        disabled: downloadProposalFileStatus === 'loading',
        onClick: () => downloadProposalFile(),
      };
    }

    return {};
  })();

  return (
    <>
      {project?.customerProposal?.requestedAt && !project?.customerProposal?.messageId && (
        <input {...getInputProps()} />
      )}
      <Dropdown.Item {...dropdownItemProps} />
      {modalOpen && <CustomerProposalModal closeModal={() => setModalOpen(false)} />}
    </>
  );
}