import * as S from './styles';
import DocumentTypeSidebar from './components/DocumentTypeSidebar';
import DocumentList from './components/DocumentList';
import MainContentContainer from './components/MainContentContainer';
import PageLoader from '~/components/PageLoader';
import AdditionalDocumentsUploadButton from './components/AdditionalDocumentsUploadButton';
import PortfolioProjectSwitcher from '~/components/PortfolioProjectSwitcher';
import DataroomActions from './components/DataroomActions';
import { useDataroomContext } from './providers/DataroomProvider';
import { usePageHeaderContext } from '~/components/PageHeader/PageHeaderProvider';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { usePageType, usePortfolioContext, useProjectContext } from '~/hooks';
import useAnalyticsPageView from '~/hooks/analytics/useAnalyticsPageView';
import { ProjectStatus } from '~/constants';
import { Project } from '~/types/project';

const DataroomPageContent = function () {
  const pageHeaderContext = usePageHeaderContext();
  const { isSimplifiedUI } = useDataroomContext();
  const { permissions } = usePermissions();
  const pageType = usePageType();
  const { project } = useProjectContext();
  const { portfolio } = usePortfolioContext();

  const isSimplifedEmptyState = pageType === 'project'
    ? project?.pricingType === 'ITC' || project?.status === ProjectStatus.MARKET_QUOTE
    : portfolio?.projects?.every((p: Project) => p.pricingType === 'ITC');

  return (
    <S.Container pageHeaderContext={pageHeaderContext} style={isSimplifiedUI && !permissions.canUpdateDataroom ? { paddingTop: 'var(--x-large)' } : {}}>
      <DocumentTypeSidebar />
      <MainContentContainer>
        {isSimplifiedUI && !permissions.canUpdateDataroom && <PortfolioProjectSwitcher />}
        {isSimplifiedUI && permissions.canUpdateDataroom && (
          <>
            <S.SimplifiedHeader as="h2">
              Share your {pageType} documents
              <DataroomActions />
            </S.SimplifiedHeader>
            <PortfolioProjectSwitcher />
            <p style={{ marginBottom: 'var(--medium)' }}>
              {isSimplifedEmptyState ? 
                <>
                  If you have documents you’d like to share, simply click the ‘add more documents’ button at the bottom, 
                  or drag-and-drop as many files as you like directly onto the ‘additional files’ section. 
                </> : 
                <>
                  Based on the type of {pageType} you’re building, Conductor Solar recommends sharing the following documents with potential partners. 
                  You can drag-and-drop the files directly onto each row or click to upload files. If you have other documents you’d like to share, 
                  simply click the ‘add more documents’ button at the bottom, 
                  or drag-and-drop as many files as you like directly onto the ‘additional files’ section. 
                </>
              }
            </p>
          </>
        )}
        <DocumentList />
        {isSimplifiedUI && (
          <div style={{ marginTop: 'calc(var(--medium) - var(--2x-small))' }}>
            <AdditionalDocumentsUploadButton />
          </div>
        )}
      </MainContentContainer>
    </S.Container>
  );
};

export default function Dataroom() {
  const pageHeaderContext = usePageHeaderContext();
  const { isLoading, hasError } = useDataroomContext();

  // Analytics
  useAnalyticsPageView('/dataroom', 'Dataroom');

  if (isLoading) {
    return (
      <div style={{ position: 'relative', width: '100%', height: pageHeaderContext.height ? `calc(100vh - ${pageHeaderContext.height/16}rem)` : '100%' }}>
        <PageLoader />
      </div>
    );
  } else if (hasError) {
    return null;
  }

  return <DataroomPageContent />;
}

