import React from 'react';
import * as S from './styles';
import { Dropdown } from 'semantic-ui-react';
import { useUpdateOwnership } from '~/mutations/admin/useUpdateOwnership';
import { useUpdateCompanyOwnership } from '~/mutations/admin/useUpdateCompanyOwnership';
import { CompanyOwnershipDisplay } from '~/constants/company-ownership';

interface OwnershipDropdownProps {
  typeDisplay?: string;
  ownershipText: string;
  defaultValue: string;
  buttonText: string;
  options: { key: string; text: string; value: string }[];
  onChange: (e: React.SyntheticEvent<HTMLElement>, value: string) => void;
}

const OwnershipDropdown: React.FC<OwnershipDropdownProps> = ({
  typeDisplay,
  ownershipText,
  defaultValue,
  buttonText,
  options,
  onChange,
}) => {
  const { status: updateOwnershipStatus } = useUpdateOwnership();
  const { status: updateCompanyOwnershipStatus } = useUpdateCompanyOwnership();

  const isDisabled = updateOwnershipStatus === 'loading' || updateCompanyOwnershipStatus === 'loading';

  return (
    <div>
      <S.OwnershipText>
        {ownershipText === CompanyOwnershipDisplay.OWNER ? `${typeDisplay} ${ownershipText}: ` : `${ownershipText}: `}
      </S.OwnershipText>
      <Dropdown
        defaultValue={defaultValue}
        disabled={isDisabled}
        trigger={
          <S.OwnershipDropdownButton>
            {buttonText}
          </S.OwnershipDropdownButton>
        }
        options={options}
        onChange={(e, data) => onChange(e, data.value as string)}
      />
    </div>
  );
};

export default OwnershipDropdown;