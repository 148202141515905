export const InvestorFeedbackRating = {
  GREAT_FIT: 'GREAT_FIT',
  OK_FIT: 'OK_FIT',
  NOT_GOOD_FIT: 'NOT_GOOD_FIT',
  DECLINED_TO_BID: 'DECLINED_TO_BID',
} as const;

export const InvestorFeedbackRatingDisplay = {
  [InvestorFeedbackRating.GREAT_FIT]: 'It\'s a great fit',
  [InvestorFeedbackRating.OK_FIT]: 'It\'s an ok fit',
  [InvestorFeedbackRating.NOT_GOOD_FIT]: 'It\'s not a good fit',
  [InvestorFeedbackRating.DECLINED_TO_BID]: 'Declined to bid',
} as const;

export const InvestorFeedbackRatingRadioOptions = [
  {
    value: InvestorFeedbackRating.GREAT_FIT,
    label: InvestorFeedbackRatingDisplay[InvestorFeedbackRating.GREAT_FIT],
  },
  {
    value: InvestorFeedbackRating.OK_FIT,
    label: InvestorFeedbackRatingDisplay[InvestorFeedbackRating.OK_FIT],
  },
  {
    value: InvestorFeedbackRating.NOT_GOOD_FIT,
    label: InvestorFeedbackRatingDisplay[InvestorFeedbackRating.NOT_GOOD_FIT],
  },
];

export const InvestorFeedbackSelection = {
  STATE_MARKET: 'STATE_MARKET',
  END_CUSTOMER: 'END_CUSTOMER',
  DEVELOPER: 'DEVELOPER',
  DEVELOPMENT_STAGE: 'DEVELOPMENT_STAGE',
  PRICING_TYPE: 'PRICING_TYPE',
  ECONOMICS: 'ECONOMICS',
  TECHNOLOGIES: 'TECHNOLOGIES',
  OTHER: 'OTHER',
} as const;

export const InvestorFeedbackSelectionDisplay = {
  [InvestorFeedbackSelection.STATE_MARKET]: 'The state\'s market',
  [InvestorFeedbackSelection.END_CUSTOMER]: 'The end customer',
  [InvestorFeedbackSelection.DEVELOPER]: 'The developer',
  [InvestorFeedbackSelection.DEVELOPMENT_STAGE]: 'The project development stage',
  [InvestorFeedbackSelection.PRICING_TYPE]: 'The desired pricing type',
  [InvestorFeedbackSelection.ECONOMICS]: 'Economics aren\'t feasible',
  [InvestorFeedbackSelection.TECHNOLOGIES]: 'The technologies involved with the project',
  [InvestorFeedbackSelection.OTHER]: 'Other',
} as const;

export const InvestorFeedbackSelectionOptions = [
  {
    value: InvestorFeedbackSelection.STATE_MARKET,
    label: InvestorFeedbackSelectionDisplay[InvestorFeedbackSelection.STATE_MARKET],
  },
  {
    value: InvestorFeedbackSelection.END_CUSTOMER,
    label: InvestorFeedbackSelectionDisplay[InvestorFeedbackSelection.END_CUSTOMER],
  },
  {
    value: InvestorFeedbackSelection.DEVELOPER,
    label: InvestorFeedbackSelectionDisplay[InvestorFeedbackSelection.DEVELOPER],
  },
  {
    value: InvestorFeedbackSelection.DEVELOPMENT_STAGE,
    label: InvestorFeedbackSelectionDisplay[InvestorFeedbackSelection.DEVELOPMENT_STAGE],
  },
  {
    value: InvestorFeedbackSelection.PRICING_TYPE,  
    label: InvestorFeedbackSelectionDisplay[InvestorFeedbackSelection.PRICING_TYPE],
  },
  {
    value: InvestorFeedbackSelection.ECONOMICS,
    label: InvestorFeedbackSelectionDisplay[InvestorFeedbackSelection.ECONOMICS],
  },
  {
    value: InvestorFeedbackSelection.TECHNOLOGIES,
    label: InvestorFeedbackSelectionDisplay[InvestorFeedbackSelection.TECHNOLOGIES],
  },
  {
    value: InvestorFeedbackSelection.OTHER,
    label: InvestorFeedbackSelectionDisplay[InvestorFeedbackSelection.OTHER],
  },
];

export const InvestorFeedbackBidStatus = {
  BID_PENDING: 'BID_PENDING',
  BID_DECLINED: 'BID_DECLINED'
} as const;

export const InvestorFeedbackButtonText = {
  DECLINE_AND_SUBMIT: 'Decline to bid + submit feedback',
  SUBMIT_FEEDBACK: 'Submit feedback',
  CONTINUE: 'Continue',
  MAKE_SELECTION: 'Please make a selection'
} as const;

