import { useMutation } from 'react-query';
import { createProject } from '~/api/projects';
import { Project } from '~/types/project';

export const useCreateProject = (params = {}) => {
  return useMutation({
    mutationFn: async (data: Partial<Project>) => {
      return await createProject(data); 
    },
    ...params, 
  });
};