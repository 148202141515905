import * as S from '../../../components/GridComponent';
import { usePermissions } from '~/requests/permissions/usePermissions';
import NotesForm from '~/components/form/NotesForm';

export const KEY_DETAILS_GRID = 'project-key-details-grid-id';

export default function KeyDetails({ project, values: projectDisplayValues }: any) {
  const { permissions } = usePermissions();

  const projectDetails = projectDisplayValues?.projectDetails;
  const projectInvestor = project?.investor;

  const developer = projectDetails?.developer;
  const location = projectDetails?.location;
  const arrayTypes = projectDetails?.technologies?.arrayTypes;
  const interconnection = projectDetails?.interconnectionType;
  const pricingGoal = projectDetails?.pricingType;
  const targetITC = projectDetails?.targetITC;
  const offTakeValue = projectDetails?.offtakeRate?.value;
  const longTermOwner = projectDetails?.longTermOwner;
  const projectInvestorName = projectInvestor?.company?.name;

  const displayOfftakeRate = projectDetails?.offtakeRate?.isDisplaying;
  const displayInvestor = projectInvestor && permissions.hasProjectDeveloperAccess;

  return (
    <S.GridContainer>
      <S.GridTable columnValues='11rem auto' gridColumn='1 / 4' id={KEY_DETAILS_GRID}>
      
        {displayInvestor && (
          <>
            <S.GridLabelEl>Investor</S.GridLabelEl>
            <S.GridTableEl gridColumn='2 / 4'>{projectInvestorName}</S.GridTableEl>
          </>
        )}

        {permissions.hasProjectDeveloperAccess && (
          <>
            <S.GridLabelEl>Bid type requested</S.GridLabelEl>
            <S.GridTableEl gridColumn='2 / 4'>{pricingGoal}</S.GridTableEl>
          </>
        )}

        {permissions.hasProjectInvestorAccess && (
          <>
            <S.GridLabelEl>Developer</S.GridLabelEl>
            <S.GridTableEl gridColumn='2 / 4'>{developer}</S.GridTableEl>
          </>
        )}
        
        <S.GridLabelEl>Location</S.GridLabelEl>
        <S.GridTableEl gridColumn='2 / 4'>{location}</S.GridTableEl>
        
        <S.GridLabelEl>Size + system</S.GridLabelEl>
        <S.GridTableEl gridColumn='2 / 4'>{arrayTypes}</S.GridTableEl>

        <S.GridLabelEl>Interconnection</S.GridLabelEl>
        <S.GridTableEl gridColumn='2 / 4'>{interconnection}</S.GridTableEl>

        <S.GridLabelEl>Ownership</S.GridLabelEl>
        <S.GridTableEl gridColumn='2 / 4'>{longTermOwner}</S.GridTableEl>
        
        {permissions.hasProjectInvestorAccess && (
          <>
            <S.GridLabelEl>Pricing goal</S.GridLabelEl>
            <S.GridTableEl gridColumn='2 / 4'>{pricingGoal}</S.GridTableEl>
          </>
        )}

        <S.GridLabelEl>Expected ITC value</S.GridLabelEl>
        <S.GridTableEl gridColumn='2 / 4'>{targetITC}</S.GridTableEl>

        {displayOfftakeRate ? (
          <>
            <S.GridLabelEl>Offtake details</S.GridLabelEl>
            <S.GridTableEl gridColumn='2 / 4'>{offTakeValue}</S.GridTableEl>
          </>
        ) : null}    

        {permissions.hasProjectDeveloperAccess ? (
          <>
            <S.GridLabelEl style={{ display: 'flex', alignItems: 'center' }}>Project notes</S.GridLabelEl>
            <S.GridTableEl gridColumn='2 / 4'>
              <NotesForm project={project} />     
            </S.GridTableEl>
          </>
        ) : project?.projectNotes ? (
          <>
            <S.GridLabelEl style={{ display: 'flex', alignItems: 'center' }}>Project notes</S.GridLabelEl>
            <S.GridTableEl gridColumn='2 / 4'>
              {project.projectNotes} 
            </S.GridTableEl>
          </>
        ) : null}
      </S.GridTable>
    </S.GridContainer>
  );
};       