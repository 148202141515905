import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getPortfolioConversations, getProjectConversations } from '~/api/conversations';
import { usePageType } from '~/hooks';

export const conversationListKey = (type: string, id: string) => ['conversations', type, id];

export const useConversationListQuery = () => {
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string }>();
  const pageType = usePageType();
  
  const useQueryResult = useQuery({
    queryKey: conversationListKey(pageType, id),
    async queryFn() {
      if (pageType === 'project' && id) {
        return getProjectConversations(id);
      } else {
        return getPortfolioConversations(id);
      }
    },
    staleTime: Infinity, // Don't refetch unless query is invalidated
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false
  });

  useEffect( () => {
    useQueryResult.refetch();
  }, [ pathname ] );

  return useQueryResult;
};