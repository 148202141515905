import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { Modal } from '~/components';
import { Button } from '~/components';
import { Form } from 'semantic-ui-react';
import { useForm } from '~/hooks';
import { FormRadioSelectGroup, FormSchemaAwareWidget } from '~/components/form';
import { STATES } from '~/utils';
import pricingEstimateDefaultValues from '~/schema/pricingEstimate/pricingEstimateDefaultValues';
import { ModalFormProps } from './types';
import { CreatePricingEstimateRadioOptions, RecOwnership } from '~/constants';
import { useCreateProject } from '~/mutations/project/useCreateProject';

const PricingTypeDisplay = styled.div`
  &&& {
    .field.label label {
      padding: 0;
    }
  }
`;

const CreatePricingEstimateForm = ({ 
  closeModal, 
  projectData = {}
}: ModalFormProps) => {
  const { formState } = useForm() as any;
  const history = useHistory();
  
  // Determine if form has an error
  const hasError = !formState.name || !formState.addressState || !formState.pricingType;
  
  const { mutateAsync: createPricingEstimate, status: createPricingEstimateStatus } = useCreateProject();
  
  const handleSubmit = async () => {
    try {
      const estimate = await createPricingEstimate({
          ...pricingEstimateDefaultValues,
          ...projectData,
          name: formState?.name,
          addressState: formState?.addressState,
          pricingType: formState?.pricingType,
          recOwnership: formState?.addressState === 'New Jersey' ? undefined : RecOwnership.INVESTOR,
      });
      if (estimate?.id) {
        history.push(`/pricing-estimate/${estimate?.id}/worksheet`);
        closeModal();
      }
    } catch (err) {
      console.error('Error occurred creating pricing estimate:', err);
      toast.error('An error occurred while creating the pricing estimate. Please try again.', { duration: 5000 });
    }
  };

  return (
    <>
      <Modal.Title>
        Create new pricing estimate
      </Modal.Title>
      <Modal.Content>
        <p>Use our marketplace data to price your C&I project. With a handful of inputs you can see how much your project is worth or the rate that your customer will pay for their lease or PPA.</p>
        <Form> 
          <FormSchemaAwareWidget
            fieldLabel="Project name"
            placeholder="Enter value"
            schemaKey="name"
          />
          <FormSchemaAwareWidget
            fieldLabel="State"
            placeholder="Enter value"
            schemaKey="addressState"
            search
            options={STATES}
          />
          <PricingTypeDisplay>
            <FormRadioSelectGroup
              fieldLabel="Solve for"
              schemaKey="pricingType"
              defaultValue={CreatePricingEstimateRadioOptions[0].value}
              values={CreatePricingEstimateRadioOptions}
              inline
              stretched
            />
          </PricingTypeDisplay>
        </Form>
      </Modal.Content>
      <Modal.Footer style={{ marginTop: 'var(--medium)' }}>
        <Button primary outlined onClick={() => closeModal()}>Cancel</Button>
        <Button 
          primary
          disabled={hasError || createPricingEstimateStatus === 'loading'}
          loading={createPricingEstimateStatus === 'loading'} 
          onClick={() => handleSubmit()}
        >
          Create estimate
        </Button>
      </Modal.Footer>
    </>
  );
};

export default CreatePricingEstimateForm;