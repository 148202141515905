import { useMutation } from 'react-query';
import { updateProjectOwnership, updatePortfolioOwnership } from '~/api/admin';

interface UpdateOwnershipParams {
  entityType: string;
  entityId: string;
  userId: string;
}

export const useUpdateOwnership = (params = {}) => {
  return useMutation({
    mutationFn: async ({ entityType, entityId, userId }: UpdateOwnershipParams) => {
      if (entityType === 'project') {
        return updateProjectOwnership(entityId, userId);
      } else if (entityType === 'portfolio') {
        return updatePortfolioOwnership(entityId, userId);
      }
    },
    ...params
  });
};