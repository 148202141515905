import * as S from '../styles';
import PricingEstimate from './PricingEstimate';
import KeyDetails from './KeyDetails';
import ProductionEstimate from './ProductionEstimate';
import BudgetTable from './BudgetTable';
import ProductionEstimateButton from '~/components/ProductionEstimateButton';
import PricingEstimateButton from './PricingEstimate/PricingEstimateButton';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { Project } from '~/types/project';
import { PricingTypes } from '~/types/project';
import ProjectCompletionStates from './ProjectCompletionStates';
import SavingsAnalysisTable from '~/features/pricingEstimateReview/SavingsAnalysis/SavingsAnalysisTable';
import { 
  ProjectInterconnectionTypeCodes, 
  LongTermOwner, 
  PricingType, 
  DeploymentTypes 
} from '~/constants';

//TODO remove the prop-drilling, find a better context-based approach to work with the data

interface ProjectSummaryDataProps {
  project: Project;
  values: any;
  projectErrors: any;
  projectState: any;
  projectAutopricing: any;
}

export default function ProjectSummaryData({ 
  project, 
  values: projectDisplayValues, 
  projectErrors, 
  projectState,
  projectAutopricing 
}: ProjectSummaryDataProps) {
  const { permissions } = usePermissions();
  const pricingType = project?.pricingType as PricingTypes;
  const autopricingResult = project?.autopricingResult ? project?.autopricingResult[pricingType] : [];

  const hasProdEstimate = projectDisplayValues?.productionEstimates?.hasProductionEstimate;
  const showProductionEstimateTable = permissions.hasProjectDeveloperAccess || hasProdEstimate;
  const showProductionEstimateButton = permissions.hasProjectDeveloperAccess;

  const showSavingsAnalysis = 
    permissions?.hasProjectDeveloperAccess &&
    project?.deploymentType === DeploymentTypes.CI &&
    project?.longTermOwner === LongTermOwner.THIRD_PARTY &&
    project?.interconnectionType === ProjectInterconnectionTypeCodes.BEHIND_METER &&
    project?.pricingType === PricingType.OFFTAKE;

  return (
    <S.TableContainer>
      {projectDisplayValues ? (
        <>
          <S.ProjectDetailsContainer>
            <ProjectCompletionStates />
            <S.Header as='h2'>Key details</S.Header>
            <KeyDetails project={project} values={projectDisplayValues} />
          </S.ProjectDetailsContainer>
          <S.BudgetContainer>
            <S.Header as='h2'>Budget</S.Header>
            <BudgetTable 
              values={projectDisplayValues} 
              format='fixed'
              hasProjectTotals
            />
          </S.BudgetContainer>
        </>
      ) : null}  
      <S.EstimatesContainer>
        <S.ProductionEstimateContainer autopricingResult={autopricingResult ?? []}>
          {showProductionEstimateTable && (<ProductionEstimate values={projectDisplayValues} />)}
          {showProductionEstimateButton && (<ProductionEstimateButton project={project} values={projectDisplayValues} errors={projectErrors} />)}
          
        </S.ProductionEstimateContainer>
          <S.PricingEstimateContainer>
            {permissions.hasProjectDeveloperAccess && (<PricingEstimate project={project} projectAutopricing={projectAutopricing} />)}
            {permissions.hasProjectDeveloperAccess && (<PricingEstimateButton project={project} projectState={projectState} errors={projectErrors} />)}
    
          </S.PricingEstimateContainer>
          <S.SavingsAnalysisContainer>
            {showSavingsAnalysis && <SavingsAnalysisTable />}
          </S.SavingsAnalysisContainer>
      </S.EstimatesContainer>
      
    </S.TableContainer>
  );
}