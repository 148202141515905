export const CreateProjectTypes = {
  MARKET_QUOTE: 'MARKET_QUOTE',
  PROJECT: 'PROJECT',
};

export const CreateProjectTypesDisplay = {
  [CreateProjectTypes.MARKET_QUOTE]: 'Get a quick pricing estimate',
  [CreateProjectTypes.PROJECT]: 'Prepare a project for investors',
};

export const CreateProjectTypesRadioOptions = [
  { value: CreateProjectTypes.MARKET_QUOTE, label: CreateProjectTypesDisplay[CreateProjectTypes.MARKET_QUOTE] },
  { value: CreateProjectTypes.PROJECT, label: CreateProjectTypesDisplay[CreateProjectTypes.PROJECT] },
];


