import * as S from './styles';
import { useDataroomContext } from '../../providers/DataroomProvider';
import DataroomActions from '../DataroomActions';
import { Button } from '~/components';
import DocumentRow from './DocumentRow';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { DataroomDocument } from '~/types/dataroom';

export default function DocumentList() {
  const { 
    dataroom, 
    selectedCategory, 
    selectedDoclistCategory, 
    editingDocumentTypes, 
    setEditingDocumentTypes,
    isSimplifiedUI
  } = useDataroomContext();

  const { permissions } = usePermissions();

  let documentTypes: DataroomDocument[] | undefined = undefined;
  if (isSimplifiedUI) {
    const additionalDocumentsMap = {} as { [id: string]: boolean };
    const allDocTypes = dataroom?.documents
      ?.reduce((acc, curr) => {
        if (curr.code === 'ADDITIONAL_DOCUMENTS') {
          curr.documentTypes.forEach(documentType => {
            additionalDocumentsMap[documentType.id] = true;
          });
        }
        acc.push(...curr.documentTypes);
        return acc;
      }, [] as DataroomDocument[])
      ?.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }) ?? [];

      documentTypes = [
        ...allDocTypes.filter(documentType => !additionalDocumentsMap[documentType.id]),
        ...allDocTypes.filter(documentType => additionalDocumentsMap[documentType.id])
      ];
  } else {
    documentTypes = dataroom?.documents
      ?.find((category) => category?.id === selectedCategory?.id)?.documentTypes
      ?.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
  }

  const uploadedDocuments = documentTypes?.filter(documentType => {
    return documentType.isSelected && documentType.document; 
  }) ?? [];

  const selectedDocumentTypes = documentTypes?.filter(documentType => {
    if (permissions?.canUpdateDataroom) { 
      return documentType.isSelected && !documentType.document;
    } else {
      return null;
    }
  }) ?? [];

  const unSelectedDocumentTypes = selectedDoclistCategory?.documentTypes?.filter((documentType: any) => { 
    return !documentType.isSelected;
  }).sort((a: any, b: any) => a.name.localeCompare(b.name)) ?? [];

  return (
    <div className="document-list">
      {!isSimplifiedUI && <DataroomActions />} 
        <S.DocumentList isEditing={editingDocumentTypes}>
          {uploadedDocuments.length || selectedDocumentTypes.length ? (
            <>
              {uploadedDocuments.map((documentType) => (
                <DocumentRow key={documentType?.id} data={documentType} />
              ))}
              <S.SelectedDocumentTypesRow 
                showSpacing={uploadedDocuments.length > 0}
                isEditing={editingDocumentTypes}
              >
                {selectedDocumentTypes.map((documentType) => (
                  <DocumentRow key={documentType?.id} data={documentType} />
                ))}
              </S.SelectedDocumentTypesRow>
            </>
          ) : (
            permissions?.canUpdateDataroom && !editingDocumentTypes && !isSimplifiedUI ? (
              <p style={{ marginTop: 'var(--large)'}}>
                There are currently no documents selected for this category. 
                To add a new document, click the ‘Edit doc list’ button above,
                or click <Button primary variant="link" onClick={() => setEditingDocumentTypes(true)}>here</Button>.
              </p>
          ) : !editingDocumentTypes && !isSimplifiedUI && (
            <p style={{ marginTop: 'var(--large)'}}>
              There are currently no documents available for this category.
            </p>
            )
          )}
        <S.UnselectedDocumentRow isEditing={editingDocumentTypes}>
          {unSelectedDocumentTypes.map((documentType: any) => (
            <DocumentRow key={documentType.id} data={documentType} />
          ))}
        </S.UnselectedDocumentRow>
      </S.DocumentList>
     </div>
  );
}