import { Popup } from 'semantic-ui-react';
import { ReactComponent as DNVLogo } from 'assets/dnv.svg';
import * as S from '../../../components/GridComponent';

interface ProductionEstimateProps {
  values: any;
}

export default function ProductionEstimate({ 
  values: projectDisplayValues,
}: ProductionEstimateProps) {
  const prodEstimate = projectDisplayValues?.productionEstimates;

  const yearOneProduction = projectDisplayValues?.yearOneProduction;
  const estVal = prodEstimate?.estVal;

return (
  <S.SubGrid style={{ paddingTop: '2rem' }} columnValues='12rem 1fr 1fr' maxWidth='40.625rem'>
    <div />
    <strong>Developer-provided</strong>
    
    <strong style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', top: '-2rem', right: '0rem', paddingRight: 'var(--2x-small)' }}>
        <Popup
          inverted
          trigger={
            <a 
              style={{ display: 'inline-block', width: '3.625rem', marginLeft: 'auto' }}
              target="_blank"
              rel="noreferrer"
              href="https://www.dnv.com/power-renewables/generation/solar-energy.html"
            >
              <DNVLogo />
            </a>
          }
          content="The estimated p50 range is computed by DNV's Solar Resource Compass (SRC) web-service, incorporating user-provided project capacity, location, mounting type and orientation. SRC automatically models inputs including irradiance, snow, soiling and albedo impacts. Click the logo to learn more about DNV."
          position="top center"
          on={['hover', 'focus']}
          style={{ maxWidth: '22.5rem', fontSize: '0.875rem', lineHeight: '1.25rem' }}
        />
      </div>
      DNV estimate
    </strong>
    <div className='line'></div>
    <S.GridTable gridColumn='1 / 4' columnValues='12rem 1fr 1fr' lineHeight="1.5rem">
      <S.GridLabelEl black>Production factor (kWh/kW)</S.GridLabelEl>
      <S.GridTableEl bold textAlign='right'>{yearOneProduction}</S.GridTableEl>
      <S.GridTableEl bold textAlign='right'>{estVal}</S.GridTableEl>
    </S.GridTable>
      
  </S.SubGrid>
  );
};  