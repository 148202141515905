import { useEffect, useRef, useState } from 'react';
import Tab from '~/components/Tab';
import { ProjectDashboardFiltersList } from '~/constants';
import { useQueryParamState } from '~/hooks/useQueryParamState';
import StickyContainer from '../StickyContainer';
import useHeightCalculation from '~/hooks/useHeightCalculation';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { DASHBOARD_QUERY_PARAM_STATE } from '../../constants';
import sendFilterClickEvent from '~/utils/analytics/sendFilterClickEvent';
import styled from 'styled-components';
import ProjectFiltersDropdown from './ProjectFiltersDropdown';

const DashboardTabs = styled.div`
  &&& {
    .ui.secondary.pointing.menu > :nth-child(3) {
      margin-left: auto;
    }
  }
`;
interface DashboardNavProps {
  onHeightCalculation: (height: number) => void;
}

export default function NavRow({ onHeightCalculation }: DashboardNavProps) {
  const ref = useRef(null);
  const { permissions } = usePermissions();
  
  const [dashboardState, setDashboardState] = useQueryParamState(DASHBOARD_QUERY_PARAM_STATE);
  const [dropdownFilters, setDropdownFilters] = useState<string[]>([]);

  // Report the height of this component to the parent
  useHeightCalculation(ref, onHeightCalculation);

  // Get the current filter based on dropdownFilters
  // If there are no dropdownFilters, then the dashboard filter is used or  defaulted to 'ALL'
  const currentDashboardFilter = dropdownFilters.length > 0 
    ? undefined 
    : (dashboardState?.filter ?? ProjectDashboardFiltersList[0].value);

  // Get the index of the current dashboard filter
  const [activeTabIndex, setActiveTabIndex] = useState(
    ProjectDashboardFiltersList.findIndex(({ value }) => value === currentDashboardFilter)
  );

  // This tab menu is used only for updating the url query param.
  // This is why there is just an onTabChange handler and no render function
  const dashboardTabs = [
    { menuItem: ProjectDashboardFiltersList[0].label },
    {
      menuItem: {
        key: ProjectDashboardFiltersList[1].value,
        content: (
          <ProjectFiltersDropdown 
            currentDashboardFilter={currentDashboardFilter}
            dropdownFilters={dropdownFilters}
            setDropdownFilters={setDropdownFilters}
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={setActiveTabIndex} 
          />
        )
      }
    },  
    { menuItem: permissions.isAdmin && ProjectDashboardFiltersList[2].label }    
  ];

  const handleDashboardTabChange = (_ignored: any, { activeIndex }: { activeIndex: number }) => {
    //Using a tab as a dropdown requires manual update of the active index
    setActiveTabIndex(activeIndex); 

    //It is only necessary to set the dashboard filter if the active tab is not the dropdown 
    if (activeIndex !== 1) {
      const filter = ProjectDashboardFiltersList[activeIndex]?.value;
    
      if (!filter && filter !== '') {
        setDropdownFilters([]);
      } else {
        //Google Analytics event
        sendFilterClickEvent(filter);

        const newDashboardState = { ...dashboardState, dashboardFilter: filter };
        setDashboardState(newDashboardState); 
      } 
    };
  };

  useEffect(() => {
    // If the active tab is NOT the dropdown tab, then it is a dashboard filter
    // dropdownFilters must be cleared in order for dashboard filters to be utilized and filter properly
    if (activeTabIndex !== 1) {
      setDropdownFilters([]);
      const newDashboardState = { ...dashboardState, dropdownFilters: [] };
      setDashboardState(newDashboardState);
    }
 }, [activeTabIndex]); 

  return (
    <StickyContainer style={{ paddingTop: 'var(--large)', zIndex: 2 }} ref={ref}>
      <DashboardTabs>
        <Tab
          panes={dashboardTabs}
          activeIndex={activeTabIndex}
          onTabChange={handleDashboardTabChange}
        />
      </DashboardTabs>
    </StickyContainer>
  );
}