import WorksheetForm from '~/features/projectWizard/worksheets/common/components/WorksheetForm';
import { WorksheetField } from '../../../worksheets/common/components/Field';
import { InputType } from '~/schema';
import { ExpensesUnit, UnitDecimals } from '~/constants';
import { useProjectContext } from '~/hooks';
import { Grid } from 'semantic-ui-react';
import { Button } from '~/components';

const defaultAdditionalExpense = {
  name: '',
  amount: null,
  escalator: null,
  expectedTerm: null,
  unit: ExpensesUnit.KWDC,
  notes: '',
};

export default function OperatingExpenses () {
  const { project, updateProjectValue } = useProjectContext();

  const add = () => {
    updateProjectValue('additionalOperatingExpenses', [...project.additionalOperatingExpenses, { ...defaultAdditionalExpense }]);
  };

  const remove = (i: number) => {
    updateProjectValue('additionalOperatingExpenses', [
      ...project.additionalOperatingExpenses.slice(0, i),
      ...project.additionalOperatingExpenses.slice(i + 1, project.additionalOperatingExpenses.length)
    ]);
  };

  return (
    <WorksheetForm>
      {/* Additional Operating Expenses */}
      {!project.additionalOperatingExpenses?.length ? (
        <>
          <h3>
            Additional operating expenses
          </h3>
          <p style={{ marginTop: 'var(--x-small)'}}>
            If there are additional operating expenses you would like to add,{' '}
            <Button variant="link" onClick={add}>
              click here
            </Button>
            {' '}
            to add the first one.
          </p> 
        </>
      ) : project.additionalOperatingExpenses?.map((_revenue: any, i: number) => (
        <>
          <h3>Additional operating expense #{i + 1}</h3>
          <WorksheetField 
            fieldLabel="Name"
            placeholder="Enter name"
            schemaKey={`additionalOperatingExpenses.${i}.name`}
          />
          <WorksheetField
            fieldLabel="Expected annual cost"
            type="number"
            schemaKey={`additionalOperatingExpenses.${i}.amount`}
            numberType={InputType.DOLLARS}
            unitField="unit"
            units={[
              {value: ExpensesUnit.KWDC, label: 'per kWdc', decimals: UnitDecimals.KWDC},
              {value: ExpensesUnit.YEAR, label: 'per year', decimals: UnitDecimals.YEAR},
            ]}
          />
          <WorksheetField
            fieldLabel="Notes"
            placeholder="Please describe the costs provided."
            schemaKey={`additionalOperatingExpenses.${i}.notes`}
            type="textarea"
          />
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <WorksheetField
                  fieldLabel="Expected annual escalator"
                  schemaKey={`additionalOperatingExpenses.${i}.escalator`}
                  type="number"
                  numberType={InputType.PERCENT}
                  decimals={2}
                />
              </Grid.Column>
              <Grid.Column>
                <WorksheetField
                  fieldLabel="Expected term"
                  schemaKey={`additionalOperatingExpenses.${i}.expectedTerm`}
                  type="number"
                  decimals={0}
                  label="years"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Button variant="link"  onClick={() => remove(i)} style={{ marginBottom: i !== project?.additionalOperatingExpenses?.length - 1 ? 'var(--medium)' : 0 }}>
            Remove this additional operating expense
          </Button>
        </>
      ))}
      {project.additionalOperatingExpenses?.length > 0 && (
        <div>
          <Button variant="link" onClick={add}>
            Add another additional operating expense
          </Button>
        </div> 
      )}
    </WorksheetForm>
  );
}