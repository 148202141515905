import { 
  EpcCompanyType, 
  LongTermOwner, 
  PricingType, 
  ProjectInterconnectionDropdownValues, 
  PropertyTaxType, 
  RevenueSource, 
  TaxUnit, 
  UnitDecimals, 
  RecOwnership,
  RecOwnershipDisplay, 
  ProjectArrays,
  LongTermOwnerDropdownOptions,
  LongTermOwnerDisplayValues,
  ProjectStatus,
  CustomerTypes,
} from '~/constants';
import { FormConditions } from '../types';
import { when } from '../util';
import { Project } from '~/types/project';
import { DevelopmentStages } from '~/constants/development-stage';
import { PartnerTypeRadioOptions, PartnerTypes } from '~/constants/partner-type';
import { DeploymentTypes } from '~/constants/deployment-type';
import { RecStates } from '~/constants';

const isCustomerOwned = (p: Project) => p.longTermOwner === LongTermOwner.END_USER;
const isNotCustomerOwned = (p: Project) => p.longTermOwner !== LongTermOwner.END_USER;
const isCommercialOrIndustrial = (p: Project) => p.deploymentType === DeploymentTypes.CI && p.longTermOwner !== LongTermOwner.END_USER;
const isCommunitySolar = (p: Project) => p.revenueSource === RevenueSource.COMMUNITY_SOLAR_SUBS;
const isNotCommunitySolar = (p: Project) => !isCommunitySolar(p);
const isOperating = (p: Project) => p.developmentStage === DevelopmentStages.OPERATING;
const isCommunitySolarWithRevenueAssumptions: (p: Project) => boolean = (p: Project) => !!(isCommunitySolar(p) && p.revenueAssumptionsFlag);
const showSubscriptionManagementFields: (p: Project) => boolean = (p: Project) => !!(isCommunitySolar(p) && p.subscriptionManagementAssumptionsFlag);
const showStorageExpenseFields: (p: Project) => boolean = (p: Project) => !!(p.hasStorage && p.storageOmFlag);

const formConditions: FormConditions = {
  fields: {
    longTermOwner: [
      when('developmentStage', 'isNull').then({ hide: true }),
      when(isOperating).then({ props: { fieldLabel: 'Who owns and operates the system?' } }),
      when((p: Project) => (
        p.deploymentType === DeploymentTypes.COMMUNITY_SOLAR && isOperating(p)
      )).then({
        props: {
          fieldLabel: 'Who owns and operates the system?',
          values: LongTermOwnerDropdownOptions.filter(option => option.value !== LongTermOwner.END_USER)
            .map(option =>  option.value === LongTermOwner.THIRD_PARTY ? { value: option.value, label: `${LongTermOwnerDisplayValues[LongTermOwner.THIRD_PARTY]} (e.g. a separate IPP)`} : option)
        }
      }),
      when((p: Project) => (
        p.deploymentType === DeploymentTypes.COMMUNITY_SOLAR && !isOperating(p)
      )).then({
        props: {
          values: LongTermOwnerDropdownOptions.filter(option => option.value !== LongTermOwner.END_USER)
            .map(option =>  option.value === LongTermOwner.THIRD_PARTY ? { value: option.value, label: `${LongTermOwnerDisplayValues[LongTermOwner.THIRD_PARTY]} (e.g. a separate IPP)`} : option)
        }
      }),
 

    ],
    partnerType: [
      when('developmentStage', 'isNull').then({ hide: true }),
      when((p: Project) => (
        [DeploymentTypes.CI, DeploymentTypes.COMMUNITY_SOLAR, DeploymentTypes.UTILITY].includes(p.deploymentType!) &&
        [DevelopmentStages.PROSPECTING, DevelopmentStages.DEVELOPMENT, DevelopmentStages.CONSTRUCTION].includes(p.developmentStage as string) &&
        [LongTermOwner.DEVELOPER, LongTermOwner.END_USER].includes(p.longTermOwner as string)
      )).then({
        props: {
          values: PartnerTypeRadioOptions.filter(option => option.value !== PartnerTypes.OWN)
        }
      }),
      when((p: Project) => (
        (p.deploymentType === DeploymentTypes.COMMUNITY_SOLAR || p.deploymentType === DeploymentTypes.CI) && 
        p.longTermOwner === LongTermOwner.THIRD_PARTY && 
        p.developmentStage !== DevelopmentStages.OPERATING
      )).then({ hide: true }),
    ],
    pricingType: when((p: Project) => {
      const isInDevelopmentPhase = (
        !!p.developmentStage && 
        p.developmentStage !== DevelopmentStages.OPERATING && 
        p.partnerType === PartnerTypes.OWN 
      );
      const isCommunitySolarOwned = p.deploymentType === DeploymentTypes.COMMUNITY_SOLAR && p.partnerType === PartnerTypes.OWN;
      const isQuote = p.status === ProjectStatus.MARKET_QUOTE;
      return isQuote ? !isInDevelopmentPhase : isInDevelopmentPhase && !isCommunitySolarOwned;
    }).then({ show: true, persist: true }),
    expectedProceedDate: when(isOperating).then({ hide: true }),
    expectedCommercialOperationDate: when(isOperating).then({
      props: {
        fieldLabel: 'Commercial operation date'
      }
    }),
    epcCompanyType: when(isOperating).then({ hide: true }),
    epcCost: when((p: Project) => p.pricingType === PricingType.ACQUISITION).then({ hide: true }),
    epcCostNotes: when('pricingType', '=', PricingType.ACQUISITION).then({ hide: true }),
    epcCompanyName: when('epcCompanyType', '=', EpcCompanyType.OTHER_DEVELOPER).then({ show: true }),
    avoidedCostOfSolar: [
      when(isCustomerOwned).then({
        props: {
          helpText: 'This is the value of $/kWh avoided by the end user due to the new solar generation.'
        }
      }),
      when(isCommunitySolar).then({ hide: true })
    ],
    customerName: [
      when(isCustomerOwned).then({ 
        props: { 
          fieldLabel: 'Customer name', 
          placeholder: 'Enter customer name',
          helpText: 'Entity name of the the end user. Ideally, this is their legal name, but it\'s OK if you don\'t know that right now.'
        } 
      }),
      when(isNotCustomerOwned).then({ 
        props: { 
          fieldLabel: 'Offtaker name', 
          placeholder: 'Enter offtaker name' 
        } 
      }),
      when(isCommercialOrIndustrial).then({ 
        props: { 
          fieldLabel: 'Customer name', 
          placeholder: 'Enter customer name',
          helpText: 'Entity name of the the end user. Ideally, this is their legal name, but it\'s OK if you don\'t know that right now.'
        } 
      }),
      when(isCommunitySolar).then({ hide: true })
    ],
    customerType: [
      when(isCustomerOwned).then({
        props: {
          fieldLabel: 'Customer type',
          placeholder: 'Select customer type',
        }
      }),
      when(isNotCustomerOwned).then({
        props: {
          fieldLabel: 'Offtaker type',
          placeholder: 'Select offtaker type',
        }
      }),
      when(isCommercialOrIndustrial).then({
        props: {
          fieldLabel: 'Customer type',
          placeholder: 'Select customer type',
        }
      }),
      when(isCommunitySolar).then({
        props: {
          fieldLabel: 'Offtaker type',
          placeholder: 'Select offtaker type',
          options: [{
            key: CustomerTypes.COMMUNITY_SOLAR, 
            text: CustomerTypes.COMMUNITY_SOLAR, 
            value: CustomerTypes.COMMUNITY_SOLAR
          }]
        }
      })
    ],
    customerPricingStatus: [
      when(isCustomerOwned).then({ hide: true }),
      when(isCommunitySolar).then({ hide: true }),
      when(isOperating).then({ hide: true })
    ],
    offtakeContractStatus: [
      when(isCustomerOwned).then({ hide: true }),
      when(isCommunitySolar).then({ hide: true }),
      when(isOperating).then({ hide: true })
    ],
    permitsStatus: when(isOperating).then({ hide: true }),
    siteControlStatus: [
      when(isCustomerOwned).then({ hide: true }),
      when(isOperating).then({ hide: true })
    ],
    interconnectionStatus: [
      when('interconnectionType', '=', 'FRONT_OF_METER').then({
        props: {
          options: ProjectInterconnectionDropdownValues.filter(({ condition }) => !condition || condition === 'FTM')
        }
      }),
      when('interconnectionType', '=', 'BEHIND_METER').then({
        props: {
          options: ProjectInterconnectionDropdownValues.filter(({ condition }) => !condition || condition === 'BTM')
        }
      }),
      when(isOperating).then({ hide: true })
    ],
    customerCreditRating: [
      when(isCustomerOwned).then({ hide: true }),
      when(isNotCustomerOwned).then({
        props: {
          fieldLabel: 'Offtaker credit details',
          helpText: 'Use this space to indicate whatever high-level information is known about the offtaker\'s credit quality (i.e., their financial strength).'
        }
      }),
      when(isCommercialOrIndustrial).then({
        props: {
          fieldLabel: 'Customer credit details',
          helpText: 'Use this space to indicate whatever high-level information is known about the customer\'s credit quality (i.e., their financial strength).'
        }
      }),
      when(isCommunitySolar).then({ hide: true }),
    ],
    contractType: [
      when(isCustomerOwned).then({
        props: {
          fieldLabel: 'Customer contract type'
        }
      }),
      when(isNotCustomerOwned).then({
        props: {
          fieldLabel: 'Offtake contract type'
        }
      }),
      when(isCommercialOrIndustrial).then({
        props: {
          fieldLabel: 'Customer offtake contract type'
        }
      }),
      when(isCommunitySolar).then({ hide: true })
    ],
    contractTerm: [
      when(isCommunitySolar).then({ hide: true }),
      when(isCommunitySolarWithRevenueAssumptions).then({
        show: true,
        props: {
          fieldLabel: 'Offtake term',
          placeholder: 'Enter offtake term'
        }
      }),
      when(isNotCommunitySolar).then({ show: true }),
    ],
    contractRate: [
      when('pricingType', '=', PricingType.OFFTAKE).then({ hide: true }),
      when(isCommunitySolar).then({ hide: true }),
      when(isCommunitySolarWithRevenueAssumptions).then({
        show: true,
        props: {
          fieldLabel: 'Base power rate',
          placeholder: 'Enter base power rate',
          helpText: 'This is the baseline $/kWh value before any community solar discounts are applied.          '
        }
      }),
      when((p: Project) => isNotCommunitySolar(p) && p.pricingType !== PricingType.OFFTAKE).then({ show: true }),
      when(isCommercialOrIndustrial).then({
        props: {
          fieldLabel: 'Rate',
        }
      }),
    ],
    recOwnership: [
      when(isCommunitySolar).then({ hide: true, persist: true }),
      when(isCommercialOrIndustrial).then({ 
        props: {
          values: [
            { value: RecOwnership.INVESTOR, label: RecOwnershipDisplay[RecOwnership.INVESTOR] },
            { value: RecOwnership.OFFTAKE_CUSTOMER, label: 'Customer' },
           ]
        } 
      }),
      when('addressState', '=', RecStates.ILLINOIS).then({ 
        persist: true,
        props: {
          helpText: 'Which party has the rights to sell any RECs generated by the project?'
        } 
      }),
      when('addressState', '=', RecStates.NEW_JERSEY).then({ 
        persist: true,
        props: {
          helpText: 'Which party has the rights to sell any RECs generated by the project?'
        } 
      }),
      when('addressState', '=', RecStates.MASSACHUSETTS).then({ 
        persist: true,
        props: {
          helpText: <>Which party has the rights to sell any RECs generated by the project?<br /><br />NOTE: Conductor does not automatically include SMART revenues for this project. Please use the &lsquo;Other revenue streams&rsquo; tab to incorporate those into the project.</>
        } 
      }),
    ],
    communitySolarDiscountPercentage: when(isCommunitySolarWithRevenueAssumptions).then({ show: true }),
    contractEscalator: [
      when(isCommunitySolar).then({ hide: true }),
      when(isCommunitySolarWithRevenueAssumptions).then({
        show: true,
        props: {
          fieldLabel: 'Estimated escalator',
          placeholder: 'Enter estimated escalator',
          helpText: 'This is the estimated escalator on the base power rate.'
        }
      })
    ],
    siteLeaseTerm: when('interconnectionType', '=', 'BEHIND_METER').then({ hide: true }),
    siteLeaseExtensions: when('interconnectionType', '=', 'BEHIND_METER').then({ hide: true }),
    siteLeaseAcreage: when((p: Project) => Boolean(p?.projectArrays?.some(array => array.type === ProjectArrays.GROUNDMOUNT_FIXED_TILT || array.type === ProjectArrays.GROUNDMOUNT_SINGLE_AXIS))).then({ show: true }),
    stateSpecificUpfrontIncentives: when('addressState', '=', 'Illinois').then({ show: true }),
    stateSpecificUpfrontIncentiveNotes: when('addressState', '=', 'Illinois').then({ show: true }),
    propertyTaxAmount: [
      when('propertyTaxType', '=', PropertyTaxType.EXEMPT).then({ hide: true }),
      when('propertyTaxType', '=', null).then({ hide: true }),
      when('propertyTaxType', '=', PropertyTaxType.PILOT).then({
        props: {
          fieldLabel: 'PILOT',
          helpText: 'Some states (like OH) calculate PILOT costs on AC opposed DC. Please provide the PILOT cost in $/year, if applicable.',
          units: [
            { value: TaxUnit.KWDC, label: 'kWdc', decimals: UnitDecimals.KWDC },
            { value: TaxUnit.KWAC, label: 'kWac', decimals: UnitDecimals.KWAC },
            { value: TaxUnit.YEAR, label: 'year', decimals: UnitDecimals.YEAR },
          ],
          unitField: 'propertyTaxUnit'
        }
      }),
      when('propertyTaxType', '=', PropertyTaxType.ANNUAL).then({
        props: {
          fieldLabel: 'Property Taxes',
          helpText: 'Enter the annual tax amount due for the property.',
          decimals: UnitDecimals.YEAR,
          label: 'per year'
        }
      })
    ],
    propertyTaxEscalator: [
      when('propertyTaxType', '=', PropertyTaxType.EXEMPT).then({ hide: true }),
      when('propertyTaxType', '=', null).then({ hide: true }),
      when('propertyTaxType', '=', PropertyTaxType.PILOT).then({
        props: {
          fieldLabel: 'PILOT Escalator'
        }
      }),
      when('propertyTaxType', '=', PropertyTaxType.ANNUAL).then({
        props: {
          fieldLabel: 'Property Tax Escalator'
        }
      })
    ],
    propertyTaxNotes: [
      when('propertyTaxType', '=', PropertyTaxType.EXEMPT).then({ hide: true }),
      when('propertyTaxType', '=', null).then({ hide: true })
    ],
    revenueAssumptionsFlag: when(isCommunitySolar).then({ show: true }),
    communitySolarSubscriptionDetails: when(isCommunitySolar).then({ show: true }),
    communitySolarRevenueDetails: when(isCommunitySolar).then({ show: true }),
    omPrice: when('omProposeFlag', '=', true).then({ show: true }),
    omEscalator: when('omProposeFlag', '=', true).then({ show: true }),
    storageOmFlag: when('hasStorage', '=', true).then({ show: true }),
    'storageExpenseAmount': when(showStorageExpenseFields).then({ show: true }),
    'storageExpenseEscalator': when(showStorageExpenseFields).then({ show: true }),
    'storageExpenseExpectedTerm': when(showStorageExpenseFields).then({ show: true }),
    'storageExpenseNotes': when(showStorageExpenseFields).then({ show: true }),
    'subscriptionExpenseAmount': when(showSubscriptionManagementFields).then({ show: true }),
    'subscriptionExpenseEscalator': when(showSubscriptionManagementFields).then({ show: true }),
    'subscriptionExpenseExpectedTerm': when(showSubscriptionManagementFields).then({ show: true }),
    'subscriptionExpenseNotes': when(isCommunitySolar).then({ show: true }),

    siteLeaseCost: when((p: Project) => p.interconnectionType === 'BEHIND_METER').then({ hide: true }),
    siteLeaseEscalator: when((p: Project) => p.interconnectionType === 'BEHIND_METER').then({ hide: true }),

  },
  subsections: {
    customerInformation: [
      when(isCustomerOwned).then({
        label: 'Customer information'
      }),
      when(isNotCustomerOwned).then({
        label: 'Offtaker information'
      }),
      when(isCommercialOrIndustrial).then({
        label: 'Customer information'
      }),
    ],
    customerContract: [
      when(isCustomerOwned).then({
        hide: true
      }),
      when(isNotCustomerOwned).then({
        label: 'Offtake contract'
      }),
      when(isCommercialOrIndustrial).then({
        label: 'Customer offtake contract'
      }),
    ],
    renewableEnergyCredits: when(isCustomerOwned).then({ hide: true }),
    otherRevenueStreams: when(isCustomerOwned).then({ hide: true }),
    subscriptionManagement: when('revenueSource', '=', RevenueSource.COMMUNITY_SOLAR_SUBS).then({ show: true }),
    projectRoles: [
      when('developmentStage', 'isNull').then({ hide: true }),
    ]
  },
  sections: {
    expenses: when(isCustomerOwned).then({ hide: true }),
    revenue: when(isCustomerOwned).then({
      label: 'Customer',
      description: 'Information about the end user'
    }),
  },
};

export default formConditions;