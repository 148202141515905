import { WorksheetField } from '../common/components/Field';
import { ProductionUnit } from '~/constants';
import { ProjectArrayDropdownOptions } from '~/constants';

export default function PricingEstimateArray() {
  return (
    <>
      <WorksheetField
        type='dropdown'
        schemaKey={'projectArrays.0.type'}
        fieldLabel='Array type'
        placeholder="Select an array type"
        options={ProjectArrayDropdownOptions}
      />
      <WorksheetField.Row isQuote>
        <div className="labelled-input">
          <WorksheetField 
            type='number'
            schemaKey={'projectArrays.0.sizeKwdc'}
            fieldLabel='Size in kWdc'
            placeholder='Enter value'
            label='kWdc'
          />
        </div>
        <div className='radio-selections'>
          <WorksheetField
            type="number"
            schemaKey={'projectArrays.0.yearOneProduction'}
            fieldLabel="Year one production"
            decimals={0}
            unitField="yearOneProductionUnit"
            placeholder="Enter production"
            units={[
              { value: ProductionUnit.KWH, label: 'kWh' },
              { value: ProductionUnit.KWHKW, label: 'kWh/kW' },
            ]}
            defaultUnit={ProductionUnit.KWHKW}
          />
        </div>
      </WorksheetField.Row>
    </>
  );
};  