import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import * as S from './styles';
import OwnershipDropdown from './OwnershipDropdown';
import { CompanyOwnershipDisplay, CompanyOwnershipDropdownOptions } from '~/constants';
import { useUpdateOwnership } from '~/mutations/admin/useUpdateOwnership';
import { useUpdateCompanyOwnership } from '~/mutations/admin/useUpdateCompanyOwnership';
import { usePageType, useProjectContext } from '~/hooks';
import { useAdminUsersQuery } from '~/requests/admin/useAdminUsersQuery';
import { ownershipKey, useOwnershipQuery } from '~/requests/ownership/useOwnershipQuery';
import Link from '../Link';
import Button from '../Button';
import AdminNotesModal from '~/features/admin/companies/AdminNotesModal';
import { User } from '~/types/user';

export default function OwnershipPicker() {
  const queryClient = useQueryClient();
  const pageType = usePageType();
  const { id } = useParams<{ id: string }>();
  const { project } = useProjectContext();
  const entityType = project?.portfolioId ? 'portfolio' : pageType;
  const entityId = project?.portfolioId ?? id;
  const typeDisplay = entityType.charAt(0).toUpperCase() + entityType.slice(1);

  const [openNotesModal, setOpenNotesModal] = useState(false);

  const { data: ownershipData } = useOwnershipQuery();
  const { data: users } = useAdminUsersQuery();
  const { mutateAsync: updateOwnership } = useUpdateOwnership();
  const { mutateAsync: updateCompanyOwnership } = useUpdateCompanyOwnership();

  const owner = ownershipData?.owner;
  const solarExperience = ownershipData?.solarExperience ?? '—';
  const thirdPartyExperience = ownershipData?.thirdPartyExperience ?? '—';

  const handleChangeOwner = async (
    e: React.SyntheticEvent<HTMLElement>, 
    value: string, 
    users: User[]  
  ) => {
    const userId = value as string;
    if (userId !== owner.id) {
      // Change to the new owner
      queryClient.setQueryData(ownershipKey(entityType, entityId), {
        ...ownershipData,
        owner: users?.find((user: User) => user.id === userId),
        ownerId: userId
      });
      try {
        await updateOwnership({
          entityType,
          entityId,
          userId 
        });
        toast.success('Successfully updated ownership data.', { duration: 5000 });
      } catch (error) {
        console.error('Error updating ownership:', error);
        toast.error('Failed to update ownership data. Please try again', { duration: 5000 });
        // Reset the owner
        queryClient.setQueryData(ownershipKey(entityType, entityId), {
          ...ownershipData,
          owner,
          ownerId: owner.id
        });
      } 
    }
  };

  const handleCompanyUpdate = async (
    key: string,
    value: string,
    e?: React.SyntheticEvent<HTMLElement> | string,
  ) => {
    queryClient.setQueryData(ownershipKey(entityType, entityId), {
      ...ownershipData,
     [key]: value,
    });
    try {
      await updateCompanyOwnership({ 
        id: ownershipData?.companyId, 
        key, 
        value: value as string
      });
      toast.success('Successfully updated company data.', { duration: 5000 });
      setOpenNotesModal(false);
    } catch (error) {
      console.error('Error updating company ownership:', error);
      toast.error('Failed to update company data. Please try again', { duration: 5000 });
      //Reset the values
      queryClient.setQueryData(ownershipKey(entityType, entityId), {
        ...ownershipData,
        [key]: value,
      });
    }
  };

  const handleSaveNotes = (notes: string) => {
    handleCompanyUpdate('notes', notes);
  };
  
  if (!owner || !users?.length) {
    return null;
  }

  return (
    <S.OwnershipPickerContainer>
      <OwnershipDropdown
        typeDisplay={typeDisplay}
        ownershipText={CompanyOwnershipDisplay.OWNER}
        defaultValue={owner.id}
        buttonText={owner.name}
        options={users?.map((user) => ({
          key: user?.id,
          text: user?.name,
          value: user?.id
        }))}
        onChange={(e, value) => handleChangeOwner(e, value, users)}
      />
      <OwnershipDropdown
        ownershipText={CompanyOwnershipDisplay.SOLAR_EXPERIENCE}
        defaultValue={solarExperience}
        buttonText={solarExperience}
        options={CompanyOwnershipDropdownOptions}
        onChange={(e, value) => handleCompanyUpdate('solarExperience', value, e)}
      />
      <OwnershipDropdown
        ownershipText={CompanyOwnershipDisplay.THIRD_PARTY_EXPERIENCE}
        defaultValue={thirdPartyExperience}
        buttonText={thirdPartyExperience}
        options={CompanyOwnershipDropdownOptions}
        onChange={(e, value) => handleCompanyUpdate('thirdPartyExperience', value, e)}
      />
      <Link 
        as={Button}
        underline
        bold
        style={{ fontSize: '0.875rem', fontStyle: 'italic' }}
        onClick={() => setOpenNotesModal(true)}
      >
        Admin notes
      </Link>
      {openNotesModal && 
        <AdminNotesModal
          company={ownershipData}
          closeModal={() => setOpenNotesModal(false)}
          onSave={handleSaveNotes}
        />
      }
    </S.OwnershipPickerContainer>
  );
}