import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import * as S from './styles';
import { Icon } from 'semantic-ui-react';
import { Button } from '~/components';
import * as DataroomApi from '~/api/dataroom';
import { useDataroomContext } from '../../providers/DataroomProvider';
import { usePageType } from '~/hooks';
import { useParams } from 'react-router-dom';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { useProjectList } from '~/requests/projects/useProjectList'; 
import { DealStates } from '~/constants';
import CustomDocumentTypeModal from '../../modals/CustomDocumentTypeModal'; 
import CopyProjectDocumentTypesModal from '../../modals/CopyProjectDocumentTypesModal';
import CopyProjectDropdown from './CopyProjectDropdown';
import { Project } from '~/types/project';

const downloadFileMutationFn = (id: string, fileType: 'all' | 'unapproved' | 'approved') => async () => {
  const currDate = new Date();
  const dateStr = currDate.toLocaleDateString('en-US', {year: 'numeric'}) +
    currDate.toLocaleDateString('en-US', {month: '2-digit'}) +
    currDate.toLocaleDateString('en-US', {day: '2-digit'});
  
  try {
    const downloadUrl = await toast.promise(
      DataroomApi.downloadFileBundle({ id, fileType, dateStr }) as Promise<string>, 
      {
        loading: 'Preparing zip for download...',
        success: `Preparation complete! Downloading zip of ${fileType} data room files now.`,
        error: 'Oops! There was a problem preparing your files for download. Please reload the page and try again in a few minutes.',
      }, { duration: 5000 }
    );

    if (downloadUrl) {
      window.document.location = downloadUrl;
    }
  } catch (err) {
    console.error(err);
  }
};

export default function DataroomActions() {
  const { 
    dataroom,
    editingDocumentTypes, 
    setEditingDocumentTypes,
    dealState, 
    isSimplifiedUI,
    dataroomCounts,
  } = useDataroomContext();

  const pageType = usePageType();
  const { id } = useParams<{ id: string }>();
  
  const { permissions } = usePermissions();
  const { data: projects, status: projectListStatus } = useProjectList();
  const [showCustomDocumentTypeModal, setShowCustomDocumentTypeModal] = useState(false); 
  const [projectToCopy, setProjectToCopy] = useState<any>(null); 

  const projectsLength = projects?.length ?? 0;
  const isDiligence = dealState >= DealStates.IN_DILIGENCE;

  const approved = dataroomCounts?.approved ?? 0;
  const total = dataroomCounts?.uploaded ?? 0;
  const unapproved = total - approved;
  
  const { mutate: downloadAllFiles, status: downloadAllFilesStatus } = useMutation(downloadFileMutationFn(dataroom?.id, 'all'));
  const { mutate: downloadApprovedFiles, status: downloadApprovedFilesStatus } = useMutation(downloadFileMutationFn(dataroom?.id, 'approved'));
  const { mutate: downloadUnapprovedFiles, status: downloadUnapprovedFilesStatus } = useMutation(downloadFileMutationFn(dataroom?.id, 'unapproved'));

  // Filter out the current project from the list of projects to copy from
  const copyProjectList = projects?.filter((p: Project) => {
    return pageType !== 'project' || p.id !== id;
  });

  // Reset editingDocumentTypes when switching to simplified UI
  useEffect(() => {
    setEditingDocumentTypes(false);
  }, [isSimplifiedUI]);
  
 return (
  <div>
    {editingDocumentTypes ? (
      <>
        <S.EditDocTypesActions> 
          <Button 
            primary
            onClick={() => setEditingDocumentTypes(false)}
          >
            <Icon name="arrow left" />
            Back to my doc list
          </Button>
          <Button 
            primary 
            outlined 
            onClick={() => setShowCustomDocumentTypeModal(true)}
          >
            I have a different doc I’d like to add
          </Button>
          <CopyProjectDropdown  
            projectsLength={projectsLength} 
            projectListStatus={projectListStatus} 
            copyProjectList={copyProjectList} 
          />
         </S.EditDocTypesActions> 
        </>
    ) : 
      (<S.UploadDocsActions fullWidth={isDiligence}>
        {permissions?.canUpdateDataroom && !isSimplifiedUI ?
          <Button 
            primary
            outlined
            onClick={() => setEditingDocumentTypes(true)}
          >
            <Icon name='pencil' style={{paddingRight: '1.5rem'}} />
            Edit doc list
          </Button>
        : null}
        {isDiligence ? (
          <>
            <Button
              outlined
              onClick={() => downloadUnapprovedFiles()}
              disabled={unapproved === 0 || downloadUnapprovedFilesStatus === 'loading'}
            >
              <Icon name='info circle' color='red' />
              Download unapproved
            </Button>
            <Button
              outlined
              onClick={() => downloadApprovedFiles()}
              disabled={approved === 0 || downloadApprovedFilesStatus === 'loading'}
            >
              <Icon name='checkmark box' color='green' />
              Download approved
            </Button>
          </>
        ) : null}
        <Button 
          onClick={() => downloadAllFiles()} 
          disabled={total === 0 || downloadAllFilesStatus === 'loading'}
        >
          <Icon name='arrow circle down' inverted />
          Download all
        </Button>
      </S.UploadDocsActions>
      )}
      {showCustomDocumentTypeModal && 
        <CustomDocumentTypeModal 
          closeModal={() => setShowCustomDocumentTypeModal(false)} 
        />
      }
      {projectToCopy && 
        <CopyProjectDocumentTypesModal 
          projectToCopy={projectToCopy} 
          closeModal={() => 
          setProjectToCopy(null)} 
        />
      }
    </div>
  );
};

