import styled from 'styled-components';

export const InputsContainer = styled.div`
  & > * {
    margin-bottom: var(--medium);
    width: 34.375rem;
  }
`;

export const FieldsContainer = styled.div`
  & > * {
    margin-bottom: var(--medium);
  }
`;


