import { ReactNode } from 'react';
import * as S from '../../../project/styles';
import SubmitButton from '../../../../components/SubmitButton';

export default function WorksheetForm({ children, isInner, isQuote }: { children: ReactNode, isInner?: true, isQuote?: boolean }) {
  return (
    <S.WorksheetForm isInner={isInner} isQuote={isQuote}>
      {children}
      {!isInner && <SubmitButton />}
    </S.WorksheetForm>
  );
}