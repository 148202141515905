import { InputType } from '~/schema';
import { WorksheetField } from '../../../worksheets/common/components/Field';
import WorksheetForm from '../../../worksheets/common/components/WorksheetForm';
import { ExpensesUnit, UnitDecimals } from '~/constants';
import { useProjectContext } from '~/hooks';

export default function OperationAndMaintenance() {
  const { conditions: { isFieldDisplaying } } = useProjectContext();

  return (
    <WorksheetForm>
      <div style={{ maxWidth: '26rem' }}>
        <WorksheetField
          type="radio"
          fieldLabel="Would you like to propose a PV O&M contract?"
          schemaKey='omProposeFlag'
          values={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
          inline
          stretched
        />
      </div>
      <WorksheetField
        fieldLabel="Proposed PV O&M cost"
        schemaKey="omPrice"
        type="number"
        numberType={InputType.DOLLARS}
        unitField="omPriceUnit"
        units={[
          {value: ExpensesUnit.KWDC, label: 'per kWdc', decimals: UnitDecimals.KWDC},
          {value: ExpensesUnit.YEAR, label: 'per year', decimals: UnitDecimals.YEAR},
        ]}
      />
      <WorksheetField
        fieldLabel="Proposed annual PV O&M escalator"
        schemaKey="omEscalator"
        type="number"
        numberType={InputType.PERCENT}
        decimals={UnitDecimals.PERCENT}
      />

      {/* Storage / O&M Expense */}
      <div style={{ maxWidth: '26rem' }}>
        <WorksheetField
          type="radio"
          fieldLabel="Do you have details on anticipated storage O&M expenses?"
          schemaKey='storageOmFlag'
          values={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
          inline
          stretched
        />
      </div>
      <WorksheetField
        fieldLabel="Storage O&M cost"
        type="number"
        schemaKey="storageExpenseAmount"
        numberType={InputType.DOLLARS}
        unitField="storageExpenseUnit"
        units={[
          {value: ExpensesUnit.KWDC, label: 'per kWdc', decimals: UnitDecimals.KWDC},
          {value: ExpensesUnit.YEAR, label: 'per year', decimals: UnitDecimals.YEAR},
        ]}
        defaultUnit={ExpensesUnit.KWDC}
      />
      {(isFieldDisplaying('storageExpenseEscalator') || isFieldDisplaying('storageExpenseExpectedTerm')) && (
        <WorksheetField.Row>
          <WorksheetField
            fieldLabel="Storage O&M escalator"
            schemaKey="storageExpenseEscalator"
            type="number"
            numberType={InputType.PERCENT}
            decimals={2}
          />
          <WorksheetField
            fieldLabel="Storage O&M term length"
            schemaKey="storageExpenseExpectedTerm"
            type="number"
            decimals={0}
            label="years"
            placeholder="Enter term length"
          />
        </WorksheetField.Row>
      )}
      <WorksheetField
        fieldLabel="Storage O&M details"
        placeholder="Please use this field to describe the project’s ongoing storage and / or O&M costs."
        schemaKey="storageExpenseNotes"
        type="textarea"
      />
    </WorksheetForm>
  );
}