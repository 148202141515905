import { formatDollars } from '~/utils/formatNumber';
import { Project } from '~/types/project';
import { UnitDecimals, PricingEstimateTypeUnitDisplay } from '~/constants';
import { ProjectSavingsAnalysisData } from '~/types/project/ProjectSavingsAnalysis';

const calculateCurrentPricing = (
  project: Project,
  currentPricing: number | undefined, 
  savingsType: ProjectSavingsAnalysisData | undefined,
): string | undefined => {
  
  const unit = savingsType?.unit as keyof typeof UnitDecimals;
  const hasResult = Boolean(currentPricing && project?.autopricingResult && Object.keys(project.autopricingResult).length > 0);

  return hasResult
    ? `${formatDollars(currentPricing, { dec: UnitDecimals[unit] })} / ${PricingEstimateTypeUnitDisplay[unit]}` 
    : '—';
};

export default function currentPricing( project: Project) {
  const { ppaPricing, leasePricing, ppaSavings, leaseSavings } = project?.savingsAnalysis || {};
  return {
    ppaCurrentPricing: calculateCurrentPricing(project, ppaPricing, ppaSavings),
    leaseCurrentPricing: calculateCurrentPricing(project, leasePricing, leaseSavings)
  };
};