import { InputType } from '~/schema';
import { WorksheetField } from '../../../../worksheets/common/components/Field';
import { Unit, UnitDecimals } from '~/constants';

export default function UpfrontIncentives() {
  //TODO state specific incentives commented out below, re-add when ready
  return (
    <>
      {/* <WorksheetField
        type="number"
        fieldLabel="State-specific Upfront Incentives"
        schemaKey="stateSpecificUpfrontIncentives"
        numberType={InputType.DOLLARS}
        unitField="stateSpecificUpfrontIncentiveUnit"
        units={[
          {value: Unit.WDC, label: 'per Wdc', decimals: UnitDecimals.WDC},
          {value: Unit.TOTAL, label: 'total', decimals: UnitDecimals.TOTAL},
        ]}
      />
      <WorksheetField 
        type="textarea"
        schemaKey="stateSpecificUpfrontIncentiveNotes"
        placeholder="Please use this space to describe the state-specific incentives captured by this project."
        rows="6"
        fieldLabel='State-specific Upfront Incentives – Notes'
        optional
      /> */}
      <WorksheetField
        type="number"
        fieldLabel="Other upfront incentives"
        schemaKey="upfrontIncentives"
        numberType={InputType.DOLLARS}
        unitField="upfrontIncentiveUnit"
        units={[
          {value: Unit.WDC, label: 'per Wdc', decimals: UnitDecimals.WDC},
          {value: Unit.TOTAL, label: 'total', decimals: UnitDecimals.TOTAL},
        ]}
      />
      <WorksheetField 
        type="textarea"
        schemaKey="upfrontIncentiveNotes"
        placeholder="Please use this space to describe the upfront incentive (e.g. USDA grant, utility incentive, etc.)"
        rows="6"
        fieldLabel='Other upfront incentives — Notes'
        optional
      />
    </>
  );
}