import { ProductionUnit, RevenueUnit, UnitDecimals } from '~/constants';
import { useProjectContext } from '~/hooks';
import { WorksheetField } from '~/features/projectWizard/worksheets/common/components/Field';
import { InputType } from '~/schema';
import { Button } from '~/components';

const defaultAdditionalRevenue = {
  name: '',
  amount: null,
  unit: ProductionUnit.KWH,
  escalator: null,
  expectedTerm: null,
  notes: '',
};

export default function PricingEstimateRevenueStream () {
  const { project, updateProjectValue } = useProjectContext();

  const add = () => {
    updateProjectValue('additionalRevenues', [defaultAdditionalRevenue]);
  };

  const remove = () => {
    updateProjectValue('additionalRevenues', []);
  };

  return (
    !project?.additionalRevenues?.length ? (
      <>
        <p style={{ marginBottom: 'var(--medium)' }}>
          If there is an additional revenue stream you would like to add,{' '}
          <Button 
            variant="link" 
            onClick={add}>
            click here
          </Button>
          {' '}
          to add it. 
        </p> 
      </>
    ) : project?.additionalRevenues?.map((revenue: any) => (
      <>
        <WorksheetField 
          schemaKey={'additionalRevenues.0.name'}
          fieldLabel={'Additional revenue stream - Name'}
          placeholder="Enter name"
        />
        <WorksheetField
          type="number"
          schemaKey={'additionalRevenues.0.amount'}
          fieldLabel="Expected annual amount"
          numberType={InputType.DOLLARS}
          unitField="unit"
          placeholder={revenue.unit === RevenueUnit.KWH ? '0.0000' : '0'}
          units={[
            {value: RevenueUnit.KWH, label: 'per kWh', decimals: UnitDecimals.KWH},
            {value: RevenueUnit.YEAR, label: 'per year', decimals: UnitDecimals.YEAR},
          ]}
        />
        <WorksheetField.Row isQuote>
          <div className="labelled-input">
            <WorksheetField
              type="number"
              schemaKey={'additionalRevenues.0.escalator'}
              fieldLabel="Expected annual escalator"
              numberType={InputType.PERCENT}
              decimals={2}
              placeholder="0.00"
            />
          </div>
          <div className="labelled-input">
            <WorksheetField
              type="number"
              schemaKey={'additionalRevenues.0.expectedTerm'}
              fieldLabel="Expected term"
              placeholder="0"
              decimals={0}
              label="years"
            />
          </div>
        </WorksheetField.Row>
        <Button 
          variant='link'
          onClick={() => remove()} 
          style={{ textAlign: 'left', marginBottom: 'var(--medium)' }}>
          Remove this revenue stream
        </Button>
      </>
    ))
);
}