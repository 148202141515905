import Link from '~/components/Link';
import { useProjectContext } from '~/hooks';
import StorageForm from './StorageForm';
import WorksheetForm from '../../../../worksheets/common/components/WorksheetForm';
import { ProjectStorage } from '~/types/project';

export default function Storage() {
  const { project, updateProjectValue } = useProjectContext();
  const storageList = project?.storage ?? [];

  const addRow = () => {
    updateProjectValue('', {
      storage: [...storageList, { order: storageList.length }],
      hasStorage: true
    }, { patch: true });
  };

  const removeRow = (storage: ProjectStorage) => {
    const newStorage = storageList
      .filter((row) => row.order !== storage.order)
      .map((row, index) => ({ ...row, order: index }));

    updateProjectValue('', {
      storage: newStorage,
      hasStorage: Boolean(newStorage.length)
    }, { patch: true });
  };

  if (!storageList?.length) {
    return (
      <p>
        This project currently does not have any storage defined.{' '}
        <Link as="button" underline onClick={addRow}>Click here</Link>{' '}
        to create your storage solution.
      </p>
    );
  }

  return (
    <WorksheetForm>
      {storageList.map((storage) => (
        <div key={storage.id}>
          <StorageForm storage={storage} addRow={addRow} removeRow={removeRow} />
        </div>
      ))}
    </WorksheetForm>
  );
}