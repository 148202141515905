import { nrsFeeWithUnit } from '~/utils/calculators';
import { display } from '../../common';
import { formatNumber } from '~/utils/formatNumber';
import { PricingType, UnitDecimals } from '~/constants';
import { Project } from '~/types/project';

export default function conductorFee(project: Project, projectCosts: any) {
  const feePercent = nrsFeeWithUnit(project)?.fee;
  const percentLabel = feePercent !== undefined 
    ? `${feePercent}% of ${project?.pricingType === PricingType.ITC ? 'ITC value' : 'budget'}` 
    : null;

  if (!projectCosts?.bid && project?.pricingType === PricingType.ACQUISITION) {
    return `${percentLabel} — To be calculated based on the sum of your bid, Interconnection costs, and Other ITC-eligible costs in the project budget`;
  } else if (feePercent && !projectCosts?.overall?.nrsFee && project?.pricingType === PricingType.ITC) {
    return `${percentLabel} - To be calculated based on the value of your bid`;
  } else if (feePercent && !projectCosts?.overall?.nrsFee) {
    return `${percentLabel} - Amount TBD based on bid`;
  } else if (feePercent) {
    return `${percentLabel} — $${formatNumber(projectCosts?.overall?.nrsFee, UnitDecimals.TOTAL)}`;
  } else {
    return display(undefined);
  }
}