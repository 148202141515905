import autopricingResult from './result';
import autopricingByUnit from './unit';
import solarContract from './solarContract';
import currentPricing from './currentPricing';
import netSavingsValueRange from './netSavingsValueRange';
import netSavingsPercentageRange from './netSavingsPercentageRange';
import { autopricingConditions } from './conditions';

export default function calculateAutopricingValues(project: any, projectState: any) {
  return {
    //range of values for estimate table and solar contract results
    autopricingResult: autopricingResult(project, projectState),
    
    //returns one row of autopricing result object by unit
    autopricingByUnit: autopricingByUnit(project),
    
    //format based on avoided cost of solar or year one savings
    currentPricing: currentPricing(project),

    //solar contract values are actually ppaResult and leaseResult from autopricingResult
    //but since the values are calculated on the backend and stored in the db
    //this provides formatting based on availability of currentPricing value
    solarContract: solarContract(project, projectState),

    //current pricing less autopricing high/low values
    netSavingsValueRange: netSavingsValueRange(project),

    //percentage of savings based on current pricing and kwh
    netSavingsPercentageRange: netSavingsPercentageRange(project),

    //boolean conditions for autopricing
    conditions: autopricingConditions(project)
  };  
}  