import * as S from '../../../../components/GridComponent';
import styled from 'styled-components';
import logo from '../../../../assets/conductor-avatar-transparent.png';
import { ProjectAutopricing } from '~/constants';
import { Project } from '~/types/project';

const EstimateHeader = styled.p`
  color: var(--color-black);
  font-weight: 700;
  font-style: italic;
  margin: 0;

  flex-shrink: 0;
  align-self: center;
`;

interface PricingEstimateProps {
  project: Project;
  projectAutopricing: any
}

export default function PricingEstimate({ 
  project,
  projectAutopricing 
}: PricingEstimateProps) {

  const autopricing = ProjectAutopricing[project?.pricingType]
    ?.filter(({ valueKey }: { valueKey: string}) => {
    return projectAutopricing?.autopricingResult?.[valueKey] !== undefined;
  });

  return (
    <S.SubGrid columnValues='10rem 1fr'>
      <div style={{ display: 'flex', gap: 'var(--2x-small)'}}>
        <img src={logo} style={{ width: '3.5rem', height: '3.5rem' }} alt='' />
        <EstimateHeader>Conductor Solar estimated project pricing</EstimateHeader>
      </div>
      <div className='line'></div>
      {projectAutopricing?.conditions?.hasPricingEstimate ? (
        autopricing?.map(({ label, valueKey }: { label: string, valueKey: string }) => (
          <S.GridTable gridColumn='1 / 3' columnValues='1fr 1fr' lineHeight="1.5rem" key={`${project?.pricingType}-${label}`}>
            <S.GridLabelEl black>{label}</S.GridLabelEl>
            <S.GridTableEl textAlign='right 'bold>{projectAutopricing?.autopricingResult?.[valueKey]}</S.GridTableEl>
          </S.GridTable>
        ))
      ) : (
        //empty state
        <S.GridTable gridColumn='1 / 3' columnValues='1fr 1fr' lineHeight="1.5rem">
          <S.GridLabelEl black>
            EPC cost + dev fee
          </S.GridLabelEl> 
          <S.GridTableEl textAlign='right' bold style={{ fontStyle: 'italic'}}>
            Awaiting estimate
          </S.GridTableEl>
          <S.GridLabelEl bold>
            $ per unit
          </S.GridLabelEl> 
          <S.GridTableEl textAlign='right' bold style={{ fontStyle: 'italic'}}>
            Awaiting estimate
          </S.GridTableEl>
        </S.GridTable>
      )}
  </S.SubGrid>
  );
};