import ProjectSummaryData from './components/ProjectSummaryData';
import { useProjectContext } from '~/hooks/providers/ProjectContextProvider';
import * as S from './styles';
import DealSummary from './components/DealSummary';
import { DealStates } from '~/constants';
import ErrorDialog from './components/ErrorDialog';
import InvestorBidArea from './components/InvestorBidArea';
import { useEffect } from 'react';
import { useTutorials } from '~/hooks/providers/TutorialProvider';
import AdminProjectInvestorInvites from '../admin/AdminProjectInvestorInvites';
import { usePermissions } from '~/requests/permissions/usePermissions';
import OwnershipPicker from '~/components/OwnershipPicker';
import Timestamp from '~/components/Timestamp';
import useAnalyticsPageView from '~/hooks/analytics/useAnalyticsPageView';

export default function ProjectSummary() {
  const { 
    project, 
    projectState, 
    projectDisplayValues, 
    projectState: { dealState }, 
    projectErrors,
    projectAutopricing 
  } = useProjectContext();
  const { permissions } = usePermissions();
  const isInvestor = !permissions.isAdmin && permissions.hasProjectInvestorAccess;
  const showDealSummary = permissions.hasProjectDeveloperAccess && dealState !== undefined && dealState >= DealStates.IN_REVIEW && dealState < DealStates.IN_DILIGENCE;
  const showInvestorBidArea = isInvestor && dealState !== undefined && dealState >= DealStates.AWAITING_BID && dealState < DealStates.DECLINED;
  const { setTutorialContext } = useTutorials() as any;

  // Analytics
  useAnalyticsPageView('/review', 'Review');

  useEffect(() => {
    setTutorialContext({
      page: 'projectSummary',
      data: {
        permissions,
        projectState,
        dealState      }
    });
  }, [permissions, projectState, dealState]);

  return (
    <S.Container>
      <S.MetadataHeader>
        {permissions.isAdmin && <OwnershipPicker />}
        <div style={{ marginLeft: 'auto' }}>
          <Timestamp project={project} />
        </div>
      </S.MetadataHeader>
      <ErrorDialog />
      {permissions.isAdmin && <AdminProjectInvestorInvites />}
      {showInvestorBidArea && (
        <div style={{ width: '100%', maxWidth: '39.5rem' }}>
          <InvestorBidArea />
        </div>
      )}
      {showDealSummary && <DealSummary />}
      <ProjectSummaryData 
        project={project} 
        values={projectDisplayValues} 
        projectState={projectState} 
        projectErrors={projectErrors} 
        projectAutopricing={projectAutopricing} 
      />
    </S.Container>  
  );
};