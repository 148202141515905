import styled from 'styled-components';
import { colors } from '~/theme';
import { usePageType, useProjectContext } from '~/hooks';
import { useAdminNotes } from '~/requests/admin/adminNotes';
import { Form, TextArea, Loader } from 'semantic-ui-react';
import { useUpdateAdminNotes } from '~/mutations/admin/adminNotes';
import React, { useEffect, useRef } from 'react';
import { format } from 'date-fns';
import { useQueryClient } from 'react-query';
import { getAdminNotesKey } from '~/requests/admin/adminNotes';
import { FormContextProvider, useForm } from '~/hooks/providers/FormContextProvider';
import { FormSchemaAwareWidget } from '../form';

interface AdminNotesState {
  notes: string;
}

const Container = styled.div`
  &&& {
    position: relative;
    max-width: 37.5rem;

    textarea {
      color: var(--color-charcoal);
    }
  }
`;

const LoadingIndicator = styled(Loader)`
  position: absolute;
  inset: 0;
  z-index: 100;
`;

interface AdminNotesProps {
  preferencesOnly?: boolean;  
}

function AdminNotesImpl({ preferencesOnly }: AdminNotesProps) {
  const queryClient = useQueryClient();
  const pageType = usePageType();
  const { formState } = useForm<AdminNotesState>();
  const { project } = useProjectContext();
  const { mutate: updateAdminNotes } = useUpdateAdminNotes() as any;
  const { data: adminData, status } = useAdminNotes(project?.id, {
    staleTime: Infinity,
    refetchOnMount: true,
    refetchOnWindowFocus: false
  }) as any;

  const handleChange = () => {
    queryClient.setQueryData(getAdminNotesKey(project?.id), { ...project, notes: formState.notes, updatedAt: new Date().toISOString() });
    
    updateAdminNotes({ 
      projectId: project?.id, 
      notes: formState.notes 
    });
  };

  // Auto-save admin notes
  const notesRef = useRef(formState.notes);
  const autoSaveNotesTimeoutRef = useRef();

  useEffect(() => {
    // Only autosave if a value actually changed
    if (formState.notes !== notesRef.current) {

      // Clear any existing timeout so tons of saves don't happen for each key stroke
      if (autoSaveNotesTimeoutRef.current) {
        clearTimeout(autoSaveNotesTimeoutRef.current);
      }

      // Set a timeout to call the execute function after 3 seconds
      autoSaveNotesTimeoutRef.current = setTimeout(handleChange, 3000) as any;

      // Update the formState ref
      notesRef.current = formState.notes;
    }
  }, [formState]);

  const isLoading = status === 'loading';

  if (pageType !== 'project') {
    return null;
  }

  return (
    <Form style={{ margin: preferencesOnly ? '0' : 'var(--large) 0' }}>
      <Container>
        <FormSchemaAwareWidget
          schemaKey='notes'
          as={TextArea}
          fieldLabel="Admin notes"
          style ={{ 
            maxWidth: '37.5rem',
            background: isLoading ? colors.gray : undefined,
          }}
          />
        {isLoading && <LoadingIndicator active size="large" />}
        {adminData?.updatedAt && <em>Last updated on {format(new Date(adminData.updatedAt), 'MM/dd/yyyy \'at\' hh:mm:ss a')}</em>}
      </Container>
    </Form>
  );
}

const AdminNotesForm: React.FC<AdminNotesProps> = ({ preferencesOnly }) => {
  const { project } = useProjectContext();
  const { data: adminData, status } = useAdminNotes(project?.id);

  const isLoading = status === 'loading';

  if (isLoading) {
    return <LoadingIndicator active size="large" />;
  }

  const defaults: AdminNotesState = {
    notes: adminData?.notes || ''
  };

  return (
    <FormContextProvider defaults={defaults}>
      <AdminNotesImpl preferencesOnly={preferencesOnly} />
    </FormContextProvider>
  );
};

export default AdminNotesForm;