import { camelCase, startCase } from 'lodash';
import { Label } from 'semantic-ui-react';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { format } from 'date-fns';
import { ProjectWorkflow, ProjectWorkflowDisplay } from '~/constants';

interface ProjectStatusBadgeProps {
  statusDisplay: string;
  isArchived?: boolean;
  bidDeadline?: string;
}

export default function ProjectStatusBadge({ 
  statusDisplay, 
  bidDeadline, 
  isArchived 
}: ProjectStatusBadgeProps) {
  const { permissions } = usePermissions();
  const display = isArchived ? ProjectWorkflowDisplay[ProjectWorkflow.ARCHIVED] : ProjectWorkflowDisplay[statusDisplay];  

  if (isArchived) {
    return <Label color="black">{display}</Label>;
  }

  switch (statusDisplay) {
    case ProjectWorkflow.MARKET_QUOTE:
    case ProjectWorkflow.NOT_SUBMITTED:
      return <Label color="grey">{display}</Label>;

    case ProjectWorkflow.BIDDING:
      if (!permissions.isAdmin && bidDeadline) {
        return <Label color="olive">{`${display} - bids due ${format(new Date(bidDeadline), 'M/dd')}`}</Label>;
      }
      return <Label color="olive">{display}</Label>;

    case ProjectWorkflow.BID_REVIEW:
      return <Label color="olive">{display}</Label>;

    case ProjectWorkflow.IN_DILIGENCE:
      return (
        <Label style={{ background: 'var(--color-light-blue)', color: 'var(--color-pure-black)' }}>
          {display}
        </Label>
      );

    case ProjectWorkflow.EXECUTED:
    case ProjectWorkflow.COMPLETED:
      return (
        <Label style={{ background: 'var(--color-primary-light)' }}>
          {display}
        </Label>
      );

    case ProjectWorkflow.IN_REVIEW:
      return <Label color="yellow">{display}</Label>;

    default:
      return <Label color="black">{startCase(camelCase(statusDisplay))}</Label>;
  }
}