import { useEffect, useRef, useState } from 'react';
import { Checkbox, Header } from 'semantic-ui-react';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { KEY_DETAILS_GRID } from './KeyDetails';
import { withForm } from '~/utils/withForm';
import { useForm, useProjectContext } from '~/hooks';
import { useMutation } from 'react-query';
import { setCompletionState } from '~/api/projects';
import toast from 'react-hot-toast';

interface ProjectCompletionStatesFormState {
  completionState: 'NONE' | 'DOCUMENTS_SIGNED' | 'CONSTRUCTION_COMPLETE' | null;
}

const ProjectCompletionStates = withForm({ defaults: {} }, () => {
  const { permissions } = usePermissions();
  const [paddingLeft, setPaddingLeft] = useState('0');
  const { project, updateProjectValue } = useProjectContext();
  const { formState, updateFormValue } = useForm<ProjectCompletionStatesFormState>();
  const completionStateRef = useRef<string | null>(project?.completionState?.code);

  const { mutateAsync: updateProjectCompletionState, status } = useMutation((code: 'NONE' | 'DOCUMENTS_SIGNED' | 'CONSTRUCTION_COMPLETE') => {
    return setCompletionState(project.id, code)
      .then(newProject => {
        updateProjectValue('', newProject, { doSave: false });
        completionStateRef.current = newProject?.completionState?.code;
      })
      .catch(err => {
        console.error(err);
        toast.error('Failed to update deal status');
        updateFormValue('completionState', completionStateRef.current);
      });
  });

  useEffect(() => {
    if (project?.completionState?.code) {
      updateFormValue('completionState', project.completionState.code);
    }
    completionStateRef.current = project?.completionState?.code;
  }, [project?.completionState?.code]);

  useEffect(() => {
    // Get key details grid.
    // If we can find the first element in the grid, offset the padding left of the inner componentry
    // so that it aligns with the key detail's second column.
    // If not found, there simply won't be padding. This ensures a graceful fallback for this component.
    const keyDetailsDiv = document.querySelector(`#${KEY_DETAILS_GRID} > div:first-child`) as HTMLDivElement;
    if (keyDetailsDiv?.offsetWidth && !isNaN(keyDetailsDiv.offsetWidth)) {
      setPaddingLeft(`${keyDetailsDiv.offsetWidth / 16}rem`);
    }
  }, []);

  // If the user doesn't have permission to set the project completion state, don't render the component.
  if (!permissions.canSetProjectCompletionState) {
    return null;
  }

  return (
    <>
      <Header as ='h2'>Deal status</Header>
      <div style={{ paddingLeft, display: 'flex', flexDirection: 'column' }}>
        <Checkbox 
          label="Definitive documents signed" 
          checked={!!formState.completionState}
          onChange={() => {
            if (formState.completionState) {
              updateFormValue('completionState', null);
              updateProjectCompletionState('NONE');
            } else {
              updateFormValue('completionState', 'DOCUMENTS_SIGNED');
              updateProjectCompletionState('DOCUMENTS_SIGNED');
            }
          }}
          disabled={status === 'loading'}
        />
        <Checkbox 
          label="Construction complete" 
          style={{ margin: 'var(--small) 0' }} 
          checked={formState.completionState === 'CONSTRUCTION_COMPLETE'}
          onChange={() => {
            if (formState.completionState === 'CONSTRUCTION_COMPLETE') {
              updateFormValue('completionState', 'DOCUMENTS_SIGNED');
              updateProjectCompletionState('DOCUMENTS_SIGNED');
            } else {
              updateFormValue('completionState', 'CONSTRUCTION_COMPLETE');
              updateProjectCompletionState('CONSTRUCTION_COMPLETE');
            }
          }}
          disabled={status === 'loading' || !formState.completionState}
        />
      </div>
    </>
  );
});

export default ProjectCompletionStates;