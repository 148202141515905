import { useProjectList } from '~/requests/projects/useProjectList';
import Table from './Table';
import { useEffect, useReducer, useState } from 'react';
import { DefaultSortOrders, projectListReducer } from '~/utils/projectListReducer';
import { ProjectDashboardFilters } from '~/constants';
import { formatNumber } from '~/utils';
import styled from 'styled-components';

const TableRow = styled(Table.Row)`
  ${props => props.selected ? `
    background-color: var(--color-primary-light);
  `: `
    &:focus-visible,
    &:hover {
      cursor: pointer;
      background-color: var(--color-grey);
    }
  `}
`;

export default function ProjectTableSelection({
  onSelect
}: {
  onSelect?: (project: any) => void;
}) {
  let { data: projects } = useProjectList();
  const [selectedProject, setSelectedProject] = useState(null);

  const [sortColumn, setSortColumn] = useState('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const [state, dispatch] = useReducer(projectListReducer, {
    column: sortColumn,
    data: projects,
    direction: sortDirection,
    dashboardFilter: ProjectDashboardFilters.ALL
  });

  // Initialize table data when projects are fetched
  useEffect(() => {
    projects = projects?.map((project: any) => {
      const locationSplit = project.location?.split(', ');
      if (locationSplit?.length > 1) {
        return {
          ...project,
          location: locationSplit[1]
        };
      } else {
        return project;
      }
    });

    dispatch({
      type: 'INIT',
      data: projects
    });
  }, [projects]);

  // Functions for getting/updating sort
  const getSort = (column: string) => {
    const sort = state.column === column ? state.direction : undefined;
    if (sort === 'asc') return 'ascending';
    if (sort === 'desc') return 'descending';
    return sort;
  };

  const updateSort = (column: string) => {
    const newSortDirection = sortColumn === column ? (sortDirection === 'asc' ? 'desc' : 'asc') : (DefaultSortOrders[column] ?? 'asc');
    setSortColumn(column);
    setSortDirection(newSortDirection);
    return dispatch({ type: 'CHANGE_SORT', column });
  };

  const handleSelect = (project: any) => {
    setSelectedProject(project);
    onSelect?.(project);
  };

  if (!projects) {
    return null;
  }

  return (
    <Table sortable>
      <Table.Header style={{ position: 'sticky', inset: 0 }}>
        <Table.Row>
          <Table.HeaderCell 
            singleLine
            sorted={getSort('name')}
            onClick={() => updateSort('name')}
          >
            Project Name
          </Table.HeaderCell>
          <Table.HeaderCell 
            singleLine
            sorted={getSort('size')}
            onClick={() => updateSort('size')}
          >
            Size (kWdc)
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={getSort('location')}
            onClick={() => updateSort('location')}
          >
            Location
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {state.data.map((project: any) => (
          <TableRow
            selected={selectedProject === project} 
            tabIndex={0} 
            key={project.id} 
            onClick={() => handleSelect(project)}
            onKeyPress={(e: KeyboardEvent) => e.key === 'Enter' && handleSelect(project)}
          >
            <Table.Cell singleLine={false}>{project.name}</Table.Cell>
            <Table.Cell>{formatNumber(project.size)}</Table.Cell>
            <Table.Cell singleLine={false}>{project.location}</Table.Cell>
          </TableRow>
        ))}
      </Table.Body>
    </Table>
  );
}