import autopricingResult from './result';
import { Project } from '~/types/project';

const calculateSolarContract = (
  result: string | undefined,
  currentPricing: number | undefined,
) => currentPricing ? result : '—';

export default function solarContract(project: Project, projectState: any) {
  const { ppaResult, leaseResult } = autopricingResult(project, projectState);
  const { ppaPricing, leasePricing } = project?.savingsAnalysis || {};

  return {
    ppaSolarContract: calculateSolarContract(ppaResult, ppaPricing),
    leaseSolarContract: calculateSolarContract(leaseResult, leasePricing)
  };
}