type DeploymentType = 'CI' | 'COMMUNITY_SOLAR' | 'UTILITY';

export const DeploymentTypes = {
  CI: 'CI' as DeploymentType,
  COMMUNITY_SOLAR: 'COMMUNITY_SOLAR' as DeploymentType,
  UTILITY: 'UTILITY' as DeploymentType
};

export const DeploymentTypeDisplay = {
  [DeploymentTypes.CI]: 'Commercial or industrial',
  [DeploymentTypes.COMMUNITY_SOLAR]: 'Community solar',
  [DeploymentTypes.UTILITY]: 'Utility'
};

export const DeploymentTypeRadioOptions = [
  {
    value: DeploymentTypes.CI,
    label: DeploymentTypeDisplay[DeploymentTypes.CI]
  },
  {
    value: DeploymentTypes.COMMUNITY_SOLAR,
    label: DeploymentTypeDisplay[DeploymentTypes.COMMUNITY_SOLAR]
  },
  {
    value: DeploymentTypes.UTILITY,
    label: DeploymentTypeDisplay[DeploymentTypes.UTILITY]
  }
];