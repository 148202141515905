import { Project, AutopricingResultDataItem, PricingTypes } from '~/types/project';
import { PricingEstimateType } from '~/constants';

const getAutopricingResultByUnit = (project: Project, unit: string) => {
  
  if (!project?.autopricingResult || !project?.pricingType) {
    return null;
  }
  
  const results = project?.autopricingResult[project?.pricingType as PricingTypes];
  return results?.find((row: AutopricingResultDataItem) => row.unit === unit);
};

export default function autopricingByUnit(project: Project) {
  return {
    autopricingKwh: getAutopricingResultByUnit(project, PricingEstimateType.KWH) as AutopricingResultDataItem,
    autopricingLease: getAutopricingResultByUnit(project, PricingEstimateType.YEAR_ONE_PAYMENT) as AutopricingResultDataItem,
    autopricingEpcTotal: getAutopricingResultByUnit(project, PricingEstimateType.TOTAL) as AutopricingResultDataItem,
    autopricingWdc: getAutopricingResultByUnit(project, PricingEstimateType.WDC) as AutopricingResultDataItem, 
  };
};