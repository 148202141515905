import { useProjectContext } from '~/hooks';
import WorksheetForm from '~/features/projectWizard/worksheets/common/components/WorksheetForm';
import Totals from './Totals';
import ProjectArraySection from './ProjectArray';
import { useDerivedRECFields } from '~/features/projectWizard/derived';

export default function PV() {
  const { project } = useProjectContext();

  // Update REC data based on sizeKwac changes in projectArrays
  useDerivedRECFields();

  return (
    <WorksheetForm>
      <Totals />
      <div>
        {project.projectArrays?.map((projectArray) => (
          <ProjectArraySection key={projectArray.id} projectArray={projectArray}/>
        ))}
      </div>
    </WorksheetForm>
  );
}