import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Form, Icon } from 'semantic-ui-react';
import { useDuplicateProject } from '~/mutations/project/useDuplicateProject';
import { Button, Modal } from '~/components';
import { FormSchemaAwareWidget } from '~/components/form';
import Link from '~/components/Link';
import { useForm } from '~/hooks';
import { ModalFormProps } from './types';
import ProjectTableSelection from '~/components/ProjectTableSelection';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ProjectTableContainer = styled.div`
  height: 100%;
  max-height: 18rem;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  position: relative;
  clip-path: inset(0 0 0 -20px);
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar {
    width: 12px;  /* Adjust to match typical scrollbar width */
  }
    
  &::-webkit-scrollbar-track {
    background: transparent; /* Hide scrollbar track */
  }

  &::-webkit-scrollbar-thumb {
    background: transparent; /* Hide scrollbar thumb */
  }
`;

const ProjectTableScrollIndicator = styled.div<{ atBottom: boolean }>`
  position: absolute;
  bottom: -1.5rem;
  background: var(--color-off-white);
  left: 0;
  right: 0;
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;

  button {
    all: unset;
    transition: 0.2s ease-in-out;
    ${props => !props.atBottom && `
      &:hover {
        cursor: pointer;
        transform: translateY(0.1rem);
        scale: 1.05;
      }
      &:focus-visible {
        outline: 2px solid var(--color-secondary);
      }
    `}
  }

  // Apply a box-shadow that only shows on top and not bottom
  &::before {
    content: '';
    position: absolute;
    top: -1rem;
    left: 0;
    right: 0;
    height: 1rem;
    background: linear-gradient(0deg, var(--color-off-white), transparent);
  }

  ${props => props.atBottom && `
    // Animate the indicator away when at the bottom
    // and push it down when the animation is done
    animation: fadeOut 0.2s forwards;
    transition-delay: 0.2s;
    transform: translateY(0.5rem);

    &::before {
      transition-delay: 0.2s;
      transform: translateY(1rem);
    }
  `}

  ${props => !props.atBottom && `
    // Animate the indicator back in when not at the bottom
    // and then display block when the animation is done
    animation: fadeIn 0.2s forwards;
  `}
`;

interface DuplicateProjectFormState {
  name: string;
}

export default function DuplicateProjectForm ({ 
  modalRef, 
  toggleFormType,
  portfolioId, 
  closeModal 
}: ModalFormProps) {
  const tableRef = useRef<HTMLDivElement>(null);
  const [atBottom, setAtBottom] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const eventListenersAttachedRef = useRef(false);
  const history = useHistory();

  const { formState } = useForm<DuplicateProjectFormState>();

  const [duplicateProjectId, setDuplicateProjectId] = useState('');

  // Determine if form has an error
  const hasError = !formState.name || !duplicateProjectId;

  useEffect(() => {
    const table = tableRef.current as HTMLDivElement;
    setIsScrollable(table.scrollHeight > table.clientHeight);
  }, []);

  useEffect(() => {
    const eventListenersAttached = eventListenersAttachedRef.current;
    const modal = modalRef?.current?.node?.querySelector('.ReactModal__Content');
    const table = tableRef.current;

    const forwardScrollEvent = (event: any) => {
      if (table && !(event.target === table || table.contains(event.target))) {
        const isAtBottom = table.scrollHeight - table.scrollTop === table.clientHeight;
        const isAtTop = table.scrollTop === 0;
        setAtBottom(isAtBottom);
        if ((isAtTop && event.deltaY < 0) || (isAtBottom && event.deltaY > 0)) {
          // Allow default scroll behavior if scrolling down and already at the bottom or scrolling up and already at the top
          return; 
        }

        event.preventDefault();

        // Adjust the scrollTop of the table based on the scroll delta
        table.scrollTop += event.deltaY; // For wheel event
      }
    };

    const handleTableScroll = () => {
      if (!table) return;
      const isAtBottom = table.scrollHeight - table.scrollTop === table.clientHeight;
      setAtBottom(isAtBottom);
    };

    if (modal && !eventListenersAttached) {
      // Attach listeners for both wheel and touchmove events on the modal
      modal.addEventListener('wheel', forwardScrollEvent);
      modal.addEventListener('touchmove', forwardScrollEvent);
    }

    if (table && !eventListenersAttached) {
      // Detect scroll of table.
      // If the table is scrolled to the top, set atTop to true
      // If the table is scrolled to the bottom, set atBottom to true
      table.addEventListener('scroll', handleTableScroll);
    }

    return () => {
      // Clean up event listeners when the component unmounts
      modal?.removeEventListener('wheel', forwardScrollEvent);
      modal?.removeEventListener('touchmove', forwardScrollEvent);
      table?.removeEventListener('scroll', handleTableScroll);
    };
  }, [atBottom]);

  const { mutateAsync: duplicateNewProject, status: duplicateNewProjectStatus } = useDuplicateProject();
  
  const handleSubmit = async () => {
    try {
      const project = await duplicateNewProject({
        id: duplicateProjectId,
        data: { 
          name: formState.name,
          portfolioId 
        }
      });
      toast.success(`Project duplicated successfully ${portfolioId ? ' and added to the portfolio' : ''}`, { duration: 5000 });
      if (project?.id) {
        history.push(`/project/${project?.id}/worksheet`);
        closeModal();
      }
    } catch (error) {
      console.error('Error duplicating project:', error);
      toast.error('Error duplicating the project', { duration: 5000 });
    }
  };

  const handleTableScrollButtonClick = () => {
    // Scroll ~3 rows at a time
    // Scroll smoothly though
    const table = tableRef.current as HTMLDivElement;
    const rowHeight = table.querySelector('tr')?.clientHeight ?? 0;
    const scrollAmount = 4 * rowHeight;
    table.scrollBy({ top: scrollAmount, behavior: 'smooth' });
  };

  return (
    <div>
      <Modal.Title>
        Duplicate a project
      </Modal.Title>
      <Modal.Content style={{ position: 'relative' }}>
        <p>
          Please note that your new project will contain all the data from the original project, but none of the documents, attachments, or messages.
        </p>
        <Form onSubmit={() => handleSubmit()}>
          <FormSchemaAwareWidget
            fieldLabel="Project name"
            placeholder="Enter value"
            schemaKey="name"
            fieldStyle={{ marginBottom: 'var(--small)' }}
          />
          <strong 
            style={{
              display: 'block',
              fontSize: '0.875rem',
              marginBottom: 'var(--x-small)'
            }}
          >
            Select an existing project
          </strong>
          <ProjectTableContainer ref={tableRef}>
            <ProjectTableSelection onSelect={(project: any) => setDuplicateProjectId(project.id)} />
          </ProjectTableContainer>
        </Form>
        {isScrollable && (
          <ProjectTableScrollIndicator atBottom={atBottom}>
            <button onClick={() => handleTableScrollButtonClick()}>
              <Icon name="chevron down" style={{ margin: 0, fontSize: '1.1rem', color: 'var(--color-charcoal)' }} />
            </button>
          </ProjectTableScrollIndicator>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Link 
          as={Button} 
          style={{ marginRight: 'auto' }}
          onClick={toggleFormType}
        >
          Create a brand new project
        </Link>
        <Button primary outlined onClick={closeModal}>Cancel</Button>
        <Button
          primary
          disabled={hasError || duplicateNewProjectStatus === 'loading'}
          loading={duplicateNewProjectStatus === 'loading'}
          onClick={() => handleSubmit()}
        >
          Create project
        </Button>
      </Modal.Footer>
    </div>
  );
}