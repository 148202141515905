import { WorksheetField } from '~/features/projectWizard/worksheets/common/components/Field';
import WorksheetForm from '~/features/projectWizard/worksheets/common/components/WorksheetForm';
import { DeploymentTypeRadioOptions } from '~/constants/deployment-type';
import { DevelopmentStageRadioOptions } from '~/constants/development-stage';
import { useDerivedRECFields, useDerivedProjectSetupFields } from '~/features/projectWizard/derived';

export default function ProjectFoundations () {
  // Update certain values automatically based on field changes
  useDerivedProjectSetupFields();
  useDerivedRECFields();

  return (
    <WorksheetForm>
      <WorksheetField
        type="radio"
        schemaKey="deploymentType"
        fieldLabel="What type of project is this?"
        values={DeploymentTypeRadioOptions}
      />
      <WorksheetField
        type="radio"
        schemaKey="developmentStage"
        fieldLabel="What is the project's status?"
        values={DevelopmentStageRadioOptions}
      />
    </WorksheetForm>
  );
}