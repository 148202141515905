import { TextArea } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import {
  FormSchemaAwareWidgetProps,
  AutoSaveSchemaAwareWidget,
  AutoSaveRadioSelectGroup,
  RadioSelectGroupProps,
  NumberInputProps,
  AutoSaveNumberInput
} from '~/components/form';
import { useProjectContext } from '~/hooks';

type WorksheetFieldWidths = 'full' | 'half' | 'third' | 'quarter';

const WidthDivisorMap = {
  full: 1,
  half: 2,
  third: 3,
  quarter: 4
};

interface WorksheetFieldTextInputProps extends FormSchemaAwareWidgetProps {
  type?: 'dropdown' | 'text' | 'textarea'
  width?: WorksheetFieldWidths
}

interface WorksheetFieldRadioInputProps extends RadioSelectGroupProps {
  type: 'radio'
  fieldLabel: string
}

interface WorksheetFieldNumberInputProps extends NumberInputProps {
  type: 'number'
  width?: WorksheetFieldWidths
}

type WorksheetFieldProps = WorksheetFieldTextInputProps | WorksheetFieldRadioInputProps | WorksheetFieldNumberInputProps;

export const WorksheetField = ({ type = 'text', ...props }: WorksheetFieldProps) => {

  const width = (props as WorksheetFieldTextInputProps | WorksheetFieldNumberInputProps)?.width;
  const maxFieldWidth = width && width !== 'full' ? `calc(42rem / ${WidthDivisorMap[width]} - (var(--medium) / 2))` : '100%';

  const {
    conditions: {
      isFieldDisplaying,
      getValidRadioValues,
      getFieldProps
    }
  } = useProjectContext();

  const displayStateProps = getFieldProps(props.schemaKey) ?? {};

  props = {
    ...props,
    ...displayStateProps
  };

  if (!isFieldDisplaying(props.schemaKey)) {
    return null;
  } else if (type === 'number') {
    // Format number input props
    const numberInputProps = props as WorksheetFieldNumberInputProps;
    return (
      <div style={{ maxWidth: maxFieldWidth ?? '100%', width: '100%' }}>
        <AutoSaveNumberInput {...numberInputProps} />
      </div>
    );
  } else if (type === 'radio') {
    const { values, ...radioProps } = props as WorksheetFieldRadioInputProps;
    return <AutoSaveRadioSelectGroup values={getValidRadioValues(props.schemaKey, values ?? [])} {...radioProps} />;
  } else {
    // Format text input props
    const textInputProps = props as WorksheetFieldTextInputProps;
    
    // Set custom widget type if needed
    const Widget = type === 'textarea' ? TextArea : undefined;
    
    return (
      <div style={{ maxWidth: maxFieldWidth ?? '100%', width: '100%' }}>
        <AutoSaveSchemaAwareWidget as={Widget} {...textInputProps} />
      </div>
    );
  }
};

WorksheetField.Row = styled.div<{ isQuote?: boolean }>`
  &&& {
    display: flex;
    align-items: flex-start;
    width: 100%;
    gap: var(--medium);
    > * {
      width: 100%;
    }
    @media(min-width: 1061px) {
      .field {
        margin-bottom: 0;
      }
    }
    @media(max-width: 1060px) {
      flex-direction: column;
      gap: 0;
      > * + * {
        margin-top: var(--medium);
      }
    }
    .radio-selections {
    .ui.input {
      max-width: calc(100% - 8rem);
      ${(props) => props.isQuote &&
        css`
          > input {
            width: 9.5rem !important;
          }
        `}
      }

    .radio-container .inline.fields {
      flex-direction: column;
      position: absolute;
      bottom: -1.5rem;
      align-items: flex-start;
      gap: 0.5rem;
      left: -0.5rem;
    }

    ${(props) => props.isQuote &&
      css`
        label {
          white-space: nowrap;
        }
      `}
  }

  .labelled-input {
    input {
      width: 0 !important;
    }
  }
  }
`;