import React from 'react';
import InvestorDisplay from '~/components/investorInvitations/InvestorDisplay';
import InvestorInvitationForm from '~/components/investorInvitations/InvestorInvitationForm';
import { Header as _Header, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { colors } from '~/theme';
import { DealStates } from '~/constants';
import BidDeadlineForm from './BidDeadlineForm';
import AdminNotes from './AdminNotes';
import ProjectRejection from '~/features/admin/ProjectRejection';
import { useUserContext } from '~/hooks';
import { PriorInvestor } from '~/types/company';

const InvInvitationContainer = styled.div`
  display: grid;
  grid-template-columns: 26.5625rem 1fr;
  column-gap: var(--2x-large);
`;

const AdminFormsContainer = styled.div`
  grid-column: 1 / 2;
  display: flex;
  flex-direction: column;
  gap: var(--medium);
`;

const DetailsMessage = styled(Message)`
    &&& {
    max-width: 37.5rem;
    color: var(--color-charcoal);
    background: var(--color-pure-white);
    margin-bottom: 0;
    border: 1px solid var(--color-black);
    box-shadow: none;
    ${props => props.isLoading && `background: ${colors.gray};`}
  }
`;

const Header = styled(_Header)`
  &&& {
    font-size: var(--small);
    margin: var(--large) 0 .285rem 0;
  }
`;

export interface InvestorInvitationsProps {
  label: string;
  investorQueue: any;
  preferredInvestors: PriorInvestor[];
  preferredInvestorDetails: string;
  assignToInvestor: any;
  updateInvitation: any;
  unassignInvestor: any;
  dealState: any;
  preferencesOnly?: boolean;
}

const InvestorInvitations: React.FC<InvestorInvitationsProps> = ({
  label,
  investorQueue,
  preferredInvestors,
  preferredInvestorDetails,
  assignToInvestor,
  updateInvitation,
  unassignInvestor,
  dealState,
  preferencesOnly
}) => {
  const { loggedInUser } = useUserContext();
  const preferredInvestorNames = preferredInvestors?.map?.((preferredInvestor: PriorInvestor) => preferredInvestor.name).join('; ');
  const showRejection = loggedInUser.isAdmin && dealState <= DealStates.MATCHING && label === 'project';

  return (
    <div>
      {dealState < DealStates.IN_REVIEW ? (
        <AdminNotes />
      ) : (
        <div style={{paddingBottom: 'var(--large)'}}>
          {!preferencesOnly && (
            <>
            <InvInvitationContainer>
             <div style={{ gridColumn: '1 / 3'}}>
                <InvestorDisplay {...{investorQueue, updateInvitation, label, unassignInvestor, dealState}}/>
              </div>
              <AdminFormsContainer>  
                {dealState < DealStates.IN_DILIGENCE && (
                  <>  
                    <BidDeadlineForm />
                    <InvestorInvitationForm {...{investorQueue, label, assignToInvestor, preferredInvestors}} />
                  </>
                )}
              </AdminFormsContainer>
              <div style={{ gridColumn: '2 / 3' }}>
                {showRejection && <ProjectRejection />}
              </div>
            </InvInvitationContainer>
          
          <Header>Investor Preference: <span style={{ fontWeight: 400 }}>{preferredInvestors.length > 0 ? preferredInvestorNames : 'N/A'}</span></Header>
          {preferredInvestorDetails && (
            <>
              <Header>Details</Header>
              <DetailsMessage>
                {preferredInvestorDetails}
              </DetailsMessage>
            </>
          )}
          </> 
          )}
          <InvInvitationContainer>
            <AdminNotes preferencesOnly={preferencesOnly} />
            <div style={{ gridColumn: '2 / 3' }}>
              {preferencesOnly && showRejection && <ProjectRejection />}
            </div>
          </InvInvitationContainer>
        </div>
      )}
    </div>
  );
};

export default InvestorInvitations;
