import ExcelJS from 'exceljs';
import download from 'downloadjs';
import { parseISO } from 'date-fns';
import sanitize from 'sanitize-filename';

import {
  ContractType,
  ContractTypeDisplay,
  EpcCompanyType,
  ExpensesUnit,
  OMPriceDisplay,
  PricingType,
  ProjectCustomerPricing,
  ProjectionInterconnectionTypes,
  ProjectOfftakeContract,
  ProjectPermits,
  SiteControlStatusDisplay,
  RecOwnershipDisplay,
  RevenueUnit,
  SiteLeaseTermDisplayExcel,
  SystemOwner,
  PropertyTaxType,
  TaxUnit,
  Unit,
  UnitDecimals,
  RevenueSource,
  LongTermOwner,
  ProjectInterconnectionDisplayValues,
  ProjectArrays,
  RecStates,
  RecAllocationDisplay,
  RecProjectCategoryDisplay,
  RecUtilityGroupExcelDisplay,
  RecProgramYearDisplay,
  RecOwnership,
} from '~/constants';
import { colors } from '~/theme';
import { epcCost, nrsFee, nrsFeeWithUnit, offtakeRate } from '~/utils/calculators';
import { formatDollars, state2abbreviation } from '~/utils/index';
import { getProjectStatus } from './projectState';
import { getProjectBidOverallAmount } from './calculators/costs';
import { ITCAdderCodes } from '~/constants/itc-adder-codes';
import { ProjectArray, ProjectArrayCounts } from '~/types/project';
import { DeploymentTypes } from '~/constants/deployment-type';

const removeForbiddenExcelCharacters = (str: string) => str.replaceAll(/(\*|\?|\\|\/|:|\[|\])/g, '_');

const white = true;
const red = true;
const blue = true;
const grey = true;

const defaultRowHeight = 17;
const A = 'A'.codePointAt(0) as any;

const bold = true;
const italic = true;

const center = true;
const right = true;

const nowrap = true;

const date = true;
const percent = true;
const zipcode = true;

const medium = {style: 'medium'};
const thin = {style: 'thin'};

const fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: {argb: colors.midnightBlue.substring(1)},
};

const numFormats = {
  zipcode: '[<=99999]00000;00000-0000',
  percent: '0.00%',
  percentNoDecimal: '0%',
  date: 'm/d/yyyy',
  currency0: '$#,##0',
  decimals0: '#,##0',
  decimals2: '0.00',
  decimals3: '0.000',
  decimals4: '0.0000',
} as any;

const spacerRow = {
  height: 4,
};

const emptyItemizeRow = function () {
  return {
    cells: [
      {blue},
      {blue, center, decimals: 0},
      {blue, center, decimals: 0},
      {blue, center, decimals: 0},
    ],
  };
} as any;

const baseStyle = {
  font: {
    name: 'Calibri (Body)',
    family: 2,
    size: 11,
    color: {argb: colors.pureBlack.substring(1)},
  },
  alignment: {
    wrapText: true,
    vertical: 'middle',
    horizontal: 'left',
  },
} as any;

const cellLabel = (row: any, col: any) => `${String.fromCharCode(col - 1 + A)}${row}`;

const mergeNoUndefined = (...objects: any) => {
  const result = {} as any;
  for (let obj of objects) {
    if (!obj) {
      continue;
    }
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== undefined) {
        result[key] = obj[key] ;
      }
    }
  }
  return result;
};

const validValue = (value: any) => {
  return !(value === undefined || value === null || value === '' || (typeof value === 'number' && isNaN(value)));
};

const setCell = (worksheet: any, row: any, col: any, data: any) => {
  data = data ?? {} as any;
  const {value, bold, italic, center, right, blue, red, white, grey, nowrap, fill, border, date, percent, zipcode, decimals, currency} = data;

  const defaultValue = data.defaultValue === undefined ? (blue || red ? '-' : null) : data.defaultValue;
  const wrapText = nowrap ? false : undefined;
  const horizontal = center ? 'center' : (right ? 'right' : undefined);
  const color = blue ? {argb: colors.blue.substring(1)} : (
    red ? {argb: colors.red.substring(1)} : (
      white ? {argb: colors.pureWhite.substring(1)} : (
        grey ? {argb: colors.charcoal.substring(1)} :
          undefined
      )));

  const isValid = validValue(value);
  const numFmt = date
    ? numFormats.date
    : (percent
      ? (decimals === false ? numFormats.percentNoDecimal : numFormats.percent)
      : (zipcode
        ? numFormats.zipcode
        : (currency !== undefined
          ? numFormats[`currency${currency}`] 
          : decimals !== undefined
            ? numFormats[`decimals${decimals}`]
            : null
          )
        )
      )
  ;
  const stylizedZero = (percent || decimals !== undefined) && !isValid && defaultValue !== null;

  const cell = worksheet.getCell(row, col);
  cell.value = !isValid ? (stylizedZero ? 0 : defaultValue) : (date ? parseISO(value) : (percent ? value / 100 : value));
  cell.font = mergeNoUndefined(cell.font, baseStyle.font, {bold, italic, color});
  cell.alignment = mergeNoUndefined(cell.alignment, baseStyle.alignment, {wrapText, horizontal});
  cell.border = mergeNoUndefined(cell.border, baseStyle.border, border);
  cell.numFmt = stylizedZero ? `0;0;"${defaultValue}";@` : (numFmt ?? baseStyle.numFmt);
  cell.fill = fill;

  //cell.value = !validValue(value) ? defaultValue : (date ? parseISO(value) : (percent ? value/100 : value))
  //cell.numFmt = numFmt ?? baseStyle.numFmt

};

const createSection = (worksheet: any, startColChar: any, startRow: any, section: any) => {
  const startCol = startColChar.codePointAt(0) - A + 1;

  section.rows = section.rows ?? [];

  if (section.header !== false) {
    const header = [] as any;
    for (let col = 0; col < section.width; col++) {
      header.push({bold, white, fill});
    }
    header[0].value = section.label;
    section.rows.unshift({cells: header});
  }

  const skippedCells = {} as any;
  for (let row = 0; row < section.rows.length; row++) {
    const rowData = section.rows[row] ?? {};
    worksheet.getRow(startRow + row).height = rowData.height ?? defaultRowHeight;

    for (let col = 0; col < section.width; col++) {
      if (skippedCells[cellLabel(startRow + row, startCol + col)]) {
        continue;
      }

      const cellData = rowData.cells?.[col] ?? {};

      if (section.border !== false) {
        cellData.border = cellData.border ?? {};
        if (row === 0) {
          cellData.border.top = medium;
        }
        if (col === 0) {
          cellData.border.left = medium;
        }
      }

      const mergeWidth = (cellData.merge?.width ?? 1) - 1;
      const mergeHeight = (cellData.merge?.height ?? 1) - 1;
      if (cellData.merge) {
        worksheet.mergeCells(startRow + row, startCol + col, startRow + row + mergeHeight, startCol + col + mergeWidth);
        for (let i = 0; i <= mergeHeight; i++) {
          for (let j = 0; j <= mergeWidth; j++) {
            skippedCells[cellLabel(startRow + row + i, startCol + col + j)] = true;
          }
        }
      }

      if (section.border !== false) {
        if (row + mergeHeight >= section.rows.length - 1) {
          cellData.border.bottom = medium;
        }
        if (col + mergeWidth >= section.width - 1) {
          cellData.border.right = medium;
        }
      }

      setCell(worksheet, startRow + row, startCol + col, cellData);
    }
  }

  return startRow + section.rows.length + 1;
};


const buildWorksheetName = (name: any, size: any, state?: any) => {
  name = name.replaceAll('[\\\\/*?:[\\]]', '');
  let fixedPart;
  if (state) {
    fixedPart = ` - ${state2abbreviation(state)} - ${Math.round(size)}kW`;
  } else {
    fixedPart = ` summary - ${Math.round(size)}kW`;
  }
  return removeForbiddenExcelCharacters(name.substring(0, 31 - fixedPart.length) + fixedPart);
};

const buildProjectWorksheet = (workbook: any, project: any, projectCosts: any, projectDisplayValues: any, permissions: any) => {
  const worksheetName = buildWorksheetName(project.name, projectCosts.sizeKwdc, project.addressState);
  const worksheet = workbook.addWorksheet(worksheetName, {views: [{showGridLines: false}]});
  worksheet.pageSetup.showGridLines = false;

  worksheet.columns = [
    {width: 2, style: baseStyle},  // A
    {width: 36, style: baseStyle}, // B
    {width: 24, style: baseStyle}, // C
    {width: 24, style: baseStyle}, // D
    {width: 22, style: baseStyle}, // E
    {width: 21, style: baseStyle}, // F
    {width: 34, style: baseStyle}, // G
    {width: 22, style: baseStyle}, // H
  ];

  // Section data: overview
  const overviewSection = {
    label: 'Overview',
    width: 7,
    rows: [
      {
        cells: [
          {value: 'Company Submitting', bold},
          {value: project.installer?.company?.name, bold, blue, nowrap},
        ],
      }, {
        cells: [
          {value: 'Portfolio Name', bold},
          {value: project.portfolio?.name || '-', blue},
        ],
      }, {
        cells: [
          {value: 'Project Name', bold},
          {value: project.name, blue},
        ],
      },
      {
        cells: [
          {value: 'Long Term System Owner', bold},
          {
            value: (() => {
              const isITC = project.pricingType === PricingType.ITC;

              if (isITC && project.systemOwner === SystemOwner.CUSTOMER) {
                return project.customerName;
              } else if (isITC && project.systemOwner === SystemOwner.THIRD_PARTY) {
                return project.systemOwnerName;
              } else {
                return project.investor?.company?.name || '';
              }
            })(), 
            blue
          },
        ],
      },
      null
      , {
        cells: [
          {value: 'Address'},
          {value: project.addressStreet, blue},
        ],
      }, {
        cells: [
          {value: 'City'},
          {value: project.addressCity, blue},
        ],
      }, {
        cells: [
          {value: 'State'},
          {value: state2abbreviation(project.addressState), blue},
        ],
      }, {
        cells: [
          {value: 'ZIP code'},
          {value: parseInt(project.addressZip?.replaceAll(/\D/g, ''), 10), blue, zipcode},
        ],
      }, {
        cells: [
          {value: 'Expected NTP'},
          {value: project.expectedProceedDate, blue, date},
        ],
      }, {
        cells: [
          {value: 'Expected COD'},
          {value: project.expectedCommercialOperationDate, blue, date},
        ],
      }, {
        cells: [
          {value: 'Utility Zone'},
          {value: project.utilityZone, blue},
        ],
      }, {
        cells: [
          {value: 'Interconnection Type'},
          {value: ProjectionInterconnectionTypes[project.interconnectionType], blue}
        ],
      }, {
        cells: [
          { value: 'System Owner' },
          { value: projectDisplayValues?.projectDetails?.longTermOwner, blue }
        ]
      },
      {
        cells: [
          {value: 'Developer\'s General Project Notes'},
          {value: project.projectNotes, blue, nowrap},
        ],
      },
      null
      , {
        cells: [
          {value: 'Array type(#)', bold},
          {value: 'Total size (kWdc)', bold, center},
          {value: 'Total size (kWac)', bold, center},
          {value: 'Tilt (degrees)', bold, center},
          {value: 'Azimuth (degrees)', bold, center},
          {value: 'Production Factor (kWh/kW)', bold, center},
          {value: 'Production (kWh)', bold, center},
        ],
      },
    ],
  } as any;

  // Mount types  
  const projectArrayAmounts = [...Object.keys(ProjectArrays), 'TOTAL'].reduce((acc, key: string) => {
    acc[key] = {
      sizeKwdc: 0,
      sizeKwac: 0,
      production: 0,
      productionFactor: 0,
    };
    return acc;
  }, {} as {
    [key: string]: {
      sizeKwdc: number;
      sizeKwac: number;
      production: number;
      productionFactor: number;
    };
  });

  let arrayCounts: ProjectArrayCounts = {
    ROOFTOP: 0,
    GROUNDMOUNT_FIXED_TILT: 0,
    GROUNDMOUNT_SINGLE_AXIS: 0,
    CARPORT_CANOPY: 0,
  };
  
  project.projectArrays?.forEach((projectArray: ProjectArray) => {
    const type = projectArray.type as keyof ProjectArrayCounts;
    if (!type) {
      // Filter out any project arrays without a type
      return;
    }
    if (type && arrayCounts[type] !== undefined) {
      arrayCounts[type]++;
    } 

    const sizeKwdc = projectArray.sizeKwdc ?? 0;
    const sizeKwac = projectArray.sizeKwac ?? 0;
    let production: number;
    if (projectArray?.yearOneProductionUnit === 'KWH') {
      production = projectArray.yearOneProduction ?? 0;
    } else  {
      // unit is KWH_KW
      production = sizeKwdc && projectArray?.yearOneProduction ? projectArray?.yearOneProduction * sizeKwdc : 0;
    }
    projectArrayAmounts[type].sizeKwdc += sizeKwdc;
    projectArrayAmounts[type].sizeKwac += sizeKwac;
    projectArrayAmounts[type].production += production;

    projectArrayAmounts.TOTAL.sizeKwdc += sizeKwdc;
    projectArrayAmounts.TOTAL.sizeKwac += sizeKwac;
    projectArrayAmounts.TOTAL.production += production;
  });

  for (const key of Object.keys(projectArrayAmounts)) {      
    const row = projectArrayAmounts[key];
    if (row.sizeKwdc) {
      row.productionFactor = row.production / row.sizeKwdc;
    }
  }

  // Adds a row for each mount type
  [
    ['Rooftop', 'ROOFTOP'], 
    ['Groundmount - Fixed', 'GROUNDMOUNT_FIXED_TILT'], 
    ['Groundmount - SAT', 'GROUNDMOUNT_SINGLE_AXIS'], 
    ['Carport/Canopy', 'CARPORT_CANOPY']
  ].forEach(([label, type]) => {

    const row = [] as any;
    overviewSection.rows.push({cells: row});
    row.push({
      value: `${label} (${arrayCounts[type as keyof ProjectArrayCounts]})`,
    });

    if (!projectArrayAmounts[type]) {
      row.push({blue, center, decimals: 0});
      row.push({blue, center, decimals: 0});
      row.push({blue, center, decimals: 0});
      row.push({blue, center, decimals: 0});
      row.push({blue, center, decimals: 0});
      row.push({blue, center, decimals: 0});
      return;
    }
    
    const { sizeKwdc, sizeKwac, production, productionFactor } = projectArrayAmounts[type];

    row.push({value: sizeKwdc, blue, center, decimals: 0});
    row.push({value: sizeKwac, blue, center, decimals: 0});
    row.push({value: '-', blue, center, decimals: 0});
    row.push({value: '-', blue, center, decimals: 0});
    row.push({value: productionFactor, blue, center, decimals: 0});
    row.push({value: production, blue, center, decimals: 0});
  });

  // Adds a row for the total mount types amount
  const totalsRow = [] as any;
  const { sizeKwdc, sizeKwac, production, productionFactor } = projectArrayAmounts.TOTAL;
  overviewSection.rows.push({cells: totalsRow});
  totalsRow.push({value: 'Total Solar', border: {top: thin}});
  totalsRow.push({value: sizeKwdc, blue, border: {top: thin}, center, decimals: 0});
  totalsRow.push({value: sizeKwac, blue, border: {top: thin}, center, decimals: 0});
  totalsRow.push({border: {top: thin}, center});
  totalsRow.push({border: {top: thin}, center});
  totalsRow.push({value: productionFactor, blue, border: {top: thin}, center, decimals: 0});
  totalsRow.push({value: production, blue, border: {top: thin}, center, decimals: 0});

  overviewSection.rows.push(null);
  overviewSection.rows.push({
    cells: [
      null,
      { value: 'Make', bold, center },
      { value: 'Model', bold, center },
      { value: 'Wattage (kW)', bold, center },
      { value: 'Discharge (kWh)', bold, center }
    ] 
  });
  overviewSection.rows.push({
    cells: [
      { value: 'Battery Storage' },
      { value: '-', center, blue },
      { value: '-', center, blue },
      { value: projectDisplayValues?.storage?.wattage, decimals: 0, center, blue },
      { value: projectDisplayValues?.storage?.discharge, decimals: 0, center, blue }
    ]
  });
  overviewSection.rows.push(null);

  overviewSection.rows.push({
    cells: [
      {value: 'Bid Type Requested:', bold},
      {
        value: (() => {
          switch (project.pricingType) {
            case PricingType.ACQUISITION:
              return 'EPC Cost + Dev Fee';
            case PricingType.OFFTAKE:
              return 'Offtake Rate';
            case PricingType.ITC:
              return 'ITC Transfer';
          }
        })(),
        bold,
        red,
        nowrap
      },
      null,
      null,
      {value: 'DNV\'s Estimated Production Values (Low):', bold, right, nowrap},
      {value: project.dnvEnergyYieldLow, defaultValue: 'N/A', red, center, decimals: 0},
      {value: project.dnvEnergyYieldLow * projectArrayAmounts.TOTAL.sizeKwdc, defaultValue: 'N/A', red, center, decimals: 0},
    ],
  });
  overviewSection.rows.push({
    cells: [
      {value: 'Current Bid:', bold},
      {
        value: (() => {
          if (project.pricingType === PricingType.ITC && project.acceptedBid) {
            return formatDollars(getProjectBidOverallAmount(project, project.acceptedBid, false));
          }
        })(),
        blue
      },
      null,
      null,
      {value: 'DNV\'s Estimated Production Values (High):', bold, right, nowrap},
      {value: project.dnvEnergyYieldHigh, defaultValue: 'N/A', red, center, decimals: 0},
      {value: project.dnvEnergyYieldHigh * projectArrayAmounts.TOTAL.sizeKwdc, defaultValue: 'N/A', red, center, decimals: 0},
    ]
  });


  // Section data: statuses
  const statusesSection = {
    label: 'Statuses',
    width: 4,
    rows: [
      {
        cells: [
          { value: 'Deal Status:', bold },
          { value: getProjectStatus(project)?.toUpperCase() || '', bold, red },
          { value: 'Desired Transaction Timing', bold },
          { value: projectDisplayValues?.desiredTransactionStage, italic, nowrap }
        ],
      },
      null
      , {
        cells: [
          {value: 'Permits:'},
          {value: ProjectPermits[project.permitsStatus], nowrap, blue},
        ],
      }, {
        cells: [
          {value: 'Interconnection:'},
          {value: ProjectInterconnectionDisplayValues[project.interconnectionStatus], nowrap, blue},
        ],
      },
      ...(() => {
        // Conditionally show customer pricing status, offtake contract status, and site control status
        const customerPricingRow = {
          cells: [
            {value: 'Customer Pricing:'},
            {value: ProjectCustomerPricing[project.customerPricingStatus], nowrap, blue},
          ]
        };
        const offtakeContractRow = {
          cells: [
            {value: 'Offtake Contract:'},
            {value: ProjectOfftakeContract[project.offtakeContractStatus], nowrap, blue},
          ]
        };
        const siteControlRow = {
          cells: [
            {value: 'Site Control:'},
            {value: SiteControlStatusDisplay[project.siteControlStatus] || '', nowrap, blue},
          ]
        };

        if (project?.longTermOwner === LongTermOwner.END_USER) {
          return [];
        } else if (project?.revenueSource === RevenueSource.COMMUNITY_SOLAR_SUBS) {
          return [siteControlRow];
        } else {
          return [customerPricingRow, offtakeContractRow, siteControlRow];
        }
      })(),
    ],
  };

  const itcAdderValue = (code: any) => {
    return project?.itcAdders?.some((adder: any) => adder.code === code) ? 'Yes' : 'No';
  };

  // ITC Adders section
  const itcAddersSection = {
    width: 5,
    header: false,
    border: false,
    rows: [
      {
        cells: [
          null,
          { value: 'Expected ITC Percentage', nowrap },
          { value: projectDisplayValues?.targetITCPercent, italic, right },
          { value: 'ITC Adders', bold, center },
          { value: 'Yes or No', bold, center },
        ]
      },
      {
        cells: [
          null,
          null,
          null,
          { value: 'Domestic Content (10%)', center },
          { value: itcAdderValue(ITCAdderCodes.DOMESTIC_CONTENT), center },
        ]
      },
      {
        cells: [
          null, // TODO re-add when ITC notes are ready { value: 'ITC Adders — Notes', bold, center, merge: { width: 3 } },
          null,
          null,
          { value: 'Energy Community (10%)', center },
          { value: itcAdderValue(ITCAdderCodes.ENERGY_COMMUNITY), center },
        ]
      },
      {
        cells: [
          null, // TODO re-add when ITC notes are ready { value: 'TODO: Add notes', italic, merge: { width: 3, height: 2 } },
          null,
          null,
          { value: 'LI Community / Tribal Lands (10%)', center },
          { value: itcAdderValue(ITCAdderCodes.LOW_INCOME_COMMUNITY_TRIBAL), center },
        ]
      },
      {
        cells : [
          null,
          null,
          null,
          { value: 'LI Economic Benefit Project (20%)', center },
          { value: itcAdderValue(ITCAdderCodes.LOW_INCOME_ECONOMIC_BENEFIT), center },
        ]
      }
    ]
  };
  
  // Section data: budget
  const budgetSection = {
    label: 'Budget',
    width: 7,
    rows: [
      null
      , {
        cells: [
          {value: 'EPC Company'},
          {value: (() => {
            switch (project.epcCompanyType) {
              case EpcCompanyType.SELF:
                return project?.installer?.company?.name ?? 'Self';
              case EpcCompanyType.OTHER_DEVELOPER:
                return project.epcCompanyName;
              case EpcCompanyType.OTHER_INVESTOR:
                return 'Investor to Choose';
            }
          })(), blue},
        ],
      },
      null
      , {
        cells: [
          null,
          {value: '$/W', center, bold},
          {value: '$', center, bold},
          {value: 'Notes', bold},
        ],
      },
    ],
  };

  let totalBudgetDollar = 0;
  let totalBudgetPerW = 0;
  const addBudgetItem = (item?: any, label?: any, solved?: any) => {
    const row = [] as any;
    budgetSection.rows.push({cells: row});
    row.push({value: label});

    if (!item) {
      row.push({blue, center, decimals: 0});
      row.push({blue, center, decimals: 0});
      row.push({grey, italic, nowrap});
      return;
    }

    let total = null;
    let perW = null;
    const amount = !item.amount ? null : item.amount;
    if (amount != null) {
      if (item.unit === Unit.TOTAL) {
        total = amount;
        perW = amount / (projectArrayAmounts.TOTAL.sizeKwdc * 1000);
      } else {
        total = amount * (projectArrayAmounts.TOTAL.sizeKwdc * 1000);
        perW = amount;
      }
      totalBudgetDollar += total;
      totalBudgetPerW += perW;
    }

    if (solved) {
      if (amount) {
        row.push({value: perW, red, center, decimals: UnitDecimals.WDC});
        row.push({value: total, red, center, decimals: UnitDecimals.TOTAL});
        row.push({value: 'CURRENT OFFER FROM INVESTOR', red, italic, nowrap});
      } else {
        row.push({red, center, decimals: 0});
        row.push({red, center, decimals: 0});
        row.push({value: 'WILL BE BID BY INVESTOR', red, italic, nowrap});
      }
    } else {
      row.push({value: perW, blue, center, decimals: UnitDecimals.WDC});
      row.push({value: total, blue, center, decimals: UnitDecimals.TOTAL});
      row.push({value: item.notes, grey, italic, nowrap});
    }
  };

  const absoluteEpcCost = epcCost(project);
  const isUnsubmittedAcquisitionBid = project?.pricingType === PricingType.ACQUISITION && !projectCosts?.bid;
  addBudgetItem({amount: absoluteEpcCost, unit: Unit.TOTAL}, 'EPC Cost + Dev Fee', project.pricingType === PricingType.ACQUISITION);
  addBudgetItem(project.budgetCosts?.INTERCONNECTION, 'Interconnection Upgrades');
  addBudgetItem(project.budgetCosts?.OTHER_ITC, 'Other ITC Eligible Costs');
  addBudgetItem(project.budgetCosts?.OTHER_NON_ITC, 'Other Non-ITC Eligible Costs');
  if (permissions.hasProjectInvestorAccess) {
    const feeAndUnit = nrsFeeWithUnit(project);
    if (!feeAndUnit) {
      addBudgetItem(null, 'Conductor Solar Fee');
    } else {
      if (project.pricingType === PricingType.ITC) {
        const { fee } = feeAndUnit;
        const amount = (projectCosts?.expectedItcCost ?? 0) * (fee ?? 0) / 100;
        addBudgetItem({ 
          amount, 
          unit: Unit.TOTAL
        }, 'Conductor Solar Fee');
      } else {
        addBudgetItem({
          amount: isUnsubmittedAcquisitionBid ? 0 : nrsFee(project, absoluteEpcCost), 
          unit: Unit.TOTAL,
           notes: `Conductor Solar Fee is set to ${feeAndUnit.fee.toFixed(2)}% of ITC-eligible budget items${isUnsubmittedAcquisitionBid ? ' (your bid, interconnection costs, and other ITC-eligible costs)' : ''}`
          }, 'Conductor Solar Fee');
      }
    }
  } else {
    budgetSection.rows.push(null);
  }

  const budgetTotalsRow = [] as any;
  budgetSection.rows.push({cells: budgetTotalsRow});
  budgetTotalsRow.push({value: 'Total', border: {top: thin}});
  budgetTotalsRow.push({value: isUnsubmittedAcquisitionBid ? 'TBD' : totalBudgetPerW, blue, border: {top: thin}, center, decimals: UnitDecimals.WDC});
  budgetTotalsRow.push({value: isUnsubmittedAcquisitionBid ? 'TBD' : totalBudgetDollar, blue, border: {top: thin}, center, decimals: UnitDecimals.TOTAL});

  // RECs Data
  // (This gets cleverly embedded into the revenue section)
  const recsData = project.recsData?.[project.addressState] ?? {};
  const recSections = [
    (() => {
      if (project.addressState === RecStates.ILLINOIS) {
        return [
          null,
          null,
          null,
          {value: 'IL SHINES Eligible?'},
          {value: recsData.isShinesProgram === 'YES' ? 'Yes' : 'No', blue, nowrap},
        ];
      } else {
        return [];
      }
    })(),
    (() => {
      if ((project.addressState === RecStates.ILLINOIS && recsData.isShinesProgram === 'YES') || project.addressState === RecStates.NEW_JERSEY) {
        return [
          {value: 'RECs secured?'},
          {value: RecAllocationDisplay[recsData.recAllocation], blue}
        ];
      }
      return [null, null];
    })(),
    (() => {
      if (project.addressState === RecStates.ILLINOIS && recsData.isShinesProgram === 'YES') {
        return [
          {value: 'Project category'},
          {value: RecProjectCategoryDisplay[recsData.recProjectCategory], blue}
        ];
      } else if (project.addressState === RecStates.NEW_JERSEY) {
        return [
          {value: 'REC Value ($/MWh)'},
          {value: recsData.recValue, blue, decimals: UnitDecimals.DOLLARS_PER_MWH}
        ];
      }
      return [null, null];
    })(),
    (() => {
      if (project.addressState === RecStates.ILLINOIS && recsData.isShinesProgram === 'YES') {
        return [
          {value: 'Utility Group'},
          {value: RecUtilityGroupExcelDisplay[recsData.recUtilityGroup], blue}
        ];
      } else if (project.addressState === RecStates.NEW_JERSEY) {
        return [
          {value: 'REC Term'},
          {value: recsData.recContractTerm, blue, decimals: 0}
        ];
      }
      return [];
    })(),
    (() => {
      if (project.addressState === RecStates.ILLINOIS && recsData.isShinesProgram === 'YES') {
        return [
          {value: 'REC Value ($/MWh)'},
          {value: recsData.recValue, blue, decimals: UnitDecimals.DOLLARS_PER_MWH}
        ];
      }
      return [null, null];
    })(),
    (() => {
      if (project.addressState === RecStates.ILLINOIS && recsData.isShinesProgram === 'YES') {
        return [
          {value: 'Program year'},
          {value: RecProgramYearDisplay[recsData.recProgramYear], blue}
        ];
      }
      return [null, null];
    })(),
  ];
  let recSectionIterator = 0;
  const nextRecSection = () => {
    if (recSectionIterator >= recSections.length || project.recOwnership !== RecOwnership.INVESTOR) {
      recSectionIterator++;
      return [null, null];
    }
    return recSections[recSectionIterator++];
  };

  // Section data: revenueSection
  const contractRate = offtakeRate(project);
  const revenueSection = {
    label: 'Revenue',
    width: 7,
    rows: [
      {
        cells: [
          {value: 'Customer Name'},
          {value: project.customerName, blue, nowrap},
        ],
      }, {
        cells: [
          {value: 'Customer Type'},
          {value: project.customerType, blue, nowrap},
        ],
      }, {
        cells: [
          {value: 'Customer Credit Information'},
          {value: project.customerCreditRating, blue, nowrap},
        ],
      }, {
        cells: [
          {value: 'Avoided Cost of Power ($/kWh)'},
          {value: project.avoidedCostOfSolar, blue, nowrap, decimals: UnitDecimals.KWH},
        ],
      },
      {
        cells: nextRecSection(),
      }, 
      ...(
        project?.revenueSource === RevenueSource.COMMUNITY_SOLAR_SUBS 
          ? [{
            cells: [
              {value: 'Offtake Contract Type'},
              {value: 'Community solar', blue, nowrap},
              null,
              ...nextRecSection(),
              {value: 'Community solar subscription details', bold, nowrap}
            ],
          }, {
            cells: [
              {value: 'Offtake Term (years)'},
              {value: project.revenueAssumptionsFlag ? project.contractTerm : 'Investor to choose', blue, nowrap, decimals: 0},
              null,
              ...nextRecSection(),
              {value: project.communitySolarSubscriptionDetails, nowrap, grey, italic}
            ],
          }, {
            cells: [
              {value: 'Base Power Rate ($/kWh)'},
              project.revenueAssumptionsFlag ? {
                value: contractRate, 
                blue,
                decimals: project.contractType === ContractType.PPA || project.deploymentType === DeploymentTypes.COMMUNITY_SOLAR 
                  ? UnitDecimals.KWH 
                  : UnitDecimals.YEAR,
              } : {
                value: 'Investor to choose', 
                blue
              },
              null,
              ...nextRecSection()
            ],
          }, {
            cells: [
              {value: 'Discount Percentage'},
              {value: project.revenueAssumptionsFlag ? project.communitySolarDiscountPercentage : 'Investor to choose', blue, percent: project.revenueAssumptionsFlag, nowrap},
              null,
              ...nextRecSection(),
              {value: 'Community solar revenue details', bold, nowrap}
            ]
          }, {
            cells: [
              {value: 'Community Solar Revenue Rate ($/kWh)'},
              {value: (() => {
                if (!project.revenueAssumptionsFlag) {
                  return 'Investor to choose';
                } else if (project.communitySolarDiscountPercentage && project.contractRate) {
                  return project.contractRate * (1 - project.communitySolarDiscountPercentage / 100);
                } else if (project.contractRate) {
                  return project.contractRate;
                }
              })(), blue, decimals: UnitDecimals.KWH},
              null,
              ...nextRecSection(),
              {value: project.communitySolarRevenueDetails, nowrap, grey, italic}
            ]
          }, {
            cells: [
              {value: 'Estimated Escalator'},
              {value: project.contractEscalator, blue, percent, nowrap},
            ],
          }]
          : [{
            cells: [
              {value: 'Offtake Contract Type'},
              {value: ContractTypeDisplay[project.contractType], blue, nowrap},
              null,
              ...nextRecSection()
            ],
          }, {
            cells: [
              {value: 'Contract Term (years)'},
              {value: project.contractTerm, blue, nowrap, decimals: 0},
              null,
              ...nextRecSection()
            ],
          }, {
            cells: [
              {value: `Offtake Rate (${project.contractType === ContractType.PPA ? '$/kWh' : '$/year'})`},
              {
                value: contractRate, ...(project.pricingType === PricingType.OFFTAKE ? {red} : {blue}),
                decimals: project.contractType === ContractType.PPA ? UnitDecimals.KWH : UnitDecimals.YEAR,
              },
              project.pricingType === PricingType.OFFTAKE ? {
                value: contractRate ? 'CURRENT OFFER FROM INVESTOR' : 'WILL BE BID BY INVESTOR',
                red,
                italic,
                nowrap,
              } : null,
              ...nextRecSection()
            ],
          }, {
            cells: [
              {value: 'Contract Escalator'},
              {value: project.contractEscalator, blue, percent, nowrap},
              null,
              ...nextRecSection()
            ],
          }]
      )
      ,
      spacerRow
      , {
        cells: [
          {value: 'REC Ownership'},
          {value: RecOwnershipDisplay[project.recOwnership], blue},
          {value: project.recDetails, grey, italic, nowrap},
          ...nextRecSection()
        ],
      }, {
        cells: [
          {value: `Upfront incentives (${project.upfrontIncentiveUnit === Unit.TOTAL ? 'Total $' : '$/W'})`},
          {value: project.upfrontIncentives, blue, decimals: project.upfrontIncentiveUnit === Unit.TOTAL ? UnitDecimals.TOTAL : UnitDecimals.KWH},
          {value: project.upfrontIncentiveNotes, grey, italic, nowrap},
        ],
      },
      null
      , {
        cells: [
          {value: 'Additional Revenue', bold},
          {value: '$/kWh', center, bold},
          {value: '$/year', center, bold},
          {value: 'Escalator', center, bold},
          {value: 'Term (years)', center, bold},
          {value: 'Notes', center, bold},
        ],
      },
    ],
  };

  if (project.additionalRevenues?.length) {
    project.additionalRevenues.forEach((additionalRevenue: any, i: number) => {
      if (!additionalRevenue) {
        return;
      }
      const row = [] as any;
      revenueSection.rows.push({cells: row});
      row.push({value: additionalRevenue.name, blue, defaultValue: `Additional Revenue ${i + 1}`});

      let perKWH = null;
      let perYear = null;
      if (additionalRevenue.unit === RevenueUnit.KWH) {
        perKWH = additionalRevenue.amount;
      } else {
        perYear = additionalRevenue.amount;
      }
      row.push({value: perKWH, blue, center, decimals: UnitDecimals.KWH});
      row.push({value: perYear, blue, center, decimals: UnitDecimals.YEAR});
      row.push({value: additionalRevenue.escalator ?? 0, blue, center, percent});
      row.push({value: additionalRevenue.expectedTerm, blue, center});
      row.push({value: additionalRevenue.notes, grey, italic, nowrap});
    });
  }
  for (let i = 0; i < 4 - project.additionalRevenues?.length ?? 0; i++) {
    revenueSection.rows.push(emptyItemizeRow());
  }


  // Section data: expenses
  let propertyTaxPerKWDC = null;
  let propertyTaxPerKWAC = null;
  let propertyTaxPerYear = null;
  if (project.propertyTaxType === PropertyTaxType.ANNUAL) {
    propertyTaxPerYear = project.propertyTaxAmount;
  } else if (project.propertyTaxType === PropertyTaxType.PILOT) {
    if (project.propertyTaxUnit === TaxUnit.YEAR) {
      propertyTaxPerYear = project.propertyTaxAmount;
    } else if (project.propertyTaxUnit === TaxUnit.KWDC) {
      propertyTaxPerKWDC = project.propertyTaxAmount;
      if (projectArrayAmounts.TOTAL.sizeKwdc) {
        propertyTaxPerYear = projectArrayAmounts.TOTAL.sizeKwdc * project.propertyTaxAmount;
      }
    } else if (project.propertyTaxUnit === TaxUnit.KWAC) {
      propertyTaxPerKWAC = project.propertyTaxAmount;
      if (projectArrayAmounts.TOTAL.sizeKwac) {
        propertyTaxPerYear = projectArrayAmounts.TOTAL.sizeKwac * project.propertyTaxAmount;
      }
    }
  }
  const expensesSection = {
    label: 'Expenses',
    width: 7,
    rows: [
      {
        cells: [
          null,
          {value: '$/kW-year', center, bold},
          {value: '$/year', center, bold},
          {value: 'Escalator', center, bold},
          {value: 'Term (years)', center, bold},
          {value: 'Extensions (years)', center, bold},
          {value: 'Acreage', center, bold}
        ],
      }, {
        height: 30,
        cells: [
          {value: 'Site Lease'},
          {value: project.siteLeaseCostUnit === ExpensesUnit.KWDC ? project.siteLeaseCost : null, blue, center, decimals: UnitDecimals.KWDC},
          {value: project.siteLeaseCostUnit === ExpensesUnit.KWDC ? null : project.siteLeaseCost, blue, center, decimals: UnitDecimals.YEAR},
          {value: validValue(project.siteLeaseCost) ? project.siteLeaseEscalator ?? 0 : null, blue, center, percent},
          {value: project?.interconnectionType !== 'BEHIND_METER' ? SiteLeaseTermDisplayExcel[project.siteLeaseTerm] : null, blue, center},
          {value: project?.interconnectionType !== 'BEHIND_METER' ? project.siteLeaseExtensions : null, blue, center},
          {value: project.siteLeaseAcreage, blue, center, decimals: 0}
        ],
      }, {
        height: 30,
        cells: [
          { value: 'Unusual Site Conditions?' },
          { value: project?.unusualSiteConditions ?? '', nowrap, merge: { width: 6 }, grey, italic}
        ]
      },
      spacerRow
      , {
        cells: [
          null,
          {value: 'Type', center, bold},
          {value: '$/kWdc', center, bold},
          {value: '$/kWac', center, bold},
          {value: 'Total per year', center, bold},
          {value: 'Escalator', center, bold},
          {value: 'Notes', center, bold},
        ],
      }, {
        cells: [
          {value: 'Property Tax'},
          {value: project.propertyTaxType, center, blue},
          {value: propertyTaxPerKWDC, center, blue, decimals: UnitDecimals.KWDC},
          {value: propertyTaxPerKWAC, center, blue, decimals: UnitDecimals.KWAC},
          {value: propertyTaxPerYear, center, blue, decimals: UnitDecimals.YEAR},
          {
            value: project.propertyTaxEscalator ?? (project.propertyTaxType === PropertyTaxType.EXEMPT || !validValue(propertyTaxPerYear) ? null : 0),
            center,
            blue,
            percent,
          },
          {value: project.propertyTaxNotes, grey, italic, nowrap},
        ],
      },
      spacerRow
      , {
        cells: [
          {value: 'EPC wants to provide O&M Price?'},
          {value: project.omProposeFlag ? 'Yes' : 'No', center, blue},
        ],
      }, {
        cells: [
          {value: `Proposed Price ${project.omProposeFlag ? OMPriceDisplay[project.omPriceUnit] ?? '' : ''}`},
          {value: project.omProposeFlag ? project.omPrice : null, center, blue, decimals: project.omPriceUnit === ExpensesUnit.KWDC ? UnitDecimals.KWDC : UnitDecimals.YEAR},
        ],
      }, {
        cells: [
          {value: 'Escalator'},
          {value: project.omProposeFlag ? project.omEscalator ?? 0 : null, center, blue, percent},
        ],
      },
      null
      , {
        cells: [
          {value: 'Additional Expenses', bold},
          {value: '$/kW-year', center, bold},
          {value: '$/year', center, bold},
          {value: 'Escalator', center, bold},
          {value: 'Term (years)', center, bold},
          {value: 'Notes', center, bold},
        ],
      },
    ],
  } as any;

  if (project.additionalOperatingExpenses?.length) {
    project.additionalOperatingExpenses.forEach((additionalExpense: any, i: number) => {
      if (!additionalExpense) {
        return;
      }
      const row = [] as any;
      expensesSection.rows.push({cells: row});
      row.push({value: additionalExpense.name, blue, defaultValue: `Additional Expense ${i + 1}`});

      let perKWyear = null;
      let perYear = null;
      if (additionalExpense.unit === ExpensesUnit.KWDC) {
        perKWyear = additionalExpense.amount;
      } else {
        perYear = additionalExpense.amount;
      }
      row.push({value: perKWyear, blue, center, decimals: UnitDecimals.KWH});
      row.push({value: perYear, blue, center, decimals: UnitDecimals.YEAR});
      row.push({value: additionalExpense.escalator ?? 0, blue, center, percent});
      row.push({value: additionalExpense.expectedTerm, blue, center});
      row.push({value: additionalExpense.notes, grey, italic, nowrap});
    });
  }
  for (let i = 0; i < 4 - project.additionalOperatingExpenses?.length ?? 0; i++) {
    expensesSection.rows.push(emptyItemizeRow());
  }

  if (project?.omProposeFlag && project?.hasStorage) {
    let perKWyear = null;
    let perYear = null;
    if (project?.storageExpenseUnit === ExpensesUnit.KWDC) {
      perKWyear = project?.storageExpenseAmount;
    } else {
      perYear = project?.storageExpenseAmount;
    }

    expensesSection.rows.push({
      cells: [
        { value: 'Storage O&M', blue },
        { value: perKWyear, blue, center, decimals: UnitDecimals.KWH },
        { value: perYear, blue, center, decimals: UnitDecimals.YEAR },
        { value: project?.storageExpenseEscalator ?? 0, blue, center, percent },
        { value: project?.storageExpenseExpectedTerm ?? 0, blue, center },
        { value: project?.storageExpenseNotes ?? '', grey, italic, nowrap }
      ]
    });
  }

  if (project?.revenueSource === RevenueSource.COMMUNITY_SOLAR_SUBS && project?.subscriptionManagementAssumptionsFlag) {
    let perKWyear = null;
    let perYear = null;
    if (project?.subscriptionExpenseUnit === ExpensesUnit.KWDC) {
      perKWyear = project?.subscriptionExpenseAmount;
    } else {
      perYear = project?.subscriptionExpenseAmount;
    }

    expensesSection.rows.push({
      cells: [
        { value: 'Subscription Management Costs', blue },
        { value: perKWyear, blue, center, decimals: UnitDecimals.KWH },
        { value: perYear, blue, center, decimals: UnitDecimals.YEAR },
        { value: project?.subscriptionExpenseEscalator ?? 0, blue, center, percent },
        { value: project?.subscriptionExpenseExpectedTerm ?? 0, blue, center },
        { value: project?.subscriptionExpenseNotes ?? '', grey, italic, nowrap }
      ]
    });
  }

  let row = createSection(worksheet, 'B', 2, overviewSection);
  row = createSection(worksheet, 'B', row, budgetSection);
  row = createSection(worksheet, 'B', row, revenueSection);
  
  createSection(worksheet, 'B', row, expensesSection);
  createSection(worksheet, 'E', 2, statusesSection);
  createSection(worksheet, 'D', 11, itcAddersSection);
};

const buildPortfolioWorksheet = (workbook: any, portfolio: any, locations: any, portfolioTotals: any, projectBlobs: any, permissions: any) => {
  const worksheetName = buildWorksheetName(portfolio.name, portfolioTotals.sizeKwdc);
  const worksheet = workbook.addWorksheet(worksheetName, {views: [{showGridLines: false}]});
  worksheet.pageSetup.showGridLines = false;

  worksheet.columns = [
    {width: 2, style: baseStyle},
    {width: 36, style: baseStyle},
    {width: 36, style: baseStyle},
    {width: 14, style: baseStyle},
    {width: 14, style: baseStyle},
    {width: 14, style: baseStyle},
    {width: 14, style: baseStyle},
    {width: 14, style: baseStyle},
    {width: 14, style: baseStyle},
    {width: 14, style: baseStyle},
  ];

  // Section data: overview
  const overviewSection = {
    label: 'Overview',
    width: 2,
    rows: [
      {
        cells: [
          {value: 'Company Submitting', bold},
          {value: portfolio.installer?.company?.name, bold, blue, nowrap},
        ],
      }, {
        cells: [
          {value: 'Portfolio Name', bold},
          {value: portfolio.name, blue},
        ],
      },
      null
      , {
        cells: [
          {value: 'Size (kWdc)'},
          {value: portfolioTotals.sizeKwdc, blue, decimals: 0},
        ],
      }, {
        cells: [
          {value: 'Project Quantity'},
          {value: projectBlobs.length, blue},
        ],
      }, {
        cells: [
          {value: 'Locations'},
          {value: locations, blue},
        ],
      },
      {
        cells: [
          {value: 'Developer\'s General Portfolio Notes'},
          {value: portfolio.portfolioNotes, blue, nowrap},
        ],
      },
    ],
  };

  // Section data: project budgets
  const budgetSection = {
    label: 'Budget',
    width: 9,
    rows: [
      {
        height: 30,
        cells: [
          {value: 'Project Name', bold},
          {value: 'Location', bold},
          {value: 'Size (kWdc)', bold, center},
          {value: 'EPC Costs', bold, center},
          {value: 'Interconnection', bold, center},
          {value: 'Other ITC-Eligible Costs', bold, center},
          {value: 'Other Non-ITC-Eligible Costs', bold, center},
          permissions.hasProjectInvestorAccess ? {value: 'Conductor Solar Fee', bold, center} : null,
          {value: 'Project Totals', bold, center, border: {left: thin}}
        ],
      },
    ],
  } as any;

  budgetSection.rows[0].cells.push();

  let portfolioEpcStyle = {blue} as any;
  for (let projectBlob of projectBlobs) {
    const {project, projectCosts} = projectBlob;
    const addressState = state2abbreviation(project.addressState);
    const location = project.addressCity ? `${project.addressCity}, ${addressState}` : addressState;
    const epcStyle = project.pricingType === PricingType.ACQUISITION ? {red} : {blue};
    portfolioEpcStyle = epcStyle;
    budgetSection.rows.push({
      cells: [
        {value: project.name, blue, bold},
        {value: location, blue, bold},
        {value: projectCosts.sizeKwdc, blue, center, decimals: 0},
        project.pricingType === PricingType.ACQUISITION && projectCosts.bid === undefined
          ? {value: 'Awaiting bid', ...epcStyle, center}
          : {value: projectCosts.overall.epcCost, ...epcStyle, center, currency: 0},
        {value: projectCosts.overall.interconnection, blue, center, currency: 0},
        {value: projectCosts.overall.otherItc, blue, center, currency: 0},
        {value: projectCosts.overall.otherNonItc, blue, center, currency: 0},
        permissions.hasProjectInvestorAccess ? (
          project.pricingType === PricingType.ACQUISITION && projectCosts.bid === undefined
            ? {value: 'Awaiting bid', ...epcStyle, center}
            : {value: projectCosts.overall.nrsFee, ...epcStyle, center, currency: 0}
        ) : null,
        {value: projectCosts.overall.total, blue, center, currency: 0, border: {left: thin}},
      ]
    });
  }
  for (let i=projectBlobs.length; i<10; i++) {
    budgetSection.rows.push({
      cells: [
        {value: '-', blue},
        null,
        {value: '-', blue, center},
        {value: '-', blue, center},
        {value: '-', blue, center},
        {value: '-', blue, center},
        {value: '-', blue, center},
        permissions.hasProjectInvestorAccess ? {value: '-', blue, center} : null,
        {value: '-', blue, center, border: {left: thin}},
      ]
    });
  }
  budgetSection.rows.push({
    cells: [
      {value: 'Portfolio Totals', bold, border: {top: thin}},
      {border: {top: thin}},
      {value: portfolioTotals.sizeKwdc, blue, center, bold, border: {top: thin}, decimals: 0},
      portfolioTotals.epcCost == null
        ? {value: 'Awaiting bids', ...portfolioEpcStyle, center, bold, border: {top: thin}}
        : {value: portfolioTotals.epcCost, ...portfolioEpcStyle, center, bold, border: {top: thin}, currency: 0},
      {value: portfolioTotals.interconnection, blue, center, bold, border: {top: thin}, currency: 0},
      {value: portfolioTotals.otherItc, blue, center, bold, border: {top: thin}, currency: 0},
      {value: portfolioTotals.otherNonItc, blue, center, bold, border: {top: thin}, currency: 0},
      permissions.hasProjectInvestorAccess ? (
        portfolioTotals.nrsFee == null
          ? {value: 'Awaiting bids', ...portfolioEpcStyle, center, bold, border: {top: thin}}
          : {value: portfolioTotals.nrsFee, ...portfolioEpcStyle, center, bold, border: {top: thin}, currency: 0}
      ) : {border: {top: thin}},
      {value: portfolioTotals.total, blue, center, bold, border: {top: thin, left: thin}, currency: 0},
    ]
  });

  let row = createSection(worksheet, 'B', 2, overviewSection);
  createSection(worksheet, 'B', row, budgetSection);
};

const downloadExcel = async (workbook: any, baseFilename: any) => {
  const buffer = await workbook.xlsx.writeBuffer();

  const currDate = new Date();
  const dateStr = currDate.toLocaleDateString('en-US', {year: 'numeric'}) +
    currDate.toLocaleDateString('en-US', {month: '2-digit'}) +
    currDate.toLocaleDateString('en-US', {day: '2-digit'});

  download(new Blob([buffer]), sanitize(`${removeForbiddenExcelCharacters(baseFilename)} - ${dateStr}.xlsx`), 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
};

export const downloadProjectExcel = async (project: any, projectCosts: any, projectDisplayValues: any, permissions: any) => {
  const workbook = new ExcelJS.Workbook();

  buildProjectWorksheet(workbook, project, projectCosts, projectDisplayValues, permissions);

  let baseFilename = `${project.name} - ${state2abbreviation(project.addressState)} - ${Math.round(projectCosts.sizeKwdc)}kW`;
  if (project.portfolio) {
    baseFilename = `${project.portfolio.name} - ${baseFilename}`;
  }
  await downloadExcel(workbook, baseFilename);
};

export const downloadPortfolioExcel = async (portfolio: any, locations: any, portfolioTotals: any, projectBlobs: any, permissions: any) => {
  const workbook = new ExcelJS.Workbook();

  buildPortfolioWorksheet(workbook, portfolio, locations, portfolioTotals, projectBlobs, permissions);
  for (let projectBlob of projectBlobs) {
    buildProjectWorksheet(workbook, projectBlob.project, projectBlob.projectCosts, projectBlob.projectDisplayValues, permissions);
  }

  await downloadExcel(workbook, `${portfolio.name} - entire portfolio - ${Math.round(portfolioTotals.sizeKwdc)}kW`);
};
