import { 
  ContractType, 
  ExpensesUnit, 
  Unit, 
  ProjectStatus 
} from '~/constants';
import { Project } from '~/types/project';

const projectDefaultValues: Partial<Project> = {
  budgetCosts: {
    INTERCONNECTION: {amount: 0, unit: Unit.WDC},
    OTHER_ITC: {amount: 0, unit: Unit.WDC},
    OTHER_NON_ITC: {amount: 0, unit: Unit.WDC},
  },
  epcCostUnit: Unit.WDC,
  contractType: ContractType.PPA,
  siteLeaseCostUnit: ExpensesUnit.KWDC,
  propertyTaxType: null,
  propertyTaxUnit: ExpensesUnit.YEAR,
  omPriceUnit: ExpensesUnit.KWDC,
  omProposeFlag: false,
  siteLeaseExtensions: 0,
  upfrontIncentiveUnit: Unit.WDC,
  stateSpecificUpfrontIncentiveUnit: Unit.WDC,
  installerAcknowledgmentFlag: false,
  additionalOperatingExpenses: [],
  additionalRevenues: [],
  projectArrays: [],
  preferredInvestors: [],
  status: ProjectStatus.DRAFT,
};

export default projectDefaultValues;
