import { formatDollars } from '~/utils/formatNumber';
import autopricingByUnit from './unit';
import { AutopricingResultDataItem } from '~/types/project';
import { Project } from '~/types/project';
import { UnitDecimals, PricingEstimateTypeUnitDisplay, PricingType, PricingEstimateType } from '~/constants';

const calculateAutopricingResult = (
  project: Project,
  projectState: any,
  autopricingType: AutopricingResultDataItem,
) => {

  //this is for legacy projects that only have one row for autopricing
  if (autopricingType?.low === undefined || autopricingType?.high === undefined) {
    return project?.pricingType === PricingType.ACQUISITION && !projectState.isQuote ? undefined : '—';
  }

  const unit = autopricingType?.unit as keyof typeof UnitDecimals;
  const hasResult = Boolean(project?.autopricingResult && Object.keys(project.autopricingResult).length > 0);

  return hasResult
  ? `${formatDollars(autopricingType?.low, { dec: UnitDecimals[unit] })} - ${formatDollars(autopricingType?.high, { dec: UnitDecimals[unit] })} ${projectState?.isQuote && unit !== PricingEstimateType.TOTAL && unit !== PricingEstimateType.WDC ? `/ ${PricingEstimateTypeUnitDisplay[unit]}` : ''}`
  : '—';
};

export default function autopricingResult(project: Project, projectState: any) {
  const { autopricingKwh, autopricingLease, autopricingEpcTotal, autopricingWdc } = autopricingByUnit(project);
  return {
    ppaResult: calculateAutopricingResult(project, projectState, autopricingKwh), //$ per kWh and PPA solar contract
    leaseResult: calculateAutopricingResult(project, projectState, autopricingLease), //expected year one payment and lease solar contract
    epcResult: calculateAutopricingResult(project, projectState, autopricingEpcTotal), //EPC cost + dev fee
    epcWdcResult: calculateAutopricingResult(project, projectState, autopricingWdc) //$ per Wdc
  };
};
