import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';
import { FormSchemaAwareWidget } from '~/components/form';
import { Button } from 'semantic-ui-react';
import { usePromiseWatcher, useForm, useUserContext, useFadeInStyles } from '~/hooks';
import { updateUserSettings } from '~/api/user';
import { FormContextProvider } from '~/hooks/providers/FormContextProvider';
import ChangePasswordModal from '~/features/user/settings/ChangePasswordModal';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import * as S from './styles';

interface UserInfoFormState {
  name: string;
  email: string;
}

const UserInfoFormImpl = () => {
  const { openModal } = useModalContext();
  const { formState } = useForm<UserInfoFormState>();
  const { execute: executeUpdateUserSettings } = usePromiseWatcher(
    () => updateUserSettings(formState),
    {messageStub: 'updating your user info'},
    [formState]
  );

  // Auto-save user info
  const formStateRef = useRef(formState);
  const autoSaveUserInfoTimeoutRef = useRef();
  useEffect(() => {
    // Only autosave if a value actually changed
    if (formState.email && formState.name && !isEqual(formState, formStateRef.current)) {

      // Clear any existing timeout so tons of saves don't happen for each key stroke
      if (autoSaveUserInfoTimeoutRef.current) {
        clearTimeout(autoSaveUserInfoTimeoutRef.current);
      }

      // Set a timeout to call the execute function after 3 seconds
      autoSaveUserInfoTimeoutRef.current = setTimeout(executeUpdateUserSettings, 3000) as any;

      // Update the formState ref
      formStateRef.current = formState;
    }
  }, [formState]);

  return (
    <S.UserInfoFormContainer style={useFadeInStyles()}>
      <FormSchemaAwareWidget
        schemaKey="name"
        fluid
        fieldLabel="Display name"
        placeholder="Enter your name"
      />
      <FormSchemaAwareWidget
        schemaKey="email"
        fluid
        fieldLabel="E-mail address"
        placeholder="Enter your e-mail address"
      />
      <Button  primary size="medium" onClick={() => openModal(ChangePasswordModal)}>
        Change my password
      </Button>
    </S.UserInfoFormContainer>
  );
};

const UserInfoForm = () => {
  const { loggedInUser } = useUserContext();
  const defaults: UserInfoFormState = {
    name: loggedInUser.name,
    email: loggedInUser.email,
    // No longer displayed
    // companyName: loggedInUser?.company?.name || loggedInUser.investor?.company?.name || loggedInUser.installer?.company?.name,
    // addressCity: loggedInUser?.company?.addressCity || loggedInUser.investor?.company?.addressCity || loggedInUser.installer?.company?.addressCity,
    // addressState: loggedInUser?.company?.addressState || loggedInUser.investor?.company?.addressState || loggedInUser.installer?.company?.addressState,
    // addressStreet: loggedInUser?.company?.addressStreet || loggedInUser.investor?.company?.addressStreet || loggedInUser.installer?.company?.addressStreet,
    // addressStreet2: loggedInUser?.company?.addressStreet2 || loggedInUser.investor?.company?.addressStreet2 || loggedInUser.installer?.company?.addressStreet2,
    // addressZip: loggedInUser?.company?.addressZip || loggedInUser.investor?.company?.addressZip || loggedInUser.installer?.company?.addressZip,
  };

  return (
    <FormContextProvider defaults={defaults}>
      <UserInfoFormImpl/>
    </FormContextProvider>
  );
};

export default UserInfoForm;
