import { Modal } from '~/components';
import { Button } from '~/components';
import { useMutation } from 'react-query';
import { useProjectContext } from '~/hooks';
import { createCustomerProposalRequest } from '~/api/projects';
import { toast } from 'react-hot-toast';

type ProposalType = 'DEFAULT' | 'PPA' | 'LEASE';
interface CustomerProposalModalProps {
  proposalType?: string | undefined;
  closeModal: () => void;
  setProposalButtonType?: (proposalType: ProposalType) => void;
}

export default function CustomerProposalModal({
  closeModal,
  proposalType,
  setProposalButtonType
}: CustomerProposalModalProps) {
  const { project, updateProjectValue } = useProjectContext();

  const { mutateAsync: submitProposalRequest, status: submitProposalRequestStatus } = useMutation(async () => await createCustomerProposalRequest(project?.id, proposalType));

  const handleSubmit = async () => {
    try {
      const result = await submitProposalRequest();
      toast.success('Customer proposal requested.', { duration: 5000 });
      updateProjectValue('', result, { doSave: false, patch: true });
      setProposalButtonType && setProposalButtonType(proposalType as ProposalType);
      closeModal();
    } catch (err) {
      console.error(err);
      toast.error('Error requesting customer proposal.', { duration: 5000 });
    }
  };

  const handleCloseModal = () => {
    if (submitProposalRequestStatus !== 'loading') {
      closeModal();
      if (setProposalButtonType) {
        setProposalButtonType('DEFAULT');
      }
    }
  };

  return (
    <Modal closeModal={handleCloseModal}>
      <Modal.Title>
        Confirm proposal request
      </Modal.Title>
      <Modal.Content>
        <p>
          {`This ${proposalType ? 'pricing estimate' : 'project'} and its information will be provided to the Conductor team and will be used to generate a customer-facing proposal. Once generated, the proposal will be uploaded to the ${proposalType ? 'pricing estimate' : 'project'} and accessible from your Conductor Support messaging thread.`}
        </p>
      </Modal.Content>
      <Modal.Footer>
        <Button 
          primary 
          outlined 
          onClick={() => {
            closeModal();
            setProposalButtonType && setProposalButtonType('DEFAULT');
          }}
        >
          Cancel
        </Button>
        <Button 
          primary 
          onClick={handleSubmit}
          loading={submitProposalRequestStatus === 'loading'}
          disabled={submitProposalRequestStatus === 'loading'}
        >
          Submit my request
        </Button>
      </Modal.Footer>
    </Modal>

  );
}