import { WorksheetField } from '../../../worksheets/common/components/Field';
import WorksheetForm from '~/features/projectWizard/worksheets/common/components/WorksheetForm';
import { 
  LongTermOwnerDropdownOptions, 
  PricingTypeDropdownOptions, 
  PartnerTypeRadioOptions, 
  EpcCompanyTypeDropdownValues, 
  EpcCompanyType
} from '~/constants';
import { useDerivedProjectSetupFields } from '~/features/projectWizard/worksheets/common/derived/useDerivedProjectSetupFields';
import { useDerivedRECFields } from '~/features/projectWizard/derived';
import { useProjectContext } from '~/hooks';

export default function ProjectRoles() { 
  const { projectDisplayValues } = useProjectContext();

  const epcCompanyDropdownOptions = EpcCompanyTypeDropdownValues.map(option => 
    option.value === EpcCompanyType.SELF && projectDisplayValues?.projectDetails?.developer ? { 
      value: option.value, 
      label: projectDisplayValues?.projectDetails?.developer
    } : option
  );

  // Update certain values automatically based on field changes
  useDerivedProjectSetupFields();
  useDerivedRECFields();

  return (
    <WorksheetForm>
      <WorksheetField 
        type="radio"
        schemaKey="longTermOwner"
        fieldLabel="Who will own and operate the system once it's built?"
        values={LongTermOwnerDropdownOptions}
      />
      <WorksheetField
        type="radio"
        schemaKey="epcCompanyType"
        fieldLabel="Who will install the system?"
        values={epcCompanyDropdownOptions}
      />
      <WorksheetField
        type="text"
        schemaKey="epcCompanyName"
        fieldLabel="EPC name"
        placeholder="Enter EPC name"
      />
      <WorksheetField
        type="radio"
        schemaKey="partnerType"
        fieldLabel="What type of partner are you seeking?"
        values={PartnerTypeRadioOptions}
      />
      <WorksheetField
        type="radio"
        schemaKey="pricingType"
        fieldLabel="What type of bids are you seeking?"
        values={PricingTypeDropdownOptions}
      />
    </WorksheetForm>
  );
}