import React, { createContext, ReactNode, useCallback, useContext, useRef, useState } from 'react';
import { getByPath } from '~/utils';
import { validate } from '~/schema';

interface FormState {
  [key: string]: any;
}

interface FormContextParams<FormStateType = FormState> {
  formState: FormStateType;
  errors: any;
  getFormValue: (schemaKey: any) => any;
  resetFormState: () => void;
  updateFormValue: any;
  handleOnChangeEvent: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface FormContextProviderProps<FormStateType = FormState> {
  defaults: FormStateType;
  children?: ReactNode;
  validation?: any;
}

const FormContext = createContext<FormContextParams>({} as FormContextParams);

export const FormContextProvider = <FormStateType extends FormState>({ defaults, children, validation }: FormContextProviderProps<FormStateType>) => {
  const [errors, setErrors] = useState(validation ? validate(defaults, validation) : {});
  const [formState, setFormState] = useState(defaults as FormState);
  const formStateRef = useRef(formState);
  formStateRef.current = formState;

  const resetFormState = useCallback(
    () => {
      if (validation) {
        setErrors(validate(defaults, validation));
      }
      setFormState(defaults);
    },
    [defaults, setFormState, setErrors]
  );

  const updateFormValue = useCallback(
    (nameOrObject: any, value = undefined) => {
      const updatedForm = typeof nameOrObject === 'string'
        ? {...formStateRef.current, [nameOrObject]: value}
        : {...formStateRef.current, ...nameOrObject};
      if (validation) {
        setErrors(validate(updatedForm, validation));
      }
      setFormState(updatedForm);
    },
    [setFormState, setErrors],
  );

  const handleOnChangeEvent = useCallback(
    ({target: {value, name}}: any) => {
      updateFormValue(name, value);
    },
    [updateFormValue]
  );

  const getFormValue = useCallback(
    (schemaKey: any) => {
      return getByPath(formStateRef.current, schemaKey);
    },
    []
  );

  return <FormContext.Provider value={{
    formState,
    errors,
    getFormValue,
    resetFormState,
    updateFormValue,
    handleOnChangeEvent
  }}>{children}</FormContext.Provider>;
};

export const useForm = <FormStateType extends FormState>() => useContext(FormContext) as FormContextParams<FormStateType>;
export const useFormGeneric = <FormStateType extends FormState>() => {
  const {formState: state, updateFormValue: updateValue, errors, handleOnChangeEvent, getFormValue: getValue} = useContext(FormContext) as FormContextParams<FormStateType>;
  return {state, updateValue, errors, handleOnChangeEvent, getValue};
};
