import { useEffect } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { ProjectStatus } from '~/constants';
import { useProjectContext } from '~/hooks';
import { useQueryClient } from 'react-query';
import { usePageType } from '~/hooks';
import useArchiveToast from '~/hooks/archive/useArchiveToast';
import { useGrantInvestorDataroomAccess } from '~/mutations/dataroom/investorAccess/useGrantInvestorDataroomAccess';
import { useRevokeInvestorDataroomAccess } from '~/mutations/dataroom/investorAccess/useRevokeInvestorDataroomAccess';
import { useInvestorDataroomAccess } from '~/requests/dataroom';
import { getInvestorProjectDataroomAccessKey } from '~/requests/dataroom';

export default function DataroomAccess() {
  const { project } = useProjectContext();
  const queryClient = useQueryClient();
  const pageType = usePageType();
  const { data: investorDataroomAccess, status: getInvestorProjectDataroomAccessStatus, refetch: refetchInvestorDataroomAccess } = useInvestorDataroomAccess();
  const { mutate: grantInvestorProjectDataroomAccess, status: grantInvestorProjectDataroomAccessStatus } = useGrantInvestorDataroomAccess();
  const { mutate: revokeInvestorProjectDataroomAccess, status: revokeInvestorProjectDataroomAccessStatus } = useRevokeInvestorDataroomAccess();
  const loadingDataroomAccess = getInvestorProjectDataroomAccessStatus === 'loading' || grantInvestorProjectDataroomAccessStatus === 'loading' || revokeInvestorProjectDataroomAccessStatus === 'loading';

  const { showArchiveToast } = useArchiveToast();

  const hasDataroomAccess = (investorId: string) => {
    return !!investorDataroomAccess?.investors?.find((row: any) => row.investorId === investorId);
  };

  const toggleDataroomAccess = async (investorId: string) => {
    if (showArchiveToast()) {
      return;
    }
    if (hasDataroomAccess(investorId)) {
      await revokeInvestorProjectDataroomAccess({ projectId: project.id, investorId } as any, { onSuccess: refetchInvestorDataroomAccess } as any);
    } else {
      await grantInvestorProjectDataroomAccess({ projectId: project.id, investorId } as any, { onSuccess: refetchInvestorDataroomAccess } as any);
    }
  };

  //This is required to keep dataroom access in sync with the project's bid summary
  useEffect(() => {
    if (pageType === 'project') {
      queryClient.invalidateQueries(getInvestorProjectDataroomAccessKey(pageType, project?.id));
    }
  }, [pageType, project?.id, queryClient]);
  
  return (
    <>
      <div>
        <strong>Grant access to documents</strong>
      </div>
      {project.bidSummary.map((row: any, i: number) => {
        const showDocumentAccessCheckbox = row?.projectStatus !== ProjectStatus.DECLINED_TO_BID && row?.projectStatus !== ProjectStatus.BID_REJECTED;

        return showDocumentAccessCheckbox ? (
          <label key={row.investor?.id} style={i === project.bidSummary.length - 1 ? { borderRight: '1px solid var(--color-grey)' } : {}}>
            <Checkbox
              checked={hasDataroomAccess(row.investor?.id)}
              onChange={() => toggleDataroomAccess(row.investor?.id)}
              disabled={loadingDataroomAccess}
            />
          </label>
        ) : <div />;
      })}
    </>
  );
}